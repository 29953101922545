import EditSubscription from "components/PrivatePages/SubscriptionDashboard/modals/EditSubscription";
import MonthlySubscriptionCard from "components/PrivatePages/SubscriptionDashboard/monthlySubscription/monthlySubscriptionCard";
import UserCard from "components/PrivatePages/SubscriptionDashboard/userCards/userCard";
import React, { useState } from "react";

/**
 * Subscription Admin Dashboard View
 * @returns { Component }
 */
function SubscriptionAdmin({
  users,
  subscriptions,
  onUpdate,
  ecosystems,
  unverified,
  unassigned,
}) {
  const [editCard, setEditCard] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [search, setSearch] = useState("");

  return (
    <div className="flex flex-col space-y-4 w-full">
      {/* <input
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        type="text"
        className="block w-full px-4 bg-white border rounded-md focus:outline-none"
        placeholder="Search..."
      /> */}
      <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
        {Object.keys(users)?.length > 0 &&
          Object.keys(users)?.map((key, index) => {
            return (
              <UserCard
                onUpdate={onUpdate}
                subscription={key}
                list={users[key] || []}
                key={index}
              />
            );
          })}
        <UserCard
          onUpdate={onUpdate}
          subscription={"Unassigned Users"}
          list={unassigned || []}
        />
      </div>
      {/* <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
        {ecosystems?.length > 0 &&
          ecosystems?.map((key, index) => {
            return (
              <UserCard
                ecosystems
                id={key?.id}
                onUpdate={onUpdate}
                subscription={key?.name}
                list={key?.Users || []}
                key={index}
              />
            );
          })}
      </div> */}
      {/* <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
        {unverified?.length > 0 && (
          <UserCard
            id={"unverified"}
            onUpdate={onUpdate}
            subscription="Unverified Users"
            list={unverified || []}
            key={"unverified"}
            unverified
          />
        )}
      </div> */}

      <div className="">
        <h1 className="text-lg md:text-3xl font-normal mb-5">Subscriptions</h1>
        <div className="flex justify-between">
          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full ">
            {subscriptions?.length > 0 &&
              subscriptions?.map((item, index) => {
                if (index < 3)
                  return (
                    <MonthlySubscriptionCard
                      subscription={item}
                      key={index}
                      editCard={editCard}
                      setEditCard={setEditCard}
                      cardDetails={cardDetails}
                      setCardDetails={setCardDetails}
                    />
                  );
              })}
            {/* <DummySubscription /> */}
          </div>
        </div>
      </div>
      <EditSubscription
        editCard={editCard}
        setEditCard={setEditCard}
        onUpdate={onUpdate}
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
      />
    </div>
  );
}

export default SubscriptionAdmin;
