//Open and close modal for adding generating new promo codes

import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";

//Redux
import { useDispatch } from "react-redux";
import logError from "helpers/utils/logErrors";
import { showToast } from "redux/toaster";
import { FilePicker } from "react-file-picker";
import { RiFileExcelFill, RiUploadCloud2Line } from "react-icons/ri";
import { HiTrash } from "react-icons/hi";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import "assets/styles/scss/_styles/_UpdateAccessModal.scss";
import { adminAddUserByEco } from "config/apis/Admin";
import { addEcosystem } from "config/apis/Ecosystems";
const AddEcosystemModal = ({ isOpen, closeModal, onUpdate }) => {
  const dispatch = useDispatch();

  const [details, setDetails] = useState({});
  const [file, setFile] = useState(null);
  const [adding, setAdding] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    closeModal(false);
    setDetails({});
    setFile();
    setUploading(false);
  };

  const onSubmit = async () => {
    if (!details?.name || !details?.sendgridTemplateId) {
      dispatch(showToast({ message: "Add all fields please!", type: "error" }));

      return;
    }
    if (details?.name == "" || details?.sendgridTemplateId == "") {
      dispatch(showToast({ message: "Add all fields please!", type: "error" }));

      return;
    }
    try {
      setAdding(true);
      let body = { ...details };

      const res = await addEcosystem(body);

      if (res.status === 200) {
        setAdding(false);
        if (file) addFile(res?.data?.data?.id);
        else {
          onUpdate();
          handleClose();
        }
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Add Eco", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    } finally {
      setAdding(false);
    }
  };

  const addFile = async (id) => {
    try {
      setUploading(true);
      const Data = new FormData();
      Data.append("file", file);

      const res = await adminAddUserByEco(id, Data);

      if (res.status === 200) {
        setTimeout(() => {
          onUpdate();
          handleClose();
        }, 2000);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Add Eco", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    } finally {
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal11">
          <Grid container className="space-y-5">
            <Grid item xs={12} className="-mt-2">
              <div className="font-medium text-xl">Add Ecosystem</div>
            </Grid>

            <Grid item xs={12}>
              <Typography className="box-modal-label">Name</Typography>
              <input
                id="name"
                name="name"
                onChange={(e) => {
                  setDetails({ ...details, name: e.target.value });
                }}
                value={details?.name || ""}
                className="box-modal-field"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal-label">
                Sendgrid Template ID
              </Typography>
              <input
                id="id"
                name="sendgridTemplateId"
                onChange={(e) => {
                  setDetails({
                    ...details,
                    sendgridTemplateId: e.target.value,
                  });
                }}
                value={details?.sendgridTemplateId || ""}
                className="box-modal-field"
              />
            </Grid>
            <Grid xs={12}>
              <Typography className="box-modal-label">File</Typography>{" "}
              <div className="w-full flex-row items-center justify-center border-primary-blue-medium border-[1px] md:pt-20 md:pb-20 pb-2 pt-2 rounded-lg relative">
                <div className="flex items-center justify-center">
                  {file ? (
                    <div className="rounded-lg space-y-5 flex flex-col justify-center items-center">
                      <HiTrash
                        onClick={() => {
                          setFile();
                        }}
                        className="h-4 w-4 absolute top-4 right-4 cursor-pointer text-red-500"
                      />
                      <RiFileExcelFill className="h-12 w-20 text-primary-blue-medium bg-contain rounded-lg" />
                      <Typography className="box-modal-label">
                        {file?.name}
                      </Typography>{" "}
                    </div>
                  ) : (
                    <FilePicker
                      extensions={["csv", "xlsx"]}
                      dims={{
                        minWidth: 0,
                        maxWidth: 1000,
                        minHeight: 0,
                        maxHeight: 5000,
                      }}
                      onChange={(file) => {
                        console.log(file);
                        setFile(file);
                      }}
                      onError={(errMsg) => {
                        console.log("error", errMsg);
                        dispatch(
                          showToast({
                            message: errMsg,
                            type: "error",
                          })
                        );
                      }}
                    >
                      <button className="text-base text-primary-blue-medium text-normal cursor-pointer transform transition ease-in-out duration-150 hover:scale-105">
                        <div className="flex items-center justify-center">
                          <RiUploadCloud2Line className="text-primary-blue-medium text-8xl" />
                        </div>
                        Upload File
                      </button>
                    </FilePicker>
                  )}
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className="flex flex-row items-center justify-between"
            >
              <button
                onClick={() => {
                  handleClose();
                }}
                className="box-modal11-row2-no"
              >
                Cancel
              </button>
              {adding ? (
                <div className="flex flex-row items-center space-x-3 text-white justify-center bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 px-2 md:h-10 h-7 rounded-lg font-normal text-sm">
                  <div className="font-sm font-medium">Adding Ecosystem</div>{" "}
                  <div className="border-white border border-t-0 bg-transparent w-5 h-5 rounded-full animate-spin" />
                </div>
              ) : uploading ? (
                <div className="flex flex-row items-center space-x-3 text-white justify-center bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 px-2 md:h-10 h-7 rounded-lg font-normal text-sm">
                  <div className="font-sm font-medium">Uploading File</div>{" "}
                  <div className="border-white border border-t-0 bg-transparent w-5 h-5 rounded-full animate-spin" />
                </div>
              ) : (
                <button
                  onClick={() => {
                    onSubmit();
                  }}
                  className="box-modal11-row2-yes"
                >
                  Save
                </button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default AddEcosystemModal;
