//Promo code card for system dashboard
import React, { useEffect, useState } from "react";
//Mapped promo codes for promo code card on system dashboard
import PromoCodes from "../PromoCodes";
//Commonly styled  buttons

import GeneratePromoCodeModal from "../GeneratePromoCodeModal";
import TertiaryButton from "components/Common/TertiaryButton";
import BizBucksItems from "./BizBucksItems";
import { getBizBucksConfig } from "config/apis/dashboard.js";

const BizBucksCard = ({ onUpdate }) => {
  //Variable to store the state of the modal for generating promo code
  const [bizBucksEarn, setBizBucksEarn] = useState([]);
  const [bizBucksRedeem, setBizBucksRedeem] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getBizBucksConfigAPI();
  }, []);

  const getBizBucksConfigAPI = async () => {
    // setIsLoading(true);
    try {
      const res = await getBizBucksConfig();
      if (res) {

        let config = res.data.config;
        const earnArray = config.filter((item) => item.configType === "earn");
        const redeemArray = config.filter(
          (item) => item.configType === "redeem"
        );

        config.map((item) => {
          item?.configKey === "attend_an_event" && localStorage.setItem("attend_an_event", item?.configValue);
        });

        setBizBucksEarn(earnArray);
        setBizBucksRedeem(redeemArray);
      }
    } catch (e) {
      logError("Company: ", e);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className="shadow-ContentCards bg-white rounded-2xl w-[97%] md:p-5 p-3">
      <div className="md:grid-cols-12 grid-cols-5 grid w-full pb-2">
        <div className="flex md:col-span-5 col-span-3">
          <h1 className="text-normal md:text-2xl text-base flex justify-start">
            BizBucks
          </h1>
        </div>
      </div>

      <span>Earn</span>
      <div className="mt-2 ">
        <BizBucksItems
          BizBucks={bizBucksEarn}
          onUpdate={getBizBucksConfigAPI}
        />
      </div>

      <br />
      <br />
      <span>Redeem</span>
      <div className="mt-2 ">
        <BizBucksItems
          BizBucks={bizBucksRedeem}
          onUpdate={getBizBucksConfigAPI}
        />
      </div>
      {/* <div>
        <GeneratePromoCodeModal open={open} setOpen={setOpen} onUpdate={onUpdate} />
      </div> */}
    </div>
  );
};

export default BizBucksCard;
