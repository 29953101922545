import { getHeader, instance } from "config/apis";

// to get the admin by type
const adminByType = () => {
  return instance.get("v1/admin/getByType", getHeader());
};

const adminAddUser = (data) => {
  return instance.post("v1/admin/user/csv", data);
};
const adminAddUserByEco = (id, data) => {
  return instance.post(`v1/admin/user/csv/${id}`, data);
};
const adminGetUser = () => {
  return instance.get("v1/admin/user/csv", getHeader());
};
export { adminByType, adminAddUser, adminGetUser, adminAddUserByEco };
