import React, { useEffect, useState } from "react";
import { TagCloud } from "react-tagcloud";
import DropDownMenu from "./DropDownMenu";
import SelectOptions from "./SelectOptions";
import { strToMonthNumMap } from "helpers/defaultValues/dashboard";
import emptyImg from "../../../../assets/illustrations/empty.svg";
import { getSearchAnalytics } from "config/apis/dashboard.js";
const WordCloud = () => {
  const [filter, setFilter] = useState("all");
  const [year, setYear] = useState();
  const [option, setOption] = useState();
  const [wordCloudData, setWordCloudData] = useState();

  const filterWordCloud = async (month, year) => {
    let response = [];
    try {
      let query = { year };
      if (filter === "monthly") query.month = month;
      const { data } = await getSearchAnalytics(query);
      response = data.data;
    } catch (error) {
      console.error(error);
    }
    return response;
  };

  const fetchData = async () => {
    const month = strToMonthNumMap[option];
    let tempRes = await filterWordCloud(month, year);
    let tempData = tempRes?.map((item) => {
      return {
        value: item.search_query,
        count: item.count,
      };
    });
    setWordCloudData(tempData);
    if (filter === "all") setOption(undefined);
  };

  useEffect(() => {
    fetchData();
  }, [filter, option, year]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full p-5  ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">Search Trends</p>
        </div>
        <div className="flex items-center gap-2">
          {filter !== "all" && (
            <div>
              <SelectOptions
                filter={filter}
                setOption={setOption}
                setYear={setYear}
              />
            </div>
          )}
          <div>
            <DropDownMenu setFilter={setFilter} setYear={setYear} />
          </div>
        </div>
      </div>
      <div className="overflow-y-scroll h-[16.5rem] pt-2">
        {wordCloudData?.length > 0 ? (
          wordCloudData?.map((item, index) => {
            return (
              <div
                key={index}
                className="rounded-[10px] bg-[#00000005] grid grid-cols-12 mt-3 "
              >
                <div className="flex justify-start items-center p-2 col-span-10">
                  <p className="text-xl font-medium"> {item.value}</p>
                </div>{" "}
                <div className="col-span-2 rounded-tl-[10px] rounded-bl-[10px] p-2 bg-[#8BF693]  flex justify-center items-center flex-col">
                  <div>
                    <p className="text-[#464646]">{item?.count}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex w-full h-full justify-center gap-4 items-center">
            <img src={emptyImg} alt="No Data Found!" className="max-h-full" />
            <h1 className="text-light-blue-900 text-3xl">No Data Found!</h1>
          </div>
        )}
        {/* {wordCloudData?.length > 0 ? (
          <TagCloud
            minSize={12}
            maxSize={35}
            shuffle={true}
            tags={wordCloudData}
            onClick={(tag) => alert(`'${tag.value}' was selected!`)}
            colorOptions={{
              luminosity: "dark",
              hue: "random",
            }}
          />
        ) : (
          <div className="flex w-full h-full justify-center gap-4 items-center">
            <img src={emptyImg} alt="No Data Found!" className="max-h-full" />
            <h1 className="text-light-blue-900 text-3xl">No Data Found!</h1>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default WordCloud;
