import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";

import { HiOutlineTrash } from "react-icons/hi";
import CommonModal from "./modals/CommonModal";
import UpdateAccessModal from "./modals/UpdateAccessModal";
import moment from "moment";
import Toggle from "components/Common/Toggle";

// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";
import { deleteAdmin } from "config/apis/AdminAccess";
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";

const AdminCard = ({ admin, onUpdate }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [openCnf, setOpenCnf] = useState(false);

  const onDelete = async (id) => {
    setFetching(true);
    try {
      const res = await deleteAdmin(admin?.id);

      if (res.status === 200) {
        onUpdate();
        dispatch(showToast({ message: res?.data?.message }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE Admin", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }

    setFetching(false);
  };
  return (
    <>
      <div className="bg-white gap-3  ml-1.5 rounded-lg border-[1px] border-[#A5A3A3] p-3 grid grid-cols-12 mb-5">
        <div className="col-span-2  ">
          <Avatar src={admin?.image?.url} />
        </div>
        <div className="col-span-10 ml-3">
          <h3 className="font-medium text-sm"> {admin?.name}</h3>
          <p className="text-[#858585] font-normal text-xs">
            Since {moment(admin?.createdAt)?.format("LL")}
          </p>
        </div>
        <div className="col-span-6 flex items-end pb-[5px]">
          <button
            onClick={() => {
              setUpdateModal(true);
            }}
            className="underline text-[#039BE5] text-xs"
          >
            Update Access
          </button>
        </div>
        <div className="col-span-6 flex items-end justify-end">
          <div>
            <Toggle
              active={admin?.isActive}
              setIsActive={() => {
                setContent(
                  `Are you sure you want to ${
                    admin?.isActive ? "deactivate" : "activate"
                  } ${admin?.name}'s account?`
                );
                setOpenModal(true);
              }}
            />
          </div>
          <div className="flex">
            <button
              onClick={() => {
                setOpenCnf(true);
              }}
              className="cursor-pointer   transform transition ease-in-out duration-150 hover:scale-105"
            >
              <HiOutlineTrash className="text-2xl  text-[#C11717]" />
            </button>
          </div>
        </div>
      </div>

      <CommonModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        content={content}
        admin={admin}
        onUpdate={onUpdate}
      />
      <UpdateAccessModal
        admin={admin}
        updateModal={updateModal}
        setUpdateModal={setUpdateModal}
        onUpdate={onUpdate}
      />
      <ConfirmationDialog
        open={openCnf}
        setOpen={setOpenCnf}
        handleYes={() => onDelete()}
        message={`Are you sure you want to delete ${admin?.name}'s account?`}
      />
    </>
  );
};

export default AdminCard;
