export const DASHBOARD = "Dashboard";
export const ADMIN = "Admin";
export const SETTINGS = "Settings";
export const USERS = "Users";
export const UNVERIFIEDUSERS = "Unverified Users";
export const ECOSYSTEM = "Ecosystem";
// new added in phase 2
export const EVENTS = "Events";
export const SERVICEREQUESTS = "ServiceRequests";
export const PERKS = "Perks";
export const STATS = "Stats";
export const NOTIFICATION = "Notification";
