import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import "./index.css";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

const topBusinessesData1 = [
  { id: 1, business: "Biz 1", BizBucks: 1500 },
  { id: 2, business: "Biz 2", BizBucks: 1300 },
  { id: 3, business: "Biz 3", BizBucks: 1100 },
  { id: 4, business: "Biz 4", BizBucks: 900 },
  { id: 5, business: "Biz 5", BizBucks: 800 },
  { id: 6, business: "Biz 6", BizBucks: 750 },
  { id: 7, business: "Biz 7", BizBucks: 600 },
  { id: 8, business: "Biz 8", BizBucks: 400 },
  { id: 9, business: "Biz 9", BizBucks: 150 },
  { id: 10, business: "Biz 10", BizBucks: 50 },
  // ... more data
];

const distributionData1 = [
  { id: 1, value: 2, count: 2 },
  { id: 2, value: 4, count: 3 },
  { id: 3, value: 6, count: 5 },
  { id: 4, value: 8, count: 6 },
  { id: 5, value: 10, count: 4 },
  { id: 6, value: 12, count: 3 },
  { id: 7, value: 14, count: 1 },
  // ... more data
];

const topBusinessesData2 = [
  { id: 1, business: "Biz 1", BizBucks: 58 },
  { id: 2, business: "Biz 2", BizBucks: 50 },
  { id: 3, business: "Biz 3", BizBucks: 45 },
  { id: 4, business: "Biz 4", BizBucks: 34 },
  { id: 5, business: "Biz 5", BizBucks: 10 },
  { id: 6, business: "Biz 6", BizBucks: 55 },
  { id: 7, business: "Biz 7", BizBucks: 58 },
  { id: 8, business: "Biz 8", BizBucks: 27 },
  { id: 9, business: "Biz 9", BizBucks: 14 },
  { id: 10, business: "Biz 10", BizBucks: 5 },
];

const pieData = [
  { id: "Gold", label: "Gold", value: 10, color: "255, 203, 118" },
  { id: "Silver", label: "Silver", value: 60, color: "#D9D9D8" },
  { id: "Bronze", label: "Bronze", value: 30, color: "#D16868" },
];

const badgesData = [
  { id: 1, value: "Gold", members: 383 },
  { id: 2, value: "Silver", members: 542 },
  { id: 3, value: "Bronze", members: 542 },
];

const Stats = ({ topBusinessesData, distributionData }) => {
  return (
    <div className="grid grid-cols-1 gap-5 pl-5 pr-5">
      <div />
      <div className="mb-3">
        <h1 className="text-4xl text-medium">Member Statistics</h1>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1>BizBucks</h1>
          <span>Filter: past month</span>
        </div>
        <div className="dashboard-content">
          <div className="chart-container">
            <h2>Top 10 Businesses</h2>
            <div className="chart">
              <ResponsiveBar
                // gridYValues={gridYValues}
                data={topBusinessesData1}
                keys={["BizBucks"]}
                indexBy="business"
                margin={{ top: 80, right: 20, bottom: 50, left: 60 }}
                padding={0.1}
                //   colors={{ scheme: 'blues' }}
                colors={() => "#6CE5E7"}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -40,
                  tickValues: [0, 500, 1000, 1500, 2000],
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                yScale={{ type: "linear", min: 0, max: 2000 }}
                gridYValues={[0, 500, 1000, 1500, 2000]}
                //   barComponent={CustomBar} // Use the custom bar component
              />
            </div>
          </div>
          <div className="chart-container">
            <h2>Distribution of Number of BizBucks</h2>
            <div className="chart">
              <ResponsiveBar
                data={distributionData1}
                keys={["count"]}
                indexBy="value"
                margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                padding={0.1}
                //   colors={{ scheme: 'blues' }}
                colors={() => "#6CE5E7"}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 12,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -20,
                  tickValues: [0, 1, 2, 3, 4, 5, 6],
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                gridYValues={[0, 1, 2, 3, 4, 5, 6]}
                yScale={{ type: "linear", min: 0, max: 6 }}
              />
            </div>
          </div>
        </div>
      </div>

      <div />
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1>Backings</h1>
          <span>Filter: past year</span>
        </div>
        <div className="dashboard-content">
          <div className="chart-container">
            <h2>Top 10 Businesses</h2>
            <div className="chart">
              <ResponsiveBar
                // gridYValues={gridYValues}
                data={topBusinessesData2}
                keys={["BizBucks"]}
                indexBy="business"
                margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                padding={0.1}
                //   colors={{ scheme: 'blues' }}
                colors={() => "#6CE5E7"}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -40,
                  tickValues: [0, 10, 20, 30, 40, 50, 60],
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                yScale={{ type: "linear", min: 0, max: 60 }}
                gridYValues={[0, 10, 20, 30, 40, 50, 60]}
                //   barComponent={CustomBar} // Use the custom bar component
              />
            </div>
          </div>
          <div className="chart-container">
            <h2>Distribution of Number of Backings</h2>
            <div className="chart">
              <ResponsiveBar
                data={distributionData1}
                keys={["count"]}
                indexBy="value"
                margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                padding={0.1}
                //   colors={{ scheme: 'blues' }}
                colors={() => "#6CE5E7"}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 12,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -20,
                  tickValues: [0, 1, 2, 3, 4, 5, 6],
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                gridYValues={[0, 1, 2, 3, 4, 5, 6]}
                yScale={{ type: "linear", min: 0, max: 6 }}
              />
            </div>
          </div>
        </div>
      </div>

      <div />
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1>Activity</h1>
          <span>Filter: past 3 months </span>
        </div>
        <div className="dashboard-content">
          <div className="chart-container">
            <h2>Top 10 Businesses</h2>
            <div className="chart">
              <ResponsiveBar
                // gridYValues={gridYValues}
                data={topBusinessesData2}
                keys={["BizBucks"]}
                indexBy="business"
                margin={{ top: 20, right: 600, bottom: 50, left: 60 }}
                padding={0.1}
                //   colors={{ scheme: 'blues' }}
                colors={() => "#6CE5E7"}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -40,
                  tickValues: [0, 10, 20, 30, 40, 50, 60],
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                yScale={{ type: "linear", min: 0, max: 60 }}
                gridYValues={[0, 10, 20, 30, 40, 50, 60]}
                //   barComponent={CustomBar} // Use the custom bar component
              />
            </div>
          </div>
        </div>
      </div>

      {/* pie chart */}
      <div />
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1>Badges</h1>
        </div>
        <div className="dashboard-content">
          <div className="chart-container">
            <h2>Badge Distribution</h2>
            <div className="chart">
              <ResponsivePie
                data={pieData}
                margin={{ top: 80, right: 20, bottom: 30, left: 0 }}
                padAngle={0.7}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={0}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLinkLabel={(arc) =>
                  arc.data.label + "\n" + arc.data.value + "%"
                }
                // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                legends={[
                  {
                    anchor: "top",
                    direction: "row",
                    justify: false,
                    translateX: 10,
                    translateY: -80,
                    itemsSpacing: 20,
                    itemWidth: 50,
                    itemHeight: 50,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "square",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
                // enableSliceLabels={false} // Disable slice labels inside the pie
                // enableArcLabels={false} // Enable arc labels (weightage) outside the pie
                // arcLabel={(arc) => `${arc.data.label}: ${arc.data.value}%`} // Customize label format
              />
            </div>
          </div>

          <div className="badge-cards">
            {badgesData.map((badge) => (
              <div key={badge.id} className="badge-card">
                <div className="badge-info">
                  <h3>{badge.value}</h3>
                  <p>{badge.members} Members</p>
                </div>
                <div className="badge-icon">
                  {/* <img src={} alt="members icon" /> */}
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="spacer" />
    </div>
  );
};

export default Stats;
