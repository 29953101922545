/* eslint-disable no-undef */
let API_END_POINT = "https://dev-api.bizbackbone.com";
let ENDORSE_BASE_URL = "https://dev-app.bizbackbone.com";

switch (process.env.REACT_APP_DEVELOPMENT_TYPE) {
  case "production":
    // Endpoint for Production website
    API_END_POINT = "https://api.bizbackbone.com/api";
    break;
  case "staging":
    // Endpoint for Staging website
    API_END_POINT = "https://stage-api.bizbackbone.com/api";
    break;
  case "development":
    // Endpoint for Development website
    API_END_POINT = "https://dev-api.bizbackbone.com/api";
    break;
  case "local":
    // Endpoint for Local

    API_END_POINT = "https://dev-api.bizbackbone.com/api";
    // API_END_POINT = "http://65.2.62.153/api";
    // API_END_POINT = "https://stage-api.bizbackbone.com/api";
    break;
  default:
    // Default Endpoint
    API_END_POINT = "https://dev-api.bizbackbone.com/api";
  // API_END_POINT = "http://localhost:3002/api";
}
console.log(API_END_POINT);
export default API_END_POINT;
export { ENDORSE_BASE_URL };
