import React, { useEffect, useState,  useMemo } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdInfo } from "react-icons/md";
import NotificationInfoModal from "./NotificationInfoModal";
import {
  getAdminNotification,
  UpdateStatusOfAdminNotification,
} from "config/apis/Notification";
import Pagination from "components/Common/Pagination";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { typesToAdminNotificationFilter } from "helpers/constants/localJsonData";
import { useLocation, useHistory } from "react-router-dom";
import { io } from "socket.io-client";
import { socketBaseUrl } from "helpers/utils";

const socket = io(socketBaseUrl());

const SeeAllNotification = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [PageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    try {
      console.log(socket, "socket>>>>>>>>>>>>>>>>>>....");

      const handleNotification = (data) => {
        // Append the new notification to the existing list
        // data?.notifyToAdmin && dispatch(showToast({ message: data?.description }));
        setNotificationList((prevList) => [data, ...prevList]);
      };

      socket.on("notification", handleNotification);
      socket.on("connect_error", (err) => {
        console.error("Connection Error:", err);
      });

      socket.on("error", (err) => {
        console.error("WebSocket Error:", err);
      });

      // Clean up the listener on component unmount
      return () => {
        socket.off("notification", handleNotification);
      };
    } catch (err) {
      console.log("catch block", err);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getNotification();
  }, [PageNo]);

  const getNotification = async () => {
    try {
      let res;
      res = await getAdminNotification(PageNo);

      if (res?.data?.success) {
        let data = res?.data;
        setTotalPages(
          Math.ceil(
            data?.notificationList?.count / 10
          )
        );
        let notif = data?.notificationList?.rows?.filter(
          (item) => !item.seen && item?.notifyToAdmin 
        );
        setNotificationList(notif); //notifyToAdmin
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log("error");
    }
  };

  const handleUpdateNotification = async (status) => {
    console.log("handleUpdateNotification ==> ", status);

    try {
      const res = await UpdateStatusOfAdminNotification(status);
      dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
      getNotification(PageNo);
    } catch (e) {
      console.error("Error updating badge tiers:", e);
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  const _renderNotification = () => {
    return (
      <div>
        {typesToAdminNotificationFilter.map((itemp, index) => {
          // Filter the notificationList based on the current type in the loop
          const filteredNotifications = notificationList.filter(
            (item) => item.notifyToAdmin && item.type === itemp.type
          );
          const filteredSeenNotifications = filteredNotifications?.filter(
            (item) => !item.seen && item?.notifyToAdmin 
          );

          return (
            <div className="my-5" key={index}>
              {/* Render the title */}

              {filteredSeenNotifications.length > 0 && 
              <>
              <h2
                className="flex items-center gap-2"
                style={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                {itemp?.title}
              </h2>

              
              { 
                filteredSeenNotifications?.map((notification) => (
                  <div
                    onClick={() => {
                      if (notification?.type?.includes("podcast")) {
                        history.push("/servicerequests");
                      } else if (notification?.type?.includes("spotlight")) {
                        history.push("/power-hour", {
                          eventName: notification?.eventDetails?.eventName,
                          id: notification?.eventDetails?.id,
                        });
                      } else if (
                        notification?.type?.includes("registration") ||
                        notification?.type?.includes("resgitration")
                      ) {
                        history.push("/power-hour", {
                          eventName: notification?.eventDetails?.eventName,
                          id: notification?.eventDetails?.id,
                        });
                      } else {
                        console.log(
                          "filteredSeenNotifications ==> item ==> ",
                          JSON.stringify(notification)
                        );
                      }
                    }}
                    key={notification?.id}
                    className="event-notification-list bg-neutral-gray-100 flex items-center justify-between p-2 rounded-lg mt-2 max-w-2xl"
                  >
                    <div>{notification?.description}</div>
                    <div className="flex items-center gap-2">
                      {/* <button>
                                            <MdInfo
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                                size={"2rem"}
                                            />
                                        </button> */}
                      <button
                        className="rounded-md bg-primary-blue-dark p-1 px-4 text-white"
                        onClick={() => {
                          handleUpdateNotification({
                            id: notification?.id,
                            query: {
                              seen: 1,
                            },
                          });
                        }}
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                ))
              /* ) : (
                <p>No notifications available</p> // Display a message if there are no notifications for the type
              ) */
              } 

              </> }
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid p-5">
        {/* {location.state?.showBackButton && (
          <div className="row">
            <IoArrowBackCircle
              className="cursor-pointer"
              size={"3rem"}
              onClick={() => history.back()}
            />
          </div>
        )} */}

        <div className="row mb-5">
          <h2
            className="flex items-center gap-2"
            style={{ fontSize: "2rem", fontWeight: "500" }}
          >
            Notifications
          </h2>
        </div>

        <div className="row my-5">
          {/* {notificationList?.length > 0 ? <div>{_renderNotification()}</div> : <p>No notifications available</p>} */}
          <div>{_renderNotification()}</div>
        </div>

        {notificationList?.length > 0 && totalPages > 0 ? (
          <div>
            <Pagination
              pageNo={PageNo}
              setPageNo={setPageNo}
              totalPages={totalPages}
            />
          </div>
        ) : (
          <div className="text-center text-gray-400 mt-2">All caught up</div>
        )}

        <NotificationInfoModal
          setOpen={setOpen}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default SeeAllNotification;
