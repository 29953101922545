import { instance, getHeader } from "config/apis";

//Update admin Access

export const UpdateAdmin = (body, id) => {
  return instance.put(`/v1/admin/update/${id}`, body, getHeader());
};

export const deleteAdmin = (id) => {
  return instance.delete(`/v1/admin/delete/${id}`, getHeader());
};
