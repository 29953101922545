import Loader from "components/Common/Loader";
import { sendRequestToChangeAPI } from "config/apis/UserAPIs";
import { dashboardData } from "config/apis/dashboard.js";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { HiTrash } from "react-icons/hi";
import Button from "components/Common/Button";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import AddEcosystemModal from "components/PrivatePages/SubscriptionDashboard/modals/AddEcosystem";
import Row from "./Row";
const Ecosystem = () => {
  const [addEco, setAddEco] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ecosystems, setEcosystems] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardData();
      if (res?.data?.result) {
        console.log(res?.data?.result?.ecosystems);
        setEcosystems(res?.data?.result?.ecosystems || []);
      }
    } catch (e) {
      logError("Dashboard", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
      if (e?.response?.status == 401) {
        localStorage.removeItem("backboneAdmin");
        history.push("/");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const sendRequestToChange = async (id) => {
    const body = { id: id };
    try {
      const res = await sendRequestToChangeAPI(body);
      console.log(res);
      dispatch(
        showToast({
          message: res?.data?.message,
          type: "success",
        })
      );
      getDashboardData();
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  return (
    <div className="w-full flex flex-col space-y-4 p-3 md:p-6">
      <AddEcosystemModal
        isOpen={addEco}
        closeModal={() => {
          setAddEco(false);
        }}
        onUpdate={() => getDashboardData()}
      />{" "}
      {isLoading && <Loader />}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-2xl md:text-4xl font-poppins font-normal m-0">
          Ecosystems
        </h1>
        <Button
          onClick={() => {
            setAddEco(true);
          }}
          text="Add Ecosystem"
        />
      </div>
      {ecosystems?.map((eco) => (
        <Row
          onUpdate={getDashboardData}
          eco={eco}
          sendRequestToChange={sendRequestToChange}
        />
      ))}
    </div>
  );
};

export default Ecosystem;
