//Sign in form component for sign in page
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//icons
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";

// APIs
import { adminResetPassword } from "config/apis/auth";

/**
 * Reset password form
 * @returns { Component }
 */
const ResetPasswordForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter your password.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmPassword: Yup.string()
        .required("Please enter confirm password.")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
    }),
    onSubmit: (values) => {
      onResetPassword(values);
    },
  });

  const onResetPassword = async (values) => {
    try {
      const arr = history.location.pathname.split("/");
      const token = arr[arr.length - 1];
      const body = { ...values };
      const res = await adminResetPassword(body, token);
      if (res.data?.success) {
        history.replace("/auth/signIn");
        dispatch(showToast({ message: res.data?.message }));
      } else {
        dispatch(showToast({ message: res.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Reset Password", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <div className="w-full h-screen items-center justify-center flex-row flex px-4 py-8">
        <div className="w-full max-w-lg md:w-3/4 h-full flex flex-row items-center justify-center">
          <div className="w-full grid grid-cols-1 gap-3">
            <div className="justify-center items-center flex mb-5">
              <img src="/assets/images/logos/Logo.png"></img>
            </div>
            <div className="justify-center items-center flex">
              <h1 className="font-semibold text-4xl">Forgot Password</h1>
            </div>
            <div className="justify-center items-center grid-cols-1 mt-2 ">
              <p className="text-base justify-start items-center flex  text-neutral-gray-light font-semibold">
                New Password*
              </p>
              <div className="w-full rounded bg-transparent border-[1px] border-[#8D8D8D] flex items-center">
                <input
                  className="border-none w-[95%] rounded focus:ring-transparent focus:border-transparent"
                  type={visible1 ? "text" : "password"}
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <button onClick={() => setVisible1(!visible1)}>
                  {visible1 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="bg-gray-100 p-2 my-1">
                  {formik.errors.password.split(",").map((item, id) => {
                    return (
                      <p key={id} className="text-red-600 text-sm">
                        {item}
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="justify-center items-center grid-cols-1 mt-2 ">
              <p className="text-base justify-start items-center flex  text-neutral-gray-light font-semibold">
                Confirm New Password*
              </p>
              <div className="w-full rounded bg-transparent border-[1px] border-[#8D8D8D] flex items-center">
                <input
                  className="border-none w-[95%] rounded focus:ring-transparent focus:border-transparent"
                  type={visible2 ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                />
                <button onClick={() => setVisible2(!visible2)}>
                  {visible2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              </div>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-red-600 text-sm bg-gray-100 my-1 p-2">
                  {formik.errors.confirmPassword}
                </p>
              ) : null}
            </div>

            <div className="justify-center items-center flex">
              <button
                onClick={formik.handleSubmit}
                type="submit"
                className="bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-2/5 w-3/5 h-10 rounded-lg font-bold text-xl"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
