//Open and close modal for AddAdmin

import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MenuItem, Select } from "@mui/material";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
//style sheet
import "assets/styles/scss/_styles/_addAdmin.scss";
import { AddNewAdmin } from "config/apis/dashboard.js";
import AdminPasswordModal from "./PasswordModal";
const AddAdmin = ({ addAdminModal, setAddAdminModal, onUpdate }) => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState({});

  const handleClose = () => {
    setAddAdminModal(false);
  };
  const dispatch = useDispatch();
  const AddAdminAPI = async (body) => {
    try {
      const res = await AddNewAdmin(body);

      if (res?.status == 201) {
        let data = res?.data;
        setPassword(data.admin.password);
        setOpenPasswordModal(true);
        setAdmin(data?.admin);
        onUpdate();
        dispatch(showToast({ message: res?.data?.message }));
        formik.resetForm();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("GET SUPER ADMIN INFO", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      email: Yup.string()
        .email("please enter a valid email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      let payload = {
        isSuperAdmin: false,
        phone: "",
      };

      AddAdminAPI({ ...payload, ...values });
    },
  });
  return (
    <div>
      <Modal
        open={addAdminModal}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal13">
          <div className="divide-y">
            <div className="pb-3">
              <h1 className="font-medium text-2xl">Add Super Admin</h1>
            </div>
            <div className="grid grid-cols-1 gap-4 pt-7">
              <div>
                <Select
                  name="type"
                  id="type"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.type}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10  font-normal text-sm text-[#5b5a5a]"
                  placeholder="Select admin type"
                >
                  <MenuItem value="Content">Content</MenuItem>
                  <MenuItem value="Subscription">Subscription</MenuItem>
                  <MenuItem value="System">System</MenuItem>
                  <MenuItem value="Analytical">Analytical</MenuItem>
                </Select>
                <p className="text-red-600 text-sm">
                  {formik.touched.type && formik.errors.type
                    ? formik.errors.type
                    : null}
                </p>
              </div>
              <div>
                <input
                  id="name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10 pl-1 font-normal text-sm text-[#5b5a5a]"
                  placeholder="Enter Name"
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : null}
                </p>
              </div>
              <div>
                <input
                  id="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10 pl-1 font-normal text-sm text-[#5b5a5a]"
                  placeholder="Enter Email ID"
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null}
                </p>
              </div>

              <div className="box-modal13-lastRow">
                <button
                  onClick={() => {
                    setAddAdminModal(false);
                  }}
                  className="box-modal13-lastRow-no"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    setAddAdminModal(false);
                  }}
                  className="box-modal13-lastRow-yes"
                >
                  Save admin
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <AdminPasswordModal
        openPasswordModal={openPasswordModal}
        setOpenPasswordModal={setOpenPasswordModal}
        password={password}
        admin={admin}
      />
    </div>
  );
};

export default AddAdmin;
