import React from "react";

/**
 * Page Divider
 * @returns { Component }
 */
function Divider() {
  return <div className="w-full divider"></div>;
}

export default Divider;
