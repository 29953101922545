import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("backboneAdminData")) || {},
  backboneAdmin: localStorage.getItem("backboneAdmin") || "",
  types: JSON.parse(localStorage.getItem("backboneAdminData"))?.type || "",
  isSuperAdmin:
    JSON.parse(localStorage.getItem("backboneAdminData"))?.isSuperAdmin || "",
};

// Storing User details and Token
export const backboneAdmin = createSlice({
  name: "user",
  initialState,
  reducers: {
    userUpdate: (state, action) => {
      if (action.payload?.admin) {
        let u = action.payload?.admin;
        state.user = u;
        state.types = u?.type;
        state.isSuperAdmin = u?.isSuperAdmin;
        localStorage.setItem("backboneAdminData", JSON.stringify(u));
      }
      if (action.payload?.types) {
        let t = action.payload?.types;
        state.types = t;
      }
      if (action.payload?.backboneAdmin) {
        let t = action.payload?.backboneAdmin;
        state.backboneAdmin = t;
        localStorage.setItem("backboneAdmin", t);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { userUpdate } = backboneAdmin.actions;

export default backboneAdmin.reducer;
