//Monthly subscription card subscription dashboard
import React from "react";
//Common button component

const MonthlySubscriptionCard = ({
  subscription,

  setEditCard,
  setCardDetails,
}) => {
  let description = subscription.subscription_description.split(",");
  console.log(subscription, description);
  return (
    <>
      <div className="grid grid-cols-12 border-[1px] min-h-[28rem] border-[#5F5F5F] bg-white rounded-lg p-4 pl-6 pr-6">
        <div className="col-span-12 flex justify-center">
          <h1 className="text-[#7D7D7D] text-lg font-semibold">
            {subscription?.subscription_name}
          </h1>
        </div>
        <div className="col-span-12 flex justify-center">
          <h1 className="font-medium text-[2.8rem] ">
            {subscription?.subscription_cost}
          </h1>
        </div>
        <div className="col-span-12 pt-3 min-h-[16rem] pb-3">
          <ul className="text-base text-[#646464] font-normal list-disc pl-5 fixed-sub-card">
            {description?.length > 0 &&
              description?.map((items, index) => {
                return <li key={index}> {items}</li>;
              })}
          </ul>
        </div>
        <div className="col-span-12 flex justify-center  mt-5">
          <button
            onClick={() => {
              setEditCard(true);
              setCardDetails({
                id: subscription.id,
                subscription_name: subscription.subscription_name,
                subscription_cost: subscription.subscription_cost,
                subscription_description: subscription.subscription_description,
              });
            }}
            className="bg-[#067EED]  cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white text-2xl font-semibold w-[260px] h-12 rounded-md "
          >
            Make changes
          </button>
        </div>
      </div>
    </>
  );
};

export default MonthlySubscriptionCard;
