// DONE

//Page for creating a new blog
import React from "react";
//Create blog card
import CreateEditBlog from "components/PrivatePages/Blogs/CreateEditBlog";
import { useParams } from "react-router-dom";

const AddBlog = () => {
  const { type } = useParams();
  return (
    <div>
      <div className="mt-3 mb-10">
        <h1 className="text-medium text-2xl">
          {type == "edit" ? "Edit" : "Create"} Blog
        </h1>
      </div>
      <div className="mt-5">
        <CreateEditBlog />
      </div>
    </div>
  );
};

export default AddBlog;
