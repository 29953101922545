//Open and close modal for adding generating new promo codes

import React from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

//Icons
import { TbUserCircle } from "react-icons/tb";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { sendMessageAPI } from "config/apis/UserAPIs";

const SendMessage = ({ openMessage, setOpenMessage, userID }) => {
  const dispatch = useDispatch();
  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpenMessage(true);
  const handleClose = () => {
    setOpenMessage(false);
  };

  const SendMessageToUserAPI = async (payload) => {
    try {
      console.log(payload);
      const res = await sendMessageAPI(payload);

      if (res.status === 200) {
        let data = res?.data;

        console.log(data);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("SEND MESSAGE", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      message: "",
      email: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setOpenMessage(false);
      let payload = {
        email: values.email,
        message: values.message,
        userId: userID,
      };
      console.log(payload);
      SendMessageToUserAPI(payload);
    },
  });
  return (
    <div>
      <Modal
        open={openMessage}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal2">
          <Grid container>
            <Grid item xs={12} className="box-modal2-row1">
              <Grid item xs={1} className="box-modal2-row1-iconDiv">
                <TbUserCircle className="box-modal2-row1-iconDiv-icon" />
              </Grid>
              <Grid item xs={11}>
                <Typography className="box-modal2-row1-header">
                  Send Message
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className="box-modal2-row2">
              <Typography className="box-modal2-row2-label">
                Send to :
              </Typography>
              <input
                id="email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className="box-modal2-row2-input"
                placeholder="xyz@gmail.com"
              />
              <p className="text-red-600 text-sm">
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </p>
            </Grid>
            {/* <Grid item xs={12} className="box-modal2-row3"> */}
            <textarea
              name="message"
              id="message"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.message}
              className="w-full rounded min-h-[10rem] mb-5"
            />
            <p className="text-red-600 text-sm">
              {formik.touched.message && formik.errors.message
                ? formik.errors.message
                : null}
            </p>
            {/* </Grid> */}
            <Grid item xs={12} className="box-modal2-row4">
              <button
                onClick={() => {
                  setOpenMessage(false);
                }}
                className="box-modal2-row4-no"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                className="box-modal2-row4-yes"
              >
                Send
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default SendMessage;
