import React, { useEffect, useState, useContext } from "react";
import Rating from "@mui/material/Rating";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import favi from "../../../assets/logos/BackboneLogo.svg";
import Helmet from "react-helmet";
import Loader from "../../../components/Common/Loader";
import Tags from "../../../components/PrivatePages/User/Tags";
import IndustryType from "../../../components/PrivatePages/User/Industry/IndustryType";
import OtherInfo from "../../../components/PrivatePages/User/Other/OtherInfo";
import ProfileReviews from "../../../components/PrivatePages/User/Profile/ProfileReviews";
import MemberSince from "../../../components/PrivatePages/User/Member/MemberSince";
import ProfileBasicDetails from "../../../components/PrivatePages/User/Profile/ProfileBasicDetails";
import ProfileSocialLinks from "../../../components/PrivatePages/User/Profile/ProfileSocialLinks";
import ProfileLeaders from "../../../components/PrivatePages/User/Profile/ProfileLeaders";
import RequestEndorse from "../../../components/PrivatePages/User/RequestEndorse";
import Activity from "components/PrivatePages/User/Activity/Activity";
import {
  companyDataAPI,
  updateCompanyDataAPI,
  addDemoBannerAPI,
  updateDemoBannerAPI,
  addBannerAPI,
  updateBannerAPI,
  bizBucksDataByIdAPI,
  getTotalBacked,
} from "../../../config/apis/Company";

import logError from "helpers/utils/logErrors";
import BizbucksBack from "components/PrivatePages/User/BizBucks/BizbucksBack";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

const index = () => {
  let { id } = useParams();

  const dispatch = useDispatch();

  const [myCompany, setMyCompany] = useState();
  const [findAllBackedCompany, setFindAllBackedCompany] = useState();
  const [findAllBackedUsCompany, setFindAllBackedUsCompany] = useState();
  const [bizBucks, setBizBucks] = useState();
  const [totalBacked, setTotalBacked] = useState();
  const [checknewMem, setChecknewMem] = useState(false);
  const [user, setUser] = useState({});
  const [newMember, setNewMember] = useState("");
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [logo, setLogo] = useState();
  const [logoPreview, setLogoPreview] = useState();
  const [demoBanner, setDemoBanner] = useState([""]);
  const [updateBanner, setUpdateBanner] = useState("");
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState("");
  const [chars, setChars] = useState(550);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isError1, setIsError1] = useState(false);
  const [dontHave, setDontHave] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    company_name: "",
    about_company: "",
    file: "",
  });

  useEffect(() => {
    getCompanyData();
  }, []);

  // const getCompanyData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await companyDataAPI(id);
  //     if (res) {
  //       // console.log("res.data.company ==> ", JSON.stringify(res.data))
  //       await getBizBucksDataById()
  //       await getTotalBackedCount()
  //       setMyCompany(res.data.company);
  //       setFindAllBackedCompany(res.data.findAllBackedCompany);
  //       setFindAllBackedUsCompany(res.data.findAllBackedUsCompany);
  //       // console.log("Res: ", res.data.company);
  //     }
  //   } catch (e) {
  //     setIsLoading(false);
  //     logError("Company: ", e);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getCompanyData = async () => {
    setIsLoading(true);
    try {
      // Perform all API calls concurrently using Promise.all
      const [companyRes, bizBucksRes, totalBackedRes] = await Promise.all([
        companyDataAPI(id),
        bizBucksDataByIdAPI(id),
        getTotalBacked(id),
      ]);

      if (companyRes) {
        setMyCompany(companyRes.data.company);
        setFindAllBackedCompany(companyRes.data.findAllBackedCompany);
        setFindAllBackedUsCompany(companyRes.data.findAllBackedUsCompany);
      }

      if (bizBucksRes?.data?.success) {
        setBizBucks(bizBucksRes.data.bizBucks);
      } else {
        dispatch(
          showToast({ message: bizBucksRes.data?.message, type: "error" })
        );
      }

      if (totalBackedRes?.data?.success) {
        setTotalBacked(totalBackedRes?.data?.totalBacked);
      } else {
        dispatch(
          showToast({ message: totalBackedRes.data?.message, type: "error" })
        );
      }
    } catch (e) {
      logError("Company: ", e);
      dispatch(showToast({ message: e?.message, type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  //   const getBizBucksDataById = async () => {
  //     try {
  //       const res = await bizBucksDataByIdAPI(id);
  //       if (res?.data?.success) {
  //         // console.log("res.data.bizBucks ==> ", JSON.stringify(res.data.bizBucks))
  //         setBizBucks(res.data.bizBucks);
  //         // console.log("Res: ", res.data.company);
  //       } else {
  //         dispatch(showToast({ message: res.data?.message, type: "error" }));
  //       }
  //     } catch (e) {
  //       logError("Company: ", e);
  //       setIsLoading(false);
  //       dispatch(showToast({ message: e?.message, type: "error" }));
  //     }
  //   };

  //   const getTotalBackedCount = async () => {
  //     try {
  //       const res = await getTotalBacked(id);
  //       if (res?.data?.success) {
  //         setTotalBacked(res?.data?.totalBacked)
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //         console.log("error");
  //         dispatch(showToast({ message: res.data?.message, type: "error" }));
  //       }
  //     } catch (e) {
  //       console.error("Error getTotalBackedCount:", e);
  //       setIsLoading(false);
  //       dispatch(showToast({ message: e?.message, type: "error" }));
  //     }
  // };

  const demoBanners = [
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner1.png",
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner2.png",
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner3.png",
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner4.png",
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner5.png",
    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner6.png",
  ];

  const editProfile = async () => {
    let profile = new FormData();
    if (name && name !== "") {
      profile.append("company_name", name);
    }
    if (about && about !== "") {
      profile.append("about_company", about);
    }
    if (logo && logo !== "") {
      profile.append("file", logo);
    }
    try {
      let res = await updateCompanyDataAPI(myCompany.id, profile);
    } catch {}

    let data = new FormData();

    if (dontHave) {
      if (myCompany?.Banners.length === 0) {
        data.append("file", demoBanner);
        try {
          await addDemoBannerAPI(myCompany.id, data);
        } catch {}
      } else {
        data.append("file", demoBanner);
        try {
          await updateDemoBannerAPI(myCompany.id, data);
        } catch {}
      }
    } else if (myCompany?.Banners.length === 0) {
      if (banner) {
        data.append("file", banner);
        try {
          await addBannerAPI(myCompany.id, data);
        } catch {}
      }
    } else {
      data.append("file", banner);
      try {
        await updateBannerAPI(myCompany.id, data);
      } catch {}
    }

    setShowModal(false);
    getCompanyData();
  };

  const onFileSelect = (e) => {
    const reader = new FileReader();
    if (e.target.files[0].size > 512000) {
      setIsError1(true);
      return;
    }

    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoPreview(reader.result);
      }
    };

    setLogo(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  const onFileSelectBanner = (e) => {
    const reader = new FileReader();
    if (e.target.files[0].size > 5242880) {
      setIsError(true);
      return;
    }

    // console.log(e.target.files[0]);

    // console.log("works");
    reader.onload = () => {
      if (reader.readyState === 2) {
        // console.log(reader.result);
        setBannerPreview(reader.result);
      }
    };

    setBanner(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  const onFileSelectDemoBanner = (item) => {
    setBanner(item);
    setBannerPreview(item);
    setDemoBanner(item);
    setUpdateBanner(item);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Helmet>
        <link rel="icon" type="image/svg" href={favi} sizes="16x16" />
      </Helmet>
      {!myCompany?.avg_ratings ? (
        <Loader />
      ) : (
        <div className="profile">
          <div className="profile-top">
            <div className="profile-top-shadow">
              <div className="profile-background">
                <figure>
                  <img
                    src={
                      !(myCompany?.Banners.length === 0)
                        ? myCompany.Banners[0].banner_pic
                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/default_banner.svg"
                    }
                    alt="Background"
                    className="object-cover"
                  />
                </figure>
              </div>
              <div className="profile-pictures">
                <figure>
                  <img
                    src={
                      myCompany?.company_logo
                        ? myCompany.company_logo
                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                    }
                    alt="Profile"
                    style={{ objectFit: "cover" }}
                  />
                </figure>
              </div>
              <AiOutlineEdit
                className="profile-edit-icon"
                onClick={() => {
                  setShowModal(true);
                  setName(myCompany?.company_name);
                  setAbout(myCompany?.about_company);
                  setBannerPreview(myCompany?.Banners[0]?.banner_pic);
                }}
              />
              <div className="profile-pictures-blank  " />
              <div className="profile-content">
                <div className="profile-names">
                  <div className="company_name">
                    <h3>
                      {myCompany?.company_name
                        ? myCompany?.company_name
                        : "Lorem Ipsum"}
                    </h3>
                    {checknewMem && (
                      <div className="newMember">
                        <img src={newMember} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="profile-rating">
                    <Rating
                      name="read-only"
                      value={
                        myCompany?.Reviews?.length === 0 && checknewMem
                          ? 5
                          : myCompany?.Reviews?.length === 0 && !checknewMem
                          ? 3
                          : myCompany.avg_ratings
                      }
                      readOnly
                      precision={0.25}
                    />
                  </div>
                </div>
              </div>
              <div className="relative"></div>
            </div>
          </div>
          <div className="hide-mobile">
            <div className="profile-down">
              <div className="profile-down-left">
                <div className="left-info">
                  <div className="profile-about">
                    <div>
                      <h4>About</h4>
                      <p
                        style={{
                          wordBreak: "break-all",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {myCompany?.about_company
                          ? myCompany?.about_company
                          : "Company About"}
                      </p>
                    </div>
                  </div>
                  <Tags
                    industry1={myCompany?.Industry}
                    prefs={myCompany?.Industrypreferences}
                    services={services}
                    serv={myCompany?.Services}
                    isMine={user?.company_id === myCompany?.id || true}
                    company_id={myCompany?.id}
                    getCompanyData={getCompanyData}
                  />
                  {/* <IndustryType
                    companyIndustry={myCompany?.Industry}
                    isMine={true}
                    company_id={myCompany?.id}
                    getCompanyData={getCompanyData}
                  />
                  <OtherInfo
                    isMine={user?.company_id === myCompany?.id || true}
                    title={myCompany?.OtherInfos}
                    pdfLinks={myCompany?.Pdfs}
                    images={myCompany?.Images}
                    links={myCompany?.Links}
                    comp={myCompany}
                    getCompanyData={getCompanyData}
                  /> */}
                  <Activity
                    findAllBackedCompany={findAllBackedCompany}
                    findAllBackedUsCompany={findAllBackedUsCompany} //Companies that have backed you
                    isMine={user?.company_id === myCompany?.id || true}
                    getCompanyData={getCompanyData}
                    company={myCompany}
                  />
                </div>
                {/* <RequestEndorse
                  myCompany={myCompany}
                />
                <ProfileReviews
                  id={myCompany?.id}
                  isMine={user?.company_id === myCompany?.id || true}
                  reviews={myCompany?.Reviews}
                /> */}
              </div>
              <div className="profile-down-right">
                <BizbucksBack
                  totalBacked={totalBacked}
                  bizBucks={bizBucks}
                  getCompanyData={getCompanyData}
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                />
                {/* <MemberSince date={myCompany?.createdAt} /> */}
                <ProfileBasicDetails
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />
                <ProfileSocialLinks
                  isMine={user?.company_id === myCompany?.id || true}
                  socials={myCompany?.Socials}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />
                <ProfileLeaders
                  leaders={myCompany?.Leaders}
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />
              </div>
            </div>
          </div>
          <div className="hide-laptop">
            <div className="profile-down my-3">
              <div className="profile-mobile-margin">
                <BizbucksBack
                  totalBacked={totalBacked}
                  bizBucks={bizBucks}
                  getCompanyData={getCompanyData}
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                />
                {/* <MemberSince date={myCompany?.createdAt} /> */}
                <Tags
                  industry1={myCompany?.Industry}
                  services={services}
                  serv={myCompany?.Services}
                  isMine={user?.company_id === myCompany?.id || true}
                  company_id={myCompany?.id}
                  getCompanyData={getCompanyData}
                />
                {/* <IndustryType
                  companyIndustry={myCompany?.Industry}
                  isMine={true}
                  company_id={myCompany?.id}
                  getCompanyData={getCompanyData}
                /> */}
                <ProfileBasicDetails
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />
                <ProfileSocialLinks
                  isMine={user?.company_id === myCompany?.id || true}
                  socials={myCompany?.Socials}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />

                <OtherInfo
                  isMine={user?.company_id === myCompany?.id || true}
                  title={myCompany?.OtherInfos}
                  pdfLinks={myCompany?.Pdfs}
                  images={myCompany?.Images}
                  links={myCompany?.Links}
                  comp={myCompany}
                  getCompanyData={getCompanyData}
                />

                <ProfileLeaders
                  leaders={myCompany?.Leaders}
                  isMine={user?.company_id === myCompany?.id || true}
                  company={myCompany}
                  getCompanyData={getCompanyData}
                />
                <Activity
                  findAllBackedCompany={findAllBackedCompany}
                  findAllBackedUsCompany={findAllBackedUsCompany}
                  isMine={user?.company_id === myCompany?.id || true}
                  getCompanyData={getCompanyData}
                  company={myCompany}
                />
                {/* <RequestEndorse
                  id={myCompany?.id}
                />
                <ProfileReviews
                  id={myCompany?.id}
                  isMine={user?.company_id === myCompany?.id || true}
                  reviews={myCompany?.Reviews}
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal */}
      <div className="profile-modal modal-wrapper">
        <Dialog
          style={{
            minWidth: "345px",
            maxWidth: "800px",
            width: "100%",
          }}
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal bg-white shadow-none overflow-y-scroll max-h-[75vh]"
        >
          <DialogHeader>
            <h1>Edit Company Details</h1>
          </DialogHeader>
          <DialogBody>
            <div className="flex flex-col">
              <div className="change-profile-logo flex-col justify-between items-center gap-4">
                <h1>Company Logo</h1>
                <img
                  src={
                    logoPreview
                      ? logoPreview
                      : myCompany?.company_logo
                      ? myCompany.company_logo
                      : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                  }
                  alt="company-logo"
                  className="object-cover"
                />
                <div className="input-field flex-col justify-around">
                  <label htmlFor="logo">Upload Logo</label>
                  <span
                    style={{
                      justifyContent: "center",
                      display: !isError1 ? "flex" : "none",
                      paddingLeft: "0.7rem",
                      fontSize: "12px",
                    }}
                  >
                    Max 500 KB
                  </span>
                  <span
                    style={{
                      justifyContent: "center",
                      display: isError1 ? "flex" : "none",
                      paddingLeft: "1rem",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    Max 500 KB
                  </span>
                  <input
                    name="companyLogo"
                    id="logo"
                    type="file"
                    accept=".jpg, .png"
                    onChange={(file) => {
                      // console.log(file);
                      onFileSelect(file);
                    }}
                  />
                </div>
              </div>
              <div className="change-profile-banner flex-col justify-between items-center gap-4">
                <h1>Company Banner</h1>
                <img
                  className="object-cover"
                  src={
                    bannerPreview
                      ? bannerPreview
                      : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/default_banner.svg"
                  }
                  alt="Lorem"
                />
                <div className="input-field">
                  <div>
                    <button onClick={() => setDontHave(!dontHave)}>
                      Don't have a banner
                    </button>
                  </div>
                  <div className="upload-banner-btn">
                    <label htmlFor="banner">Upload Banner</label>
                    <div className="banner-instructions">
                      <span
                        style={{
                          justifyContent: "center",
                          display: !isError ? "flex" : "none",

                          fontSize: "12px",
                        }}
                      >
                        Dimensions : 1315 * 275
                      </span>
                      <span
                        style={{
                          justifyContent: "end",
                          display: !isError ? "flex" : "none",

                          fontSize: "12px",
                        }}
                      >
                        Max 5 MB
                      </span>
                      <span
                        style={{
                          justifyContent: "center",
                          display: isError ? "flex" : "none",
                          paddingLeft: "9rem",
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Max 5 MB
                      </span>
                    </div>
                    <input
                      name="bannerImg"
                      id="banner"
                      type="file"
                      accept=".jpg, .png"
                      onChange={(e) => {
                        onFileSelectBanner(e);
                        // console.log(banner);
                      }}
                    />
                  </div>
                </div>
              </div>
              {dontHave && (
                <>
                  <div className="row-banner">
                    <div className="row-banner-message">
                      <h2>
                        Don't have a banner, don't worry we've got your back.
                      </h2>
                      <p>Select from what we have designed you.</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                      className="col-md-4 col-sm-6 col-12 my-2"
                    >
                      {demoBanners.map((item, key) => {
                        // console.log("item", item);
                        return (
                          <img
                            style={{
                              margin: "0.5rem",
                            }}
                            src={item}
                            key={key}
                            alt="Lorem"
                            onClick={() => onFileSelectDemoBanner(item)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              <div className="company-name flex flex-col items-center justify-between">
                <h1>Company Name</h1>
                <div className="modal-form w-[90%]">
                  <input
                    className="w-full"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="company-about flex flex-col items-center justify-between">
                <h1>About</h1>
                <div className="modal-form w-[90%]">
                  <textarea
                    className="w-full"
                    rows={6}
                    type="text"
                    value={about}
                    onChange={(e) => {
                      e.target.value.length < 551 && setAbout(e.target.value);
                    }}
                  />
                  <div className="max-length-email-content">
                    <span>{chars - about?.length} characters left</span>
                  </div>
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div>
              <Button
                color="green"
                onClick={(e) => editProfile()}
                variant="gradient"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default index;
