import React, { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

import PasswordGeneratedModal from "./PasswordGeneratedModal";
import { GeneratePassword } from "config/apis/GenerateNewPasswordAPI";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { convertArrayToMap } from "helpers/utils";

const GeneratePasswordForm = ({ adminData, setAdminData }) => {
  const [open, setOpen] = React.useState(false);
  const [adminDataMap, setAdminDataMap] = useState({});
  const [newPassword, setNewPassword] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (adminData?.admins?.length > 0) {
      setAdminDataMap(convertArrayToMap(adminData.admins, "id"));
    }
  }, [adminData]);

  const generateNewPasswordAPI = async (payload) => {
    try {
      const res = await GeneratePassword(payload);

      if (res.status === 200) {
        setNewPassword(res?.data?.newPassword);
        setOpen(true);
        formik.resetForm();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("GENERATE NEW PASSWORD", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedAdminId: "",
      email: "",
      type: "",
    },
    validationSchema: Yup.object({
      selectedAdminId: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      let payload = {
        email: values.email,
        type: values.type,
      };
      generateNewPasswordAPI(payload);
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    const selectedAdmin = adminDataMap[e.target.value];
    formik.setFieldValue("email", selectedAdmin.email);
    formik.setFieldValue("type", selectedAdmin.type);
  };

  return (
    <div className="gap-5 grid grid-cols-1 md:w-1/2 w-full shadow-ContentCards bg-white rounded-2xl p-5">
      <div className="pb-3 border-b-[1px]">
        <h1 className="font-medium text-xl">Generate Password for Admins</h1>
      </div>
      <div>
        <Select
          name="selectedAdminId"
          id="selectedAdminId"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          value={formik.values.selectedAdminId}
          className="bg-white w-full rounded-lg border-[#0080FF] border-[1px]"
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span className="text-[#8e8e8e]">Select admin</span>;
            }
            const selectedAdmin = adminDataMap[selected];
            return `${selectedAdmin.name} - ${selectedAdmin.type} admin`;
          }}
        >
          {adminData?.admins?.length > 0 &&
            adminData?.admins?.map((items, index) => {
              return (
                <MenuItem key={index} value={items.id}>
                  {items.name} - {items.type} admin
                </MenuItem>
              );
            })}
        </Select>
        <p className="text-red-600 text-sm">
          {formik.touched.selectedAdminId && formik.errors.selectedAdminId
            ? formik.errors.selectedAdminId
            : null}
        </p>
      </div>
      <div>
        <p className="text-[#5B5B5B] text-sm font-normal">Email ID</p>
        <input
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={true}
          value={formik.values.email}
          className=" h-[3.125rem] w-full bg-[#EFEFEF] rounded-lg pl-2"
        />
        <p className="text-red-600 text-sm">
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : null}
        </p>
      </div>
      <div>
        <button
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
          className={
            "text-lg font-bold text-white bg-[#039BE5] w-[10.8rem] h-10 rounded-lg " +
            (!(formik.isValid && formik.dirty) &&
              "pointer-events-none opacity-[0.6]")
          }
        >
          Generate
        </button>
      </div>
      <div>
        {open && (
          <PasswordGeneratedModal
            open={open}
            setOpen={setOpen}
            newPassword={newPassword}
          />
        )}
      </div>
    </div>
  );
};

export default GeneratePasswordForm;
