//Open and close modal for TransferOwnershipFormModal

import React from "react";
//MUI components
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";
//API
import { TransferOwnership } from "config/apis/SuperAdminProfile";
// Utils
import logError from "helpers/utils/logErrors";
const TransferOwnershipFormModal = ({
  newAdmin,
  setNewAdmin,
  open,
  setOpen,
  setSuccess,
  success,
}) => {
  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const TransferOwnershipAPI = async (body) => {
    try {
      const res = await TransferOwnership(body);
      console.log(res);
      if (res?.status === 204) {
        let data = res?.data;
        setSuccess(true);
        console.log(data);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("TRANSFER OWNERSHIP", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      email: Yup.string()
        .email("please enter a valid email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      setNewAdmin(values);

      TransferOwnershipAPI(values);
    },
  });
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal6">
          <div className="divide-y">
            <div className="pb-3">
              <h1 className="font-medium text-2xl">Add Super Admin</h1>
            </div>
            <div className="grid grid-cols-1 gap-4 pt-7">
              <div>
                <input
                  id="name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10 pl-1 font-normal text-sm text-[#B0B0B0]"
                  placeholder="Enter Name"
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : null}
                </p>
              </div>
              <div>
                <input
                  id="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10 pl-1 font-normal text-sm text-[#B0B0B0]"
                  placeholder="Enter Email ID"
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null}
                </p>
              </div>
              <div>
                <input
                  id="phone"
                  name="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  className="bg-[#EFEFEF] w-[19rem] rounded h-10 pl-1 font-normal text-sm text-[#B0B0B0]"
                  placeholder="Enter Contact Number"
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : null}
                </p>
              </div>
              <div className="box-modal6-lastRow">
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="box-modal6-lastRow-no"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    setOpen(false);
                  }}
                  className="box-modal6-lastRow-yes"
                >
                  Save admin
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TransferOwnershipFormModal;
