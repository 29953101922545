import React, { useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { TbFileSpreadsheet } from "react-icons/tb";
import EventNotification from "./EventNotification";
import EventMember from "./EventMember";
import EventSlots from "./EventSlots";
import EventRequests from "./EventRequests";
import { useLocation } from "react-router-dom";
import EventMembersTable from "./EventMembersTable";
import { GetEventById } from "config/apis/Events";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";

const PowerHour = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, eventName } = location.state || undefined;

  const [events, setevent] = useState([]);
  const [eventRegistrations, seteventRegistrations] = useState([]);
  const [eventNotification, seteventNotification] = useState([]);
  const [spotlightRequests, setspotlightRequests] = useState([]);
  const [downloadData, setDownloadData] = useState([]);

  const _GetEventById = async () => {
    try {
      let res;
      res = await GetEventById(id);
      if (res?.data?.success) {
        let data = res?.data;
        setevent(data?.InvitedEvent);
        seteventRegistrations(data?.InvitedEvent?.eventRegistrations);
        seteventNotification(data?.InvitedEvent?.eventNotifications);

        const updatedRequests = data?.InvitedEvent?.spotlightRequests?.map(
          (request) => ({
            ...request,
            eventDate: data?.InvitedEvent?.eventDate,
            eventName: data?.InvitedEvent?.eventName,
          })
        );

        setspotlightRequests(updatedRequests);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
        console.log("error");
      }
    } catch (e) {
      // console.log("error");
      logError("Delete Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  useEffect(() => {
    _GetEventById();
  }, []);

  const downloadCSV = (event, done) => {
    // setLoading(false);
    const eventRegistrationData = eventRegistrations?.map((user) => [
      events?.eventName,
      user?.first_name + " " + user?.last_name,
      user?.Company?.company_name,
      user?.job_title,
      user?.email,
      user?.mobileNo,
      "Event Member",
    ]);

    const spotlightRequestData = spotlightRequests?.map((request) => [
      events?.eventName,
      request?.first_name + " " + request?.last_name,
      request?.company_name,
      request?.job_title,
      request?.email,
      "", // Empty string for 'Mobile Number' as it's not available in spotlightRequests
      request?.status, // Adding 'status' from spotlightRequests
      // request?.status === "" ? "Spotlight" : ""
      "Spotlight",
    ]);

    // Merge event registrations data and spotlight requests data
    const data = [...eventRegistrationData, ...spotlightRequestData];

    // Add header row
    data.unshift([
      "Event Name",
      "Name",
      "Company Name",
      "Title",
      "Email",
      "Mobile Number",
      "Status",
      "type",
    ]);

    setDownloadData(data);

    // setLoading(true);
    done();
  };

  return (
    <>
      <div className="container-fluid px-4 md:px-10 py-2 md:py-5">
        <div className="row">
          <IoArrowBackCircle
            className="cursor-pointer"
            size={"3rem"}
            onClick={() => history.back()}
          />
        </div>
        <div className="row">
          <h2
            className="flex items-center gap-2"
            style={{ fontSize: "2rem", fontWeight: "bold" }}
          >
            {eventName}
            <CSVLink
              data={downloadData}
              asyncOnClick={true}
              onClick={downloadCSV}
            >
              <TbFileSpreadsheet />
            </CSVLink>
          </h2>
        </div>
        <div className="row">
          <EventNotification
            notification={eventNotification}
            onUpdate={_GetEventById}
          />
        </div>
        <div className="row py-2">
          <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
            Event Member
          </h2>
        </div>
        <div className="row">
          <EventMembersTable
            data={eventRegistrations}
            onUpdate={_GetEventById}
          />
        </div>
        <div className="row py-2">
          <EventSlots
            eventDate={events?.eventDate}
            eventId={events?.id}
            data={spotlightRequests}
            onUpdate={_GetEventById}
          />
        </div>
        <div className="row">
          <EventRequests data={spotlightRequests} onUpdate={_GetEventById} />
        </div>
      </div>
    </>
  );
};

export default PowerHour;
