import React, { useEffect, useState } from "react";

import { Dialog, DialogBody, Button } from "@material-tailwind/react";

import { BsPeople } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
// import ChipInput from "material-ui-chip-input";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ENDORSE_BASE_URL } from "../../../../config/APIEndpoints";
import { validateEmail } from "../../../../helpers/utils/checkRegex";
import { requestEndorseAPI } from "config/apis/Company";
import { MuiChipsInput } from "mui-chips-input";
const RequestEndorse = ({ myCompany }) => {
  const [showModal, setShowModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [modifiedTxt, setModifiedTxt] = useState(false);
  const [newText, setNewText] = useState("");
  const [content, setContent] = useState("");

  const handleAddChip = (email) => {
    if (validateEmail(email)) setEmails([...emails, email]);
  };

  const handleDeleteChip = (chipToDelete) => {
    setEmails(emails.filter((email) => email !== chipToDelete));
  };
  const [getLink, setGetLink] = useState(false);

  const text = `Hi there, 


    We'd like to request an endorsement for ${myCompany?.company_name}, based on your experience partnering with us! The review will be featured on the B2B business growth platform-BackBone.\n\nTo complete the rating, you'll need to simply register your company details with BackBone. They do this only to ensure legitimate businesses provide ratings. We appreciate your patience in this matter!\n
As a gesture of goodwill, Backbone will offer you a $100 advertising credit note that can be used should you sign up for a trial.
\nThanks in advance for your cooperation!\n
${myCompany?.company_name}
`;

  useEffect(() => {
    setContent(text);
  }, [myCompany]);

  const handleEndorseRequest = async () => {
    let data = new URLSearchParams();
    for (let i = 0; i < emails.length; i++) {
      if (validateEmail(emails[i])) data.append(`emailList[${i}]`, emails[i]);
      else {
        return;
      }
    }
    data.append(`url`, `/company/${id}`);

    if (modifiedTxt) {
      data.append("newText", newText);
      data.append("hasModified", modifiedTxt);
    } else {
      data.append("text", content);
    }
    setGetLink(false);
    if (emails.length > 0) {
      await requestEndorseAPI(data);
      setShowModal(false);
      setContent(text);
      setEmails([]);
    }
  };

  const onChange = (e) => {
    setNewText(e.target.value);
    setContent(e.target.value);
    setModifiedTxt(true);
  };

  return (
    <>
      <div className="request-other-to-endorse">
        <button onClick={() => setShowModal(true)}>
          Request customer endorsements
        </button>
      </div>

      {/* Modal */}

      <div className="endorse-modal modal-wrapper">
        <Dialog
          size="lg"
          open={showModal}
          handler={() => {
            setShowModal(false);
          }}
          className=" overflow-y-scroll max-h-[75vh]"
        >
          <DialogBody>
            <div className="endorse-body">
              <div className="top-heading">
                <div className="icon-wrapper">
                  <BsPeople />
                </div>
                <h1>Request an endorsement</h1>
              </div>
              <p className="get-free-ad-credit">
                Earn $100 advertising credit to use on BackBone for every
                successful endorsement.
              </p>

              <div className="input-section">
                <div className="mt-4 mb-3">
                  <MuiChipsInput
                    placeholder="Add email id here"
                    style={{ margin: "0" }}
                    required
                    value={emails}
                    blurBehavior="add"
                    onAdd={(chip) => handleAddChip(chip)}
                    fullWidth
                    onDelete={(chip) => handleDeleteChip(chip)}
                    label=""
                    variant="outlined"
                  />
                </div>
                <div className="mail-content">
                  <textarea
                    name="emailcontent"
                    id="emailcontent"
                    cols="30"
                    rows="8"
                    value={content}
                    readOnly
                  />
                </div>
                <div className="max-length-email-content">
                  <span>You can write up to 250 words</span>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div className="save-button">
                  <Button
                    color="green"
                    onClick={() => {
                      handleEndorseRequest();
                    }}
                    variant="gradient"
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
            <hr />
            <div className="endorse-body mt-3">
              <div className="top-heading">
                <div className="icon-wrapper">
                  <AiOutlineLink />
                </div>
                <h1>Get link</h1>
              </div>
              <div className="bottom-share flex gap-2 justify-content-between">
                <p className="share-this-link">
                  Anyone on the internet with this link can view
                </p>
                <CopyToClipboard
                  text={`${ENDORSE_BASE_URL}/company/${myCompany?.id}`}
                >
                  <button onClick={() => setGetLink(true)}>
                    {!getLink ? <h1>Copy Link</h1> : <h1>Link Copied</h1>}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </>
  );
};

export default RequestEndorse;
