import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiFillFilter } from "react-icons/ai";
import "assets/styles/scss/_styles/_MUIDropDown.scss";
import { useState } from "react";
import { numToMonthStrMap } from "helpers/defaultValues/dashboard";
const SelectOptions = ({ filter, setOption, setYear }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState();
  const [optionsArr, setOptionsArr] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemSelect = (e) => {
    const value = e.target.innerText;
    setValue(value);
    if (filter === "annual") {
      setOption(null);
      setYear(value);
    } else {
      setOption(value.split(" ")[0].toLowerCase());
      const selectedYear = parseInt(value.split(" ")[2]);
      setYear(selectedYear);
    }
    handleClose();
  };

  let yearsArr = [];
  const monthsArr = [];
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  for (let i = 0; i < 6 && currentYear - i >= 2022; i++)
    yearsArr.push(currentYear - i);
  for (let i = currentMonth + 1; i <= 12; i++) monthsArr.push(i);
  for (let i = 1; i <= currentMonth; i++) monthsArr.push(i);

  useEffect(() => {
    setValue(
      filter === "annual"
        ? `${currentYear}`
        : `${numToMonthStrMap[currentMonth]} - ${currentYear - 2000}`
    );
    setOption(
      filter === "annual"
        ? `${currentYear}`
        : `${numToMonthStrMap[currentMonth].toLowerCase()}`
    );
    setOptionsArr(filter === "annual" ? yearsArr : monthsArr);
  }, [filter]);

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="flex items-center space-x-2 "
      >
        <AiFillFilter />
        <p>{value}</p>
      </button>
      <Menu
        className="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {optionsArr.map((option) => (
          <MenuItem className="menu-item" onClick={handleMenuItemSelect}>
            {filter === "annual"
              ? `${option}`
              : `${numToMonthStrMap[option]} - ${
                  option > currentMonth ? currentYear - 1 : currentYear
                }`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SelectOptions;
