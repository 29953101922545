//commonly styled primary blue button
import React from "react";
//react hooks for navigation
import { Link, useHistory } from "react-router-dom";

const Button = ({ text, path, onClick }) => {
  return (
    <>
      <button
        onClick={() => {
          onClick ? onClick() : null;
        }}
        className="bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white p-3 px-8 rounded-lg font-normal text-sm"
      >
        {path ? <Link to={path}>{text}</Link> : text}
      </button>
    </>
  );
};

export default Button;
