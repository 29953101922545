import { getHeader, getMultipartHeader, instance } from "config/apis";

/**
 * Admin Sign In
 * @param { email, password } data
 * @returns {  data:user, message }
 */

// to fetch a company's data
export const companyDataAPI = (id) => {
  return instance.get(`/v1/superAdmin/getCompanyById/${id}`, getHeader());
};

// to fetch a bizbucks data
export const bizBucksDataByIdAPI = (id) => {
  return instance.get(`/v1/superAdmin/getBizBucksById/${id}`, getHeader());
};

// to fetch total backed count from all companies
export const getTotalBacked = (id) => {
  return instance.get(`/backaBiz/getTotalBackedAdminAPI?id=${id}`, getHeader());
};

// to update the bizbucks amounts
export const updateBizBucksAmount = (id, body) => {
  return instance.post(
    `/v1/superAdmin/updateBizBucks/${id}`,
    body,
    getHeader()
  );
};

//to  update a company's data
export const updateCompanyDataAPI = (id, body) => {
  return instance.put(
    `/v1/superAdmin/updateCompany/${id}`,
    body,
    getMultipartHeader()
  );
};

//to  update a company's user's info data
export const updateCompanyInfoAPI = (id, body) => {
  return instance.put(
    `/v1/superAdmin/updateCompanyInfo/${id}`,
    body,
    getHeader()
  );
};

// to get industries
export const getIndustriesAPI = () => {
  return instance.get(`/v1/superAdmin/getAllIndustries`, getHeader());
};

// to update a company's industry type
export const updateIndustryAPI = (company_id, id) => {
  return instance.put(
    `/v1/superAdmin/updateIndustry/${company_id}/${id}`,
    {},
    getHeader()
  );
};

// to add demo banner
export const addDemoBannerAPI = (id, bannerLink) => {
  return instance.post(
    `/v1/superAdmin/addDemoBanner/${id}`,
    bannerLink,
    getMultipartHeader()
  );
};

// to update demo banner
export const updateDemoBannerAPI = (id, bannerLink) => {
  return instance.put(
    `/v1/superAdmin/updateDemoBanner/${id}`,
    bannerLink,
    getMultipartHeader()
  );
};

// to add banner
export const addBannerAPI = (id, banner) => {
  return instance.post(
    `/v1/superAdmin/addBanner/${id}`,
    banner,
    getMultipartHeader()
  );
};

// to update banner
export const updateBannerAPI = (id, banner) => {
  return instance.put(
    `/v1/superAdmin/updateBanner/${id}`,
    banner,
    getMultipartHeader()
  );
};

// to update tags
export const addTagsAPI = (id, services) => {
  return instance.post(`/v1/superAdmin/add/services/${id}`, services, {
    headers: {
      ...getHeader().headers,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// to delete tags
export const deleteTagsAPI = (id) => {
  return instance.delete(`/v1/superAdmin/deleteServices/${id}`, getHeader());
};

// to get tags
export const getTagsAPI = () => {
  return instance.get(`/v1/superAdmin/get/services`, getHeader());
};

// to update Preference
export const addPreferenceAPI = (id, services) => {
  // console.log("addPreferenceAPI === id ===>>  ", id)
  // console.log("addPreferenceAPI === services ===>>  ", JSON.stringify(services))
  return instance.post(`/mypreference/add?company_id=${id}`, services, {
    headers: {
      ...getHeader().headers,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// to delete Preference
export const deletePreferenceAPI = (id) => {
  return instance.delete(`/mypreference/delete/${id}`, getHeader());
};

// to get Preference
export const getPreferenceAPI = () => {
  return instance.get(`/mypreference/get`, getHeader());
};

// to add socials
export const addSocialAPI = (company_id, id, social) => {
  console.log(company_id, id);
  return instance.post(
    `/v1/superAdmin/social/addSocial/${company_id}/${id}`,
    social,
    getHeader()
  );
};

// to update socials
export const updateSocialAPI = (updatedSocial) => {
  return instance.put(
    `/v1/superAdmin/social/updateSocial/${updatedSocial.id}`,
    {
      socialName: updatedSocial.socialName,
      socialLink: updatedSocial.socialLink,
    },
    getHeader()
  );
};

// to delete socials
export const deleteSocialAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/social/deleteSocial/${id}`,
    getHeader()
  );
};

// to add leaders
export const addLeaderAPI = (company_id, leader) => {
  return instance.post(
    `/v1/superAdmin/leader/addLeader/${company_id}`,
    leader,
    getMultipartHeader()
  );
};

// to delete leaders
export const deleteLeaderAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/leader/deleteLeader/${id}`,
    getHeader()
  );
};

// to request endorse
export const requestEndorseAPI = (data) => {
  return instance.post(`/v1/superAdmin/endorse/requestEndorsement`, data, {
    headers: {
      ...getHeader().headers,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// to add title in other info section
export const addTitleAPI = (company_id, data) => {
  return instance.post(
    `/v1/superAdmin/otherinfo/addTitle/${company_id}`,
    data,
    getHeader()
  );
};

// to update title in other info section
export const updateTitleAPI = (company_id, data) => {
  return instance.put(
    `/v1/superAdmin/otherinfo/updateTitle/${company_id}`,
    data,
    getHeader()
  );
};

// to delete title from other info section
export const deleteTitleAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/otherinfo/deleteTitle/${id}`,
    getHeader()
  );
};

// to add image in other info section
export const addImageAPI = (user_id, company_id, data) => {
  return instance.post(
    `/v1/superAdmin/otherinfo/addImg/${user_id}/${company_id}`,
    data,
    getMultipartHeader()
  );
};

// to delete image from other info section
export const deleteImageAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/otherinfo/deleteImage/${id}`,
    getHeader()
  );
};

// to add pdf in other info section
export const addPdfAPI = (user_id, company_id, data) => {
  return instance.post(
    `/v1/superAdmin/otherinfo/addPdf/${user_id}/${company_id}`,
    data,
    getMultipartHeader()
  );
};

// to delete pdf from other info section
export const deletePdfAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/otherinfo/deletePdf/${id}`,
    getHeader()
  );
};

// to add link in other info section
export const addLinkAPI = (user_id, company_id, data) => {
  return instance.post(
    `/v1/superAdmin/otherinfo/addLink/${user_id}/${company_id}`,
    data,
    {
      headers: {
        ...getHeader().headers,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

// to delete link from other info section
export const deleteLinkAPI = (id) => {
  return instance.delete(
    `/v1/superAdmin/otherinfo/deleteLink/${id}`,
    getHeader()
  );
};
