import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BsFillBellFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { VscThreeBars } from "react-icons/vsc";
import BackboneLogo from "assets/logos/BackboneLogo.svg";
import { Link, useHistory, useRouteMatch, useLocation } from "react-router-dom";

// Utils
import classNames from "helpers/utils/classNames";

// Constants
import { navigation, userNavigation } from "helpers/constants/Navigation";
import { privateRoutes } from "routes/PrivateRoute";
import { useSelector } from "react-redux";
import { companyDataAPI } from "config/apis/Company";

export default function Example() {
  const history = useHistory();
  const location = useLocation();
  const selectedRoute = useRouteMatch();
  const user = useSelector((state) => state?.user);
  const [companyName, setCompanyName] = useState("Company Name");

  const signOut = () => {
    localStorage.removeItem("backboneAdmin");
    history.push("/");
  };

  const fetchCompanyName = async (id) => {
    let res = await companyDataAPI(id);
    setCompanyName(res?.data?.company?.company_name);
  };

  useEffect(() => {
    if (location.pathname.includes("company")) {
      let id = location.pathname.split("/")[2];
      fetchCompanyName(id);
    }
  }, []);

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto w-full px-4 sm:px-6 lg:px-8 ">
            <div className="flex h-16 justify-between">
              <div className="flex gap-4 items-center">
                <div
                  onClick={() => history.push("/dashboard")}
                  className="cursor-pointer flex flex-shrink-0 items-center lg:hidden"
                >
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={BackboneLogo}
                    alt="Your Company"
                  />
                </div>
                {location.pathname.includes("company") && (
                  <div className="hidden md:flex gap-2">
                    <div
                      className="hover:underline cursor-pointer"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Subscription Admin &gt;&gt;
                    </div>
                    <div
                      className="hover:underline cursor-pointer"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Edit Profile &gt;&gt;
                    </div>
                    <div
                      className="hover:underline cursor-pointer"
                      onClick={() => {
                        history.push(location.pathname);
                      }}
                    >
                      {companyName} &gt;&gt;
                    </div>
                  </div>
                )}
              </div>

              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-blue-dark">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <AiOutlineClose
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <VscThreeBars
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-3">
              {navigation.map((item) => {
                return (
                  <Disclosure.Button
                    as="a"
                    key={item?.name}
                    href={item.path}
                    className={classNames(
                      privateRoutes.find((e) => e.path == selectedRoute.path)
                        ?.menuTab == item.tab
                        ? "bg-primary-blue-lightest block border-l-4 text-primary-blue-dark py-2 pl-3 pr-4 text-base font-medium border-primary-blue-dark"
                        : "text-neutral-gray-500  block",
                      " py-2 pl-3 pr-4 text-base font-medium  transform transition ease-in-out duration-150 hover:scale-105"
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
              })}
              {userNavigation.map((item) => {
                return (
                  <>
                    {item?.path ? (
                      item?.name === "Settings" && user?.isSuperAdmin ? (
                        <Disclosure.Button
                          as="a"
                          key={item?.name}
                          href={item.path}
                          className={classNames(
                            privateRoutes.find(
                              (e) => e.path == selectedRoute.path
                            )?.menuTab == item.tab
                              ? "bg-primary-blue-lightest block border-l-4 text-primary-blue-dark py-2 pl-3 pr-4 text-base font-medium border-primary-blue-dark"
                              : "text-neutral-gray-500  block",
                            " py-2 pl-3 pr-4 text-base font-medium  transform transition ease-in-out duration-150 hover:scale-105"
                          )}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ) : null
                    ) : (
                      <div
                        onClick={() => {
                          signOut();
                        }}
                        className={classNames(
                          privateRoutes.find(
                            (e) => e.path == selectedRoute.path
                          )?.menuTab == item.tab
                            ? "bg-primary-blue-lightest block border-l-4 text-primary-blue-dark py-2 pl-3 pr-4 text-base font-medium border-primary-blue-dark"
                            : "text-neutral-gray-500  block",
                          " py-2 pl-3 pr-4 text-base font-medium  transform transition ease-in-out duration-150 hover:scale-105"
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </>
                );
              })}
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
