//Open and close modal for adding new events or editing events

import React, { useState, useEffect } from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddNewEvent, getAllCompanies, UpdateEvent } from "config/apis/Events";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import logError from "helpers/utils/logErrors";
import { FaCommentsDollar } from "react-icons/fa";
import AutocompleteCompanySearchComponent from "./AutocompleteCompanySearchComponent";

const AddEventModal = ({
  open,
  setOpen,
  offlineModal,
  setOfflineModal,
  setPayload,
  edit,
  editEventData,
  setEdit,
  onUpdate,
  setEditEventData,
}) => {
  const dispatch = useDispatch();
  //Functions to handle opening and closing of modal

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    formik.resetForm();
    setCreatedType("");
  };
  //State to count number of character in text area
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState("");
  const [createdType, setCreatedType] = useState("");

  // const [companyError, setCompanyError] = useState("");
  // const [companyData, setCompanyData] = useState({});

  const handleCompanyError = (errorMessage) => {
    setCompanyError(errorMessage);
  };

  const [initialValuesObject, setInitialValuesObject] = useState({
    eventName: edit ? editEventData.eventName : "",
    eventType: edit ? editEventData.eventType : "",
    registrationLink: edit ? editEventData.registrationLink : "",
    eventDate: edit ? editEventData.eventDate : "",
    eventDescription: edit ? editEventData.eventDescription : "",
    eventMode: edit ? editEventData.eventMode : "",
    createdType: edit
      ? editEventData?.Company && editEventData?.company_id
        ? "member-event"
        : "backbone-event"
      : "",
    companyName: edit ? editEventData.company_name : "",
    companyId: edit ? editEventData.company_id : "",
    userId: edit ? editEventData.user_id : "",
    company: edit
      ? {
          companyId: editEventData.company_id,
          userId: editEventData.user_id,
          companyName: editEventData.company_name,
        }
      : null,
  });

  const addEvent = async (eventData) => {
    try {
      const res = await AddNewEvent(eventData);

      if (res.status === 201) {
        let data = res?.data;
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const updateEventAPI = async (body, id) => {
    try {
      const res = await UpdateEvent(body, id);

      if (res.status === 200) {
        setEdit(false);
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Update Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  useEffect(() => {
    edit ? setDate(new Date(editEventData.eventDate)) : setDate(new Date());
    edit
      ? setTextAreaCount(editEventData.eventDescription?.length)
      : setTextAreaCount(0);
  }, [edit]);

  /* useEffect(() => {
    if(companyData?.companyId?.length > 0) {
      setCompanyError("")
    }
  }, [companyError]); */

  useEffect(() => {
    if (!open && !offlineModal) {
      formik.resetForm();
      setCreatedType("");
      setEditEventData({});
    } else {
      if (edit) {
        if (editEventData?.Company && editEventData?.company_id) {
          setCreatedType("member-event");
        } else {
          setCreatedType("backbone-event");
        }
      } else {
        setEditEventData({});
      }
    }
  }, [open]);

  useEffect(() => {
    if (edit) {
      setInitialValuesObject({
        eventName: edit ? editEventData.eventName : "",
        eventType: edit ? editEventData.eventType : "",
        registrationLink: edit ? editEventData.registrationLink : "",
        eventDate: edit ? editEventData.eventDate : "",
        eventDescription: edit ? editEventData.eventDescription : "",
        eventMode: edit ? editEventData.eventMode : true,
        createdType: edit
          ? editEventData?.Company && editEventData?.company_id
            ? "member-event"
            : "backbone-event"
          : "",
        companyName: edit ? editEventData.company_name : "",
        companyId: edit ? editEventData.company_id : "",
        userId: edit ? editEventData.user_id : "",
        company: edit
          ? {
              companyId: editEventData.company_id,
              userId: editEventData.user_id,
              companyName: editEventData.company_name,
            }
          : null,
      });
    } else {
      setEditEventData({});
    }
  }, [edit]);

  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const formik = useFormik({
    initialValues: initialValuesObject,
    validationSchema: Yup.object({
      eventName: edit ? "" : Yup.string().required("Required"),
      eventType: edit ? "" : Yup.string().required("Required"),
      registrationLink:
        mode == "false" || mode == false
          ? ""
          : edit
          ? ""
          : createdType === "member-event"
          ? Yup.string().required("Required").matches(re, "URL is not valid")
          : "",
      // eventDate: edit ? "" : Yup.string().required("Required"),
      eventDescription: edit ? "" : Yup.string().required("Required"),
      eventMode: edit
        ? ""
        : createdType === "member-event"
        ? Yup.boolean().required("Required")
        : "",
      company: edit
        ? ""
        : createdType === "member-event"
        ? Yup.object().nullable().required("Required")
        : "",
    }),
    onSubmit: (values) => {
      if (createdType?.length === 0) {
        alert("Please select the checkbox!");
        return;
      }

      setOpen(false);
      let unchangedMode = true;

      if (values.eventMode === "") {
        unchangedMode = editEventData.eventMode;
      }
      if (
        values.eventMode === false ||
        values.eventMode === "false" ||
        unchangedMode === "false" ||
        unchangedMode === false
      ) {
        createdType === "member-event" && setOfflineModal(true);
        setPayload({
          eventName: edit
            ? values.eventName == ""
              ? editEventData.eventName
              : values.eventName
            : values.eventName,
          eventType: edit
            ? values.eventType == ""
              ? editEventData.eventType
              : values.eventType
            : values.eventType,
          registrationLink: edit
            ? values.registrationLink == ""
              ? editEventData.registrationLink
              : values.registrationLink
            : values.registrationLink,
          eventDate: edit
            ? date == ""
              ? editEventData.eventDate
              : date
            : date,
          eventDescription: edit
            ? values.eventDescription == ""
              ? editEventData.eventDescription
              : values.eventDescription
            : values.eventDescription,
          eventMode: edit
            ? values.eventMode == ""
              ? editEventData.eventMode
              : values.eventMode
            : values.eventMode,
        });

        if (createdType === "backbone-event") {
          let eventID = editEventData?.id;
          let eventData = edit
            ? {
                eventName: edit
                  ? values.eventName == ""
                    ? editEventData.eventName
                    : values.eventName
                  : values.eventName,
                eventType: edit
                  ? values.eventType == ""
                    ? editEventData.eventType
                    : values.eventType
                  : values.eventType,
                registrationLink: edit
                  ? values.registrationLink == ""
                    ? editEventData.registrationLink
                    : values.registrationLink
                  : values.registrationLink,
                eventDate: edit
                  ? date == ""
                    ? editEventData.eventDate
                    : date
                  : date,
                eventDescription: edit
                  ? values.eventDescription == ""
                    ? editEventData.eventDescription
                    : values.eventDescription
                  : values.eventDescription,
                eventMode: edit
                  ? values.eventMode == ""
                    ? editEventData.eventMode
                    : values.eventMode
                  : values.eventMode,
              }
            : {
                eventName: values.eventName,
                eventType: values.eventType,
                registrationLink: values.registrationLink,
                eventDate: date,
                eventDescription: values.eventDescription,
                eventMode: values.eventMode,
                streetName: "",
                subUrb: "",
                pincode: "",
                city: "",
                createdType: createdType,
                companyId: values?.companyId,
                userId: values?.userId,
              };

          // console.log("createdType ==> ",edit, eventData, editEventData?.id)
          setCreatedType("");
          edit ? updateEventAPI(eventData, eventID) : addEvent(eventData);
        }
      } else {
        let eventID = editEventData?.id;
        let eventData = edit
          ? {
              eventName:
                values.eventName == ""
                  ? editEventData.eventName
                  : values.eventName,
              eventType:
                values.eventType == ""
                  ? editEventData.eventType
                  : values.eventType,
              registrationLink:
                values.registrationLink == ""
                  ? editEventData.registrationLink
                  : values.registrationLink,
              eventDate: date == "" ? editEventData.eventDate : date,
              eventDescription:
                values.eventDescription == ""
                  ? editEventData.eventDescription
                  : values.eventDescription,
              eventMode:
                values.eventMode == ""
                  ? editEventData.eventMode
                  : values.eventMode,
              streetName: "",
              subUrb: "",
              pincode: "",
              city: "",
              createdType: createdType,
              companyId:
                values.companyId == ""
                  ? editEventData.company_id
                  : values.companyId,
              userId:
                values.userId == "" ? editEventData.user_id : values.userId,
            }
          : {
              eventName: values.eventName,
              eventType: values.eventType,
              registrationLink: values.registrationLink,
              eventDate: date,
              eventDescription: values.eventDescription,
              eventMode: values.eventMode,
              streetName: "",
              subUrb: "",
              pincode: "",
              city: "",
              createdType: createdType,
              companyId: values?.companyId,
              userId: values?.userId,
            };

        setCreatedType("");
        edit ? updateEventAPI(eventData, eventID) : addEvent(eventData);
      }
    },
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="box-modal-header">
                {edit ? "Edit Event" : "Add New Event"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup
                id="createdType"
                name="createdType"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                onChange={(e) => {
                  setCreatedType(e.target.value);
                }}
                defaultValue={
                  edit
                    ? editEventData?.Company && editEventData?.company_id
                      ? "member-event"
                      : "backbone-event"
                    : ""
                }
                
              >
                <FormControlLabel
                  value="backbone-event"
                  className="box-modal-radiolabel"
                  control={
                    <Radio
                      disabled={edit && createdType === "member-event" ? true : false}
                      sx={{
                        color: "#5A5A5A!important",
                        "&.Mui-checked": {
                          color: "#5A5A5A",
                        },
                      }}
                    />
                  }
                  label="BackBone Event"
                />
                <FormControlLabel
                  value="member-event"
                  className="box-modal-radiolabel"
                  control={
                    <Radio
                      disabled={edit && createdType === "backbone-event" ? true : false}
                      sx={{
                        color: "#5A5A5A!important",
                        "&.Mui-checked": {
                          color: "#5A5A5A",
                        },
                      }}
                    />
                  }
                  label="Member Event"
                />
              </RadioGroup>
            </Grid>
            {createdType === "member-event" && (
              <Grid item xs={12}>
                <Typography className="box-modal-label">
                  Select company
                </Typography>
                <AutocompleteCompanySearchComponent
                  formik={formik}
                  id="company"
                  fieldName="company"
                  onSelectCompany={(company) =>
                    formik.setFieldValue("company", company)
                  }
                  // onSelectCompany={formik.handleChange}
                  editEventData={editEventData}
                />
                {/* <input
                  id="eventName"
                  name="eventName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  defaultValue={edit ? editEventData.eventName : ""}
                  className="box-modal-field"
                /> */}

                {/* {companyError ? (
                  <p className="text-red-600 text-sm">
                    {companyError}
                  </p>
                ) : null} */}
                {formik.touched.company && formik.errors.company ? (
                  <p className="text-red-600 text-sm">
                    {formik.errors.company}
                  </p>
                ) : null}
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography className="box-modal-label">Event name</Typography>
              <input
                id="eventName"
                name="eventName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                defaultValue={edit ? editEventData.eventName : ""}
                className="box-modal-field"
              />

              {formik.touched.eventName && formik.errors.eventName ? (
                <p className="text-red-600 text-sm">
                  {formik.errors.eventName}
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal-label">Event type</Typography>
              <input
                id="eventType"
                name="eventType"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                defaultValue={edit ? editEventData.eventType : ""}
                className="box-modal-field"
              />
              {formik.touched.eventType && formik.errors.eventType ? (
                <p className="text-red-600 text-sm">
                  {formik.errors.eventType}
                </p>
              ) : null}
            </Grid>
            {createdType === "member-event" && (
              <Grid item xs={12}>
                <Typography className="box-modal-label">Event mode</Typography>
                <RadioGroup
                  id="eventMode"
                  name="eventMode"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMode(e.target.value);
                  }}
                  defaultValue={edit ? editEventData.eventMode : ""}
                >
                  <FormControlLabel
                    value={true}
                    className="box-modal-radiolabel"
                    control={
                      <Radio
                        sx={{
                          color: "#5A5A5A!important",
                          "&.Mui-checked": {
                            color: "#5A5A5A",
                          },
                        }}
                      />
                    }
                    label="online"
                  />
                  <FormControlLabel
                    value={false}
                    className="box-modal-radiolabel"
                    control={
                      <Radio
                        sx={{
                          color: "#5A5A5A!important",
                          "&.Mui-checked": {
                            color: "#5A5A5A",
                          },
                        }}
                      />
                    }
                    label="offline"
                  />
                </RadioGroup>
                {formik.touched.eventMode && formik.errors.eventMode ? (
                  <p className="text-red-600 text-sm">
                    {formik.errors.eventMode}
                  </p>
                ) : null}
              </Grid>
            )}
            {createdType === "member-event" && (
              <Grid item xs={12}>
                <Typography className="box-modal-label">
                  Registration link
                </Typography>
                <input
                  id="registrationLink"
                  name="registrationLink"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  defaultValue={edit ? editEventData.registrationLink : ""}
                  className="box-modal-field"
                />
                {formik.touched.registrationLink &&
                formik.errors.registrationLink ? (
                  <p className="text-red-600 text-sm">
                    {formik.errors.registrationLink}
                  </p>
                ) : null}
              </Grid>
            )}
            <Grid item xs={12} md={5}>
              <Typography className="box-modal-label">Date</Typography>

              <DatePicker
                id="eventDate"
                name="eventDate"
                selected={date}
                onChange={(date1) => {
                  setDate(date1);
                  formik.setFieldValue("eventDate", date1);
                  // formik.handleChange(date1);
                }}
                onBlur={formik.handleBlur}
                defaultValue={edit ? editEventData.eventDate : new Date()}
                className="box-modal-field"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={edit ? new Date(editEventData.eventDate) : new Date()}
              />

              {formik.touched.eventDate && formik.errors.eventDate ? (
                <p className="text-red-600 text-sm">
                  {formik.errors.eventDate}
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal-label">
                About the event
              </Typography>
              <textarea
                id="eventDescription"
                name="eventDescription"
                onBlur={formik.handleBlur}
                defaultValue={edit ? editEventData.eventDescription : ""}
                maxLength="350"
                onChange={(e) => {
                  formik.handleChange(e);
                  setTextAreaCount(e.target.value.length);
                }}
                className="box-modal-textarea"
              />
              {formik.touched.eventDescription &&
              formik.errors.eventDescription ? (
                <p className="text-red-600 text-sm">
                  {formik.errors.eventDescription}
                </p>
              ) : null}
              <Typography className="box-modal-helper">
                {350 - textAreaCount} Characters left
              </Typography>
            </Grid>
            <Grid item xs={12} className="box-modal-lastrow">
              <button
                onClick={() => {
                  setOpen(false);
                  setEdit(false);
                  setCreatedType("");
                }}
                className="box-modal-lastrow-button1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  formik.handleSubmit();
                }}
                type="submit"
                className="box-modal-lastrow-button2 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                {edit ? "Update" : "Add"}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default AddEventModal;
