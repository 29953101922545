// DONE

//Add new blog card for add blog page

//React hooks
import React, { useState, useEffect } from "react";
//Icons
import { FilePicker } from "react-file-picker";
import { RiUploadCloud2Line } from "react-icons/ri";
import { HiOutlineTrash } from "react-icons/hi";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Blue chips component
import Chips from "./Chips";
import { useHistory, useParams } from "react-router-dom";
import RichTextEditor from "components/Common/RichTextEditor";
import {
  addBlogs,
  deleteABlog,
  getABlog,
  updateBlogs,
} from "config/apis/Blogs";
import logError from "helpers/utils/logErrors";
import Loader from "components/Common/Loader";

const CreateEditBlog = () => {
  const history = useHistory();
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    title: "",
    description: "",
    file: "",
    tags: "",
  });
  //Variable to store the state(boolean value) for image uploaded
  const [imageUploaded, setImageUploaded] = useState();

  const [rawContent, setRawContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getBlogById = async () => {
    try {
      setIsLoading(true);
      const res = await getABlog(id);
      if (res?.status == 200) {
        let data = res?.data?.blog;

        setDetails({
          title: data?.blog_title,
          description: data?.blog_description,
          tags: data?.tags,
          file: data?.blog_image,
        });
        setImageUploaded(data?.blog_image);
        setRawContent(data?.blog_description);
      }
    } catch (e) {
      logError("Get Blog", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id != 0) {
      getBlogById();
    }
  }, [id]);

  const onSubmit = async () => {
    const body = new FormData();

    body.append("blog_title", details?.title);
    body.append("blog_description", details?.description);
    body.append("tags", details?.tags);
    body.append("file", details?.file);

    switch (type) {
      case "create":
        {
          try {
            setIsLoading(true);
            const res = await addBlogs(body);
            if (res?.status == 201) {
              history.push("/blogs");
              dispatch(showToast({ message: res?.data?.message }));
            }
          } catch (e) {
            logError("Add Blog", e);
            dispatch(
              showToast({
                message: e?.response?.data?.error,
                type: "error",
              })
            );
          } finally {
            setIsLoading(true);
          }
        }
        break;
      default: {
        try {
          setIsLoading(true);
          const res = await updateBlogs(id, body);
          if (res?.status == 200) {
            history.push("/blogs");
            dispatch(showToast({ message: res?.data?.message }));
          }
        } catch (e) {
          logError("Edit Blog", e);
          dispatch(
            showToast({
              message: e?.response?.data?.error,
              type: "error",
            })
          );
        } finally {
          setIsLoading(true);
        }
      }
    }
  };

  const onFileSelect = (e) => {
    const reader = new FileReader();

    console.log(e);

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUploaded(reader.result);
      }
    };

    setDetails({ ...details, file: e });
    reader.readAsDataURL(e);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <div className="rounded-lg shadow-BlogCard bg-white h-full md:p-10 p-5">
          <div className="flex gap-2">
            <input
              name="title"
              id="title"
              onChange={(e) => {
                setDetails({ ...details, title: e?.target?.value });
              }}
              value={details?.title}
              placeholder="Type title here"
              className="w-full h-10 border-solid border-[1px] pl-2 rounded-lg border-[#818181]"
            />
            <button
              onClick={() => {
                setImageUploaded("");
                setDetails({ ...details, file: "" });
              }}
              className="bg-red-100 p-2 rounded-md cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
            >
              <HiOutlineTrash className="text-2xl text-alert" />
            </button>
          </div>
          {imageUploaded ? (
            <div className="rounded-lg mt-5 flex justify-center">
              <img
                src={imageUploaded}
                className="max-h-72 bg-contain rounded-lg"
              />
            </div>
          ) : (
            <div className="w-full flex-row items-center justify-center border-primary-blue-medium border-[1px] mt-5 md:pt-20 md:pb-20 pb-2 pt-2 rounded-lg">
              <div className="flex items-center justify-center">
                <FilePicker
                  extensions={["jpg", "jpeg", "png"]}
                  dims={{
                    minWidth: 0,
                    maxWidth: 1000,
                    minHeight: 0,
                    maxHeight: 5000,
                  }}
                  onChange={(file) => {
                    console.log(file);
                    onFileSelect(file);
                  }}
                  onError={(errMsg) => {
                    console.log("error", errMsg);
                    dispatch(
                      showToast({
                        message: errMsg,
                        type: "error",
                      })
                    );
                  }}
                >
                  <button className="text-base text-primary-blue-medium text-normal cursor-pointer transform transition ease-in-out duration-150 hover:scale-105">
                    <div className="flex items-center justify-center">
                      <RiUploadCloud2Line className="text-primary-blue-medium text-8xl" />
                    </div>
                    upload image
                  </button>
                </FilePicker>
              </div>
            </div>
          )}
          <div className="h-72 mt-5">
            <RichTextEditor
              id="blog"
              name="blog"
              setData={(e) => {
                setDetails({ ...details, description: e });
              }}
              rawContent={rawContent}
              toolbarClassName="toolbarClassName"
              wrapperClassName="bg-primary-blue-medium p-[1px] rounded"
              editorClassName="h-48 bg-white p-1"
            />
            <p className="text-red-600 text-sm"></p>
          </div>
          <div>
            <Chips
              tags={details?.tags}
              setTags={(val) => {
                setDetails({ ...details, tags: val });
              }}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <button
            type="submit"
            onClick={() => {
              onSubmit();
            }}
            className={`bg-primary-blue-medium text-white  tab:w-1/5 md:2/5 w-2/5 h-10 rounded-lg font-bold md:text-xl text-base ${
              !(details.title && details.description)
                ? "pointer-events-none opacity-[0.6]"
                : ""
            }`}
          >
            Save changes
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateEditBlog;
