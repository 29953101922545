// DONE
// TODO: Copy the error handling format from EditFAQ for all API calls

//Event card for event list page

import { useEffect, useState } from "react";
//MUI components
//Icons
//Avatar image
//Blue chips for event card on the event page

// Redux
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// TODO: Make it dynamic accept props for each values being displayed and show that
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";

import { getTotalBacked, updateBizBucksAmount } from "config/apis/Company";
import "./bizbucksback.css";
import { showToast } from "redux/toaster";
import whiteBiz from "../../../../assets/illustrations/30.png";

const BizbucksBack = ({
  totalBacked,
  bizBucks,
  isMine,
  company,
  getCompanyData,
}) => {
  const [showModal, setShowModal] = useState(false);

  // let user = company?.User;
  let { id } = useParams();

  const dispatch = useDispatch();

  const [bizBuck, setBizBuck] = useState();

  const addBizbucks = async () => {
    if (bizBuck?.length > 0) {
      try {
        const res = await updateBizBucksAmount(id, {
          bizBucks: parseInt(bizBuck),
        });
        if (res?.data?.success) {
          dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
          setShowModal(false);
          setBizBuck("");
          await getCompanyData();
        } else {
          console.log("error");
          dispatch(showToast({ message: res.data?.message, type: "error" }));
        }
      } catch (e) {
        console.error("Error updating badge tiers:", e);
        dispatch(showToast({ message: e?.message, type: "error" }));
      }
    } else {
      alert("Enter some amount");
    }
  };

  const removeBizbucks = async () => {
    if (bizBuck?.length > 0) {
      try {
        const res = await updateBizBucksAmount(id, {
          bizBucks: -parseInt(bizBuck),
        });
        if (res?.data?.success) {
          dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
          setShowModal(false);
          setBizBuck("");
          await getCompanyData();
        } else {
          console.log("error");
          dispatch(showToast({ message: res.data?.message, type: "error" }));
        }
      } catch (e) {
        console.error("Error updating badge tiers:", e);
        dispatch(showToast({ message: e?.message, type: "error" }));
      }
    } else {
      alert("Enter some amount");
    }
  };

  const date = new Date();
  const date1 = new Date(date.getYear(), date.getMonth(), date.getDate());
  const shortName = date1.toLocaleString("en-US", { month: "short" });

  return (
    <>
      <div className="bizbucks-container">
        <div className="bizbucks-content">
          <div className="bizbucks-badge text-black">
            <span>Backed by</span>
            <span className="bizbucks-count">{`${totalBacked}`}</span>
            <span>Businesses</span>
          </div>
          <div className="bizbucks-amount">
            <span
              className="amount text-[#FFD744]"
              style={{ fontFamily: "Rye, serif" }}
            >
              {bizBucks?.balance || 0}
            </span>
            <img src={whiteBiz} alt="" />
          </div>
        </div>
      </div>

      <div className="bizbucks-button-container">
        <button onClick={() => setShowModal(true)} className="bizbucks-button">
          Add/Remove Bucks
        </button>
      </div>

      <div className="profile-modal modal-wrapper">
        <Dialog
          size="sm"
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1>Number of BizBucks to add to selected members:</h1>
          </DialogHeader>
          <DialogBody>
            <div className="mt-4 mb-3">
              <input
                maxLength={5}
                type="number"
                id="bizBuck"
                className="w-[100px]"
                placeholder=""
                value={bizBuck}
                onChange={(e) => {
                  setBizBuck(e.target.value);
                }}
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="close-button mr-4">
              <Button
                color="green"
                onClick={(e) => removeBizbucks()}
                variant="gradient"
              >
                Remove
              </Button>
            </div>
            <div>
              <Button
                color="green"
                onClick={(e) => addBizbucks()}
                variant="gradient"
              >
                Add
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default BizbucksBack;
