import { instance, getHeader } from "config/apis";

// TODO: Update proper code documentation

//To get FAQs
export const FAQs = () => {
  return instance.get(`/faq/getFAQ`, getHeader());
};

//Delete FAQS
export const deleteFAQs = (id) => {
  return instance.delete(`/faq/FAQ/${id}`, getHeader());
};

//Add FAQS
export const AddNewFAQs = (body) => {
  return instance.post(`/faq/addFAQ`, body, getHeader());
};

//Update FAQS

export const UpdateFAQs = (body, id) => {
  return instance.patch(`/faq/FAQ/${id}`, body, getHeader());
};
