import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdOutlineClose } from "react-icons/md";
import { HiOutlineArrowLongDown } from "react-icons/hi2";
import moment from "moment";

const SlotInfoModal = ({ data, open2, setOpen2, handleClose2 }) => {
  return (
    <div>
      <Modal open={open2} onClose={handleClose2} sx={{ overflow: "scroll" }}>
        <Box className="box-modal6 relative ">
          <button
            className="absolute top-4 right-4"
            onClick={() => {
              setOpen2(false);
            }}
          >
            <MdOutlineClose size={"1.5rem"} />
          </button>
          <div className="modal-content my-3">
            <div className="slot-info-modal-wrapper">
              <p className="mb-3 text-neutral-gray-500 ">
                Event Name:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.eventName}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Event Time:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${moment(data?.eventDate)?.format("hh:mm A")}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Name:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.first_name} ${data?.last_name}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Company Name:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.company_name}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Email Address:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.email}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Job Title:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.job_title}`}
                </span>
              </p>
              <p className="mb-3 text-neutral-gray-500">
                Description:{" "}
                <span className="font-medium text-neutral-gray-800">
                  {`${data?.description}`}
                </span>
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SlotInfoModal;
