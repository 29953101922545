import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import _ from "lodash";
import "./AutocompleteCompanySearchComponent.css";
import { getAllCompanies } from "config/apis/Events";

const AutoCompleteCompanySearchCompanyDetails = ({
  onSelectCompany,
  onChangeSearch,
}) => {
  const [search, setSearch] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showCompaniesList, setShowCompaniesList] = useState(false);
  const [isEditEventDataHandled, setIsEditEventDataHandled] = useState(false);

  const debounceFetchCompanies = useCallback(
    _.debounce(async (searchText) => {
      if (searchText.length > 0) {
        try {
          const response = await getAllCompanies(searchText);
          if (response?.data?.success) {
            const companies = response?.data?.company?.filter(
              (company) => company.User !== null && company.User !== undefined
            );
            setCompanyList(companies);
          } else {
            setCompanyList([]);
            setShowCompaniesList(false);
          }
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      } else {
        setCompanyList([]);
        setShowCompaniesList(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    debounceFetchCompanies(search);
  }, [search, debounceFetchCompanies]);

  const handleSearchChange = (e) => {
    const value = e.target.value;

    // Check if the input is empty
    if (value?.trim() === "") {
      setSearch(""); // Clear the search state
      setShowCompaniesList(false); // Hide the companies list
      onChangeSearch(false); // Trigger the onChangeSearch callback with false
    } else {
      setSearch(value);
      setShowCompaniesList(true);
      onChangeSearch(true); // Trigger the onChangeSearch callback with true
    }
  };

  const handleCompanyClick = (company) => {
    setSearch(company.company_name);
    setShowCompaniesList(false);
    setFilteredCompanies([]);
    // if (onError) onError(""); // Clear the error when a valid company is selected

    if (onSelectCompany) {
      onSelectCompany({ company });
    }
  };

  useEffect(() => {
    setFilteredCompanies(
      companyList.filter((company) =>
        company.company_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [companyList, search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".auto-complete")) {
        setShowCompaniesList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="auto-complete">
      <input
        type="text"
        value={search}
        onChange={handleSearchChange}
        // onBlur={handleBlur}
        placeholder="Search company..."
        className="box-modal-field"
        // onFocus={() => !editEventData?.company_id && setShowCompaniesList(true)}
      />
      {showCompaniesList && filteredCompanies.length > 0 && (
        <ul className="auto-complete-list">
          {filteredCompanies.map((company) => (
            <li
              key={company.id}
              onClick={() => handleCompanyClick(company)}
              className="auto-complete-item"
            >
              {company.company_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteCompanySearchCompanyDetails;
