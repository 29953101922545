import React, { useEffect, useState } from "react";
import { MdInfo } from "react-icons/md";
import Confirmation from "./Confirmation";
import { GetAllSpotlight, UpdateSpotlightStatus } from "config/apis/Requests";
import { GetEventById } from "config/apis/Events";
import moment from "moment";
import SlotInfoModal from "./SlotInfoModal";

import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import ConfirmSpotlightModalEvents from "./ConfirmSpotlightModalEvents";
import WaitlistSpotlightModalEvent from "./WaitlistSpotlightModalEvent";

const EventRequests = ({ data, onUpdate }) => {
  const [spotlightRequests, setSpotlightRequests] = useState([]);

  const [modalType, setModalType] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [RequestData, setRequestData] = useState();
  const [isSlotAvailable, setIsSlotAvailable] = useState(undefined);

  const dispatch = useDispatch();

  const openModal = (type, request) => {
    setModalType(type);
    setSelectedRequest(request);
  };

  const closeModal2 = () => {
    setModalType(null);
    setSelectedRequest(null);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setRequestData();
  };

  /*  useEffect(() => {
    if(open) {
      // getSpotlight()
    }
  }, [open]) */

  useEffect(() => {
    const filteredData = data?.filter((item) => item?.status === "approved")
    filteredData?.length >= 2 ? setIsSlotAvailable(false) : setIsSlotAvailable(true)
    setSpotlightRequests(data);
  }, [data]);

  const updateSpotlightStatus = async (status, request_id) => {
    // console.table([status, request_id])
    try {
      let res = await UpdateSpotlightStatus(
        {
          status: status, // pending,approved,rejected,waitlist
        },
        request_id
      );

      if (res?.data?.success) {
        dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
        onUpdate();
      } else {
        console.log("error", res.data?.message);
        dispatch(showToast({ message: res.data?.message, type: "error" }));
      }
    } catch (e) {
      console.log("error");
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  const renderSpotlightRequests = (status) => {
    return spotlightRequests
      .filter((request) => request.status === status)
      .map((request) => (
        /* <RequestSpotlightItem key={request.request_id} request={request} openModal={openModal} /> */
        <div className="request-list flex mb-4 bg-neutral-gray-100 p-3 rounded-md max-w-2xl justify-between">
          <div>
            <div className="flex items-center">
              <h2 style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                {`${request?.first_name} ${request?.last_name}`}
              </h2>
              <span className="ms-5">{`${request?.company_name}`}</span>
              <button
                onClick={async () => {
                  await setRequestData(request);
                  await setOpen2(true);
                }}
                className="rounded-full bg-primary-blue-medium p-1  text-white ms-2"
              >
                <MdInfo size={"1.2rem"} />
              </button>
            </div>
            <p className="py-1">{`${request?.eventName} ~ ${moment(
              request?.eventDate
            )?.format("hh:mm A")}`}</p>
          </div>
          <div className="flex flex-col gap-2">
            {request.status === "pending" && (
              <>
                <button
                  onClick={() => {
                    isSlotAvailable && openModal("confirm", request);
                  }}
                  className={isSlotAvailable ? "rounded-full bg-primary-blue-dark px-4 p-1 text-white" :  "rounded-full disabled-slot-confirm-button px-4 p-1 text-white"}
                >
                  Confirm
                </button>
                <button
                  onClick={() => {
                    openModal("waitlist", request);
                  }}
                  className="rounded-full bg-neutral-gray-500 px-4 p-1 text-white"
                >
                  Waitlist
                </button>
              </>
            )}
            {request.status === "waitlist" && (
              <button
                onClick={() => {
                  isSlotAvailable && openModal("confirm", request);
                }}
                className={isSlotAvailable ? "rounded-full bg-primary-blue-dark px-4 p-1 text-white" :  "rounded-full disabled-slot-confirm-button px-4 p-1 text-white"}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      ));
  };

  return (
    <>
      <div className="event-request-wrapper mt-8">
        <h2 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
          Requests
        </h2>
        {/* loop */}

        {/* {spotlightRequests.} */}
        <div>{renderSpotlightRequests("pending")}</div>
      </div>

      {/* waitlist component */}
      <div className="event-request-wrapper mt-8">
        <h2 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
          Waitlist
        </h2>
        <div>{renderSpotlightRequests("waitlist")}</div>
      </div>

      {/* <Confirmation open={open} setOpen={setOpen} handleClose={handleClose} /> */}

      {modalType === "confirm" && (
        <ConfirmSpotlightModalEvents
          request={selectedRequest}
          closeModal={closeModal2}
          updateStatus={updateSpotlightStatus}
        />
      )}
      {modalType === "waitlist" && (
        <WaitlistSpotlightModalEvent
          request={selectedRequest}
          closeModal={closeModal2}
          updateStatus={updateSpotlightStatus}
        />
      )}

      <SlotInfoModal
        data={RequestData}
        open2={open2}
        setOpen2={setOpen2}
        handleClose2={handleClose2}
      />
    </>
  );
};

export default EventRequests;
