import { instance } from "config/apis";

/**
 * Admin Sign In
 * @param { email, password } data
 * @returns { token, data:user, message }
 */
const adminSignIn = (data) => {
  return instance.post("/v1/admin/auth/login", data);
};

const adminForgotPassword = (data) => {
  return instance.post("/v1/admin/auth/forgot-password", data);
};

const adminResetPassword = (data, token) => {
  return instance.post(`/v1/admin/auth//reset-password/${token}`, data);
};

export { adminSignIn, adminForgotPassword, adminResetPassword };
