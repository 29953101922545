import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdOutlineClose } from "react-icons/md";
import { HiOutlineArrowLongDown } from "react-icons/hi2";
import { MdInfo } from "react-icons/md";
import AutocompleteCompanySearchComponent from "./AutocompleteCompanySearchComponent";
import * as Yup from "yup";
import { useFormik } from "formik";
import AutoCompleteCompanySearchCompanyDetails from "./AutoCompleteCompanySearchCompanyDetails";
import { bookSpotMemberByAdmin } from "config/apis/Events";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { red } from "@mui/material/colors";

const EventFillModal = ({ eventId, open, setOpen, handleClose, onUpdate }) => {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [description, setDescription] = useState("");
  const [IsSearched, setIsSearched] = useState(false);

  const setValuse = (company) => {
    setUserId(company?.company?.User?.id);
    setCompanyId(company?.company?.id);
    setFirstName(company?.company?.User?.firstName);
    setLastName(company?.company?.User?.lastName);
    setEmail(company?.company?.User?.email);
    setCompanyName(company?.company?.company_name);
  };
  const clearValues = () => {
    setUserId("");
    setCompanyId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompanyName("");
    setCompanyName("");
  };

  useEffect(() => {
    if (!IsSearched) {
      clearValues();
    }
  }, [IsSearched]);
  
  useEffect(() => {
    if (!open) {
      clearValues();
    }
  }, [open]);

  const onSubmit = async () => {
    let payload = {
      user_id: userId,
      company_id: companyId,
      event_id: eventId,
      first_name: firstName ? firstName : " ",
      last_name: lastName ? lastName : " ",
      email: email,
      company_name: comapnyName,
      job_title: jobTitle,
      description: description,
    };

    // if(first_name && first_name.length === 0) {
    //   alert
    // }

    try {
      const res = await bookSpotMemberByAdmin(payload);

      if (res?.data?.success) {
        let data = res?.data;
        onUpdate();
        handleClose();
        clearValues();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        // clearValues();
        if(res?.data?.message && res?.data?.message.length > 0) {
          dispatch(showToast({ message: res?.data?.message, type: "error" }));  
        } else {
          dispatch(showToast({ message: res?.data?.error, type: "error" }));
        }
        
      }
    } catch (e) {
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal7 relative ">
          <button
            className="absolute top-4 right-4"
            onClick={() => {
              setOpen(false);
            }}
          >
            <MdOutlineClose size={"1.5rem"} />
          </button>
          <div className="modal-content my-3">
            <div className="modal-heading text-neutral-gray-dark text-lg mb-3 font-medium">
              Requests
            </div>
            <div className="slot-request-wrapper">
              {/* loop */}
              <div className="request flex items-center justify-between rounded-md mb-2">
                {/* <h2>Name - Company Name </h2> */}
                <AutoCompleteCompanySearchCompanyDetails
                  id="company"
                  onSelectCompany={(company) => setValuse(company)}
                  onChangeSearch={(isSearched) => {
                    // console.log("onChangeSearch  :", isSearched);
                    setIsSearched(isSearched);
                  }}
                />
                {/* <div className="flex items-center ">
                  <button className="rounded-full bg-primary-blue-medium p-1  text-white me-2">
                    <MdInfo size={"1.5rem"} />
                  </button>
                  
                </div> */}
              </div>
              {/* <div className="request flex items-center justify-between bg-neutral-gray-100 p-1 px-3 rounded-md mb-2">
                <h2>Name - Company Name </h2>
                <div className="flex items-center ">
                  <button className="rounded-full bg-primary-blue-medium p-1  text-white me-2">
                    <MdInfo size={"1.5rem"} />
                  </button>
                  <button className="rounded-full bg-primary-blue-dark px-4 p-1 text-white">
                    Fill
                  </button>
                </div>
              </div> */}

              <div>
                {/* {firstName && ( */}
                  <input
                    type="text"
                    name="FirstName"
                    id="userFName"
                    placeholder="First Name"
                    className="box-modal-field my-1"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    // disabled
                  />
                  {/* <>
                    <p style={{ color: red }}>{dadasda}</p>
                  </> */}
                {/* )} */}
                <div />
                {/* {lastName && ( */}
                  <input
                    type="text"
                    name="LastName"
                    id="userLName"
                    placeholder="Last Name"
                    className="my-1 box-modal-field"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    // disabled
                  />
                {/* )} */}
                <div />
                {/* {email && ( */}
                  <input
                    type="text"
                    name="Email"
                    id="userEmail"
                    placeholder="Email"
                    className="my-1 box-modal-field"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    // disabled
                  />
                {/* )} */}
              </div>

              <div>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Job Title"
                  className="my-1 box-modal-field"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                />
                <div />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Description"
                  className="my-1 box-modal-field w-100%"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              <button
                onClick={() => onSubmit()}
                className="rounded-full bg-primary-blue-dark px-4 p-1 text-white"
              >
                Submit
              </button>
            </div>

            {/* <div className="horizontalLine">
              <div className="inline-flex items-center justify-center w-full">
                <hr className="w-full h-px my-5 bg-gray-600 border-0 rounded dark:bg-gray-900" />
                <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                  <p>or</p>
                </div>
              </div>
            </div>
            <div className="modal-heading text-neutral-gray-dark text-lg mb-3 font-medium">
              Manual Entry
            </div>
            <div className="flex justify-between items-center">
              <div>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Name"
                  className="my-1 rounded-md"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Company Name"
                  className="my-1 rounded-md"
                />
              </div>
              <div className="px-3">
                <button className="rounded-full bg-primary-blue-dark px-4 p-1 text-white">
                  Fill
                </button>
              </div>
            </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EventFillModal;
