import { instance, getHeader } from "config/apis";

//To get all Events
export const GetAllEvents = (page) => {
  return instance.get(`/events/getEvents?page=${page}`, getHeader());
};

//To get specific Events by id
export const GetEventById = (id) => {
  return instance.get(`/events/get-invited-event/${id}`, getHeader());
};

//To remove the spotlight member.
export const removeSpotlightMember = (id) => {
  return instance.delete(
    `/spotlight/remove-spotlight-member/${id}`,
    getHeader()
  );
};

//To remove the spotlight member.
export const bookSpotMemberByAdmin = (payload) => {
  return instance.post(`/spotlight/book-spot-by-admin`, payload, getHeader());
};

export const GetUpcomingEvents = (page) => {
  return instance.get(
    `/events/getEvents?page=${page}&upcoming=true`,
    getHeader()
  );
};

//to delete event
export const DelEvent = (id) => {
  {
    return instance.post(`/events/deleteEvent/${id}`, {}, getHeader());
  }
};

//to Add event
export const AddNewEvent = (payload) => {
  {
    return instance.post(`/events/addEvent`, payload, getHeader());
  }
};

//Update Event

export const UpdateEvent = (body, id) => {
  return instance.put(`/events/updateEvent/${id}`, body, getHeader());
};

// get all companies for the member event

export const getAllCompanies = (searchParams) => {
  return instance.get(
    `/company/get/allcompany?search=${searchParams}`,
    getHeader()
  );
};

//search Event

export const getSearchEventsAPI = (value, page = 0) => {
  return instance.get(
    `/events/search?search=${value}&page=${page}`,
    getHeader()
  );
};

///spotlight/book-spot-by-admin

export const bookSpotByAdmin = (payload) => {
  return instance.post(`/spotlight/book-spot-by-admin`, payload, getHeader());
};

/* event table in the event details section */
export const addMultipleBizBucks = (payload) => {
  return instance.post(`/events/admin/add-bizbucks`, payload, getHeader());
};

export const deleteAttendees = (id) => {
  return instance.delete(`/events/admin/delete-attendees/${id}`, getHeader());
};

export const editAttendees = (id, payload) => {
  return instance.put(
    `/events/admin/edit-attendees/${id}`,
    payload,
    getHeader()
  );
};
