// DONE

//Blog,Event,FAQ cards for dashboard
import BlogCard from "components/PrivatePages/Dashboard/Conetnt/Blog/BlogCard";
import EventCard from "components/PrivatePages/Dashboard/Conetnt/Event/EventCard";
import FaqCard from "components/PrivatePages/Dashboard/Conetnt/Faq/FaqCard";

/**
 * Content Admin Dashboard View
 * @returns { Component }
 */
function ContentAdmin({ faqs, events, blogs, onUpdate }) {
  return (
    <div>
      <div className="grid tab:grid-cols-2 grid-cols-1 gap-x-10 gap-y-6 mb-7">
        <FaqCard faqs={faqs} onUpdate={onUpdate} />
        <EventCard events={events} onUpdate={onUpdate} />
      </div>
      <BlogCard blogs={blogs} onUpdate={onUpdate} />
    </div>
  );
}

export default ContentAdmin;
