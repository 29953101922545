import { instance, getHeader } from "config/apis";

//To get all podcasts
export const GetAllPodcasts = () => {
  return instance.get(`/podcast/getPodcastList`, getHeader());
};

export const UpdatePodcastStatus = (body, id) => {
  return instance.put(`/podcast/updateRequest/${id}`, body, getHeader());
};

//To get all spotlight
export const GetAllSpotlight = () => {
  return instance.get(`/spotlight/getSpotlightList`, getHeader());
};

export const UpdateSpotlightStatus = (body, id) => {
  return instance.put(`/spotlight/updateRequest/${id}`, body, getHeader());
};

/* export const topTenBusinesssMostNumberOfBizBucksAPI = () => {
  return instance.post(`v1/admin/dashboard/top-business/activity`, {duration: 1, type: 'MONTH'}, getHeader());
}; */
