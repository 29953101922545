import React, { useState, useEffect } from "react";
import "./EventMembersTable.css";
import { Avatar } from "@mui/material";
import {
  addMultipleBizBucks,
  deleteAttendees,
  editAttendees,
} from "config/apis/Events";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { Link } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import DeleteConfirmation from "./DeleteConfirmation";
import EditRegistrationFormPopup from "./EditRegistrationFormPopup";

const EditIcon = () => (
  <span role="img" aria-label="edit">
    ✏️
  </span>
);
const DeleteIcon = () => (
  <span role="img" aria-label="delete">
    🗑️
  </span>
);
const Checkbox = ({ checked, onChange }) => (
  <input type="checkbox" checked={checked} onChange={onChange} />
);

const EventMembersTable = ({ data, onUpdate }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAnyMemberSelected, setIsAnyMemberSelected] = useState(false); // New state

  const [showModal, setShowModal] = useState(false);
  const [bizBuck, setBizBuck] = useState();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [selectedCompanyName, setSelectedCompanyName] = useState();
  const [selectedMemberDetails, setSelectedMemberDetails] = useState();
  const handleClose = () => {
    setOpen(false);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (company_name, member) => {
    setSelectedCompanyName(company_name);
    setSelectedMemberDetails(member);
    setSelectedMember(member?.registration_id);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleSave = async (formData) => {
    // console.table([selectedMemberDetails?.event_id, formData])
    try {
      const res = await editAttendees(
        selectedMemberDetails?.event_id,
        formData
      );

      if (res.data?.success) {
        onUpdate();
        setSelectedCompanyName();
        setSelectedMemberDetails();
        setSelectedMember();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        setSelectedCompanyName();
        setSelectedMemberDetails();
        setSelectedMember();
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      console.log("response eroro ==> : ", JSON.stringify(e));
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const _addMultipleBizBucks = async () => {
    let payload = [];

    filteredData.forEach((company) => {
      company.members.forEach((member) => {
        if (member.selected) {
          payload.push({
            company_id: member.company_id,
            amount: bizBuck,
            event_id: member.event_id,
            user_id: member.user_id,
          });
        }
      });
    });

    // Remove duplicates
    const uniquePayload = payload.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.company_id === value.company_id &&
            t.event_id === value.event_id &&
            t.user_id === value.user_id
        )
    );

    console.log("data to be data: ", JSON.stringify(uniquePayload));

    try {
      const res = await addMultipleBizBucks(uniquePayload);

      if (res.data?.success) {
        clearState();
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        clearState();
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      console.log("response eroro ==> : ", JSON.stringify(e));
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const _deleteAttendees = async (id) => {
    // console.log('delete attendees ==> ', id)
    try {
      const res = await deleteAttendees(id);

      if (res.data?.success) {
        onUpdate();
        setSelectedMember();
        setOpen(false);
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        setSelectedMember();
        setOpen(false);
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      console.log("response eroro ==> : ", JSON.stringify(e));
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const clearState = () => {
    setShowModal(false);
    setSelectAll(false);
    setBizBuck("");
    const resetData = filteredData.map((company) => ({
      ...company,
      members: company.members.map((member) => ({
        ...member,
        selected: false,
      })),
    }));
    setFilteredData(resetData);
    setIsAnyMemberSelected(false);
  };

  useEffect(() => {
    const groupedData = data.reduce((acc, item) => {
      const companyId = item.company_id;
      if (!acc[companyId]) {
        acc[companyId] = {
          company_name: item.Company.company_name,
          members: [],
        };
      }
      acc[companyId].members.push(item);
      return acc;
    }, {});
    const configBizBucks = localStorage.getItem("attend_an_event")
    setBizBuck(configBizBucks);
    setFilteredData(Object.values(groupedData));
  }, [data]);

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    const updatedData = filteredData.map((company) => ({
      ...company,
      members: company.members.map((member) => ({
        ...member,
        selected: e.target.checked,
      })),
    }));
    setFilteredData(updatedData);

    // Update isAnyMemberSelected state
    setIsAnyMemberSelected(e.target.checked);
  };

  const handleCheckboxChange = (companyIndex, memberIndex) => {
    const updatedData = [...filteredData];
    updatedData[companyIndex].members[memberIndex].selected =
      !updatedData[companyIndex].members[memberIndex].selected;
    setFilteredData(updatedData);

    // Update selectAll state if all are selected
    const allSelected = updatedData.every((company) =>
      company.members.every((member) => member.selected)
    );
    setSelectAll(allSelected);

    // Update isAnyMemberSelected state
    const anyMemberSelected = updatedData.some((company) =>
      company.members.some((member) => member.selected)
    );
    setIsAnyMemberSelected(anyMemberSelected);
  };

  return (
    <div className="event-members-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Company Name</th>
            <th>Title</th>
            <th>Email</th>
            <th>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </th>
            <th>
              <button
                onClick={() => setShowModal(true)}
                className={
                  isAnyMemberSelected ? "add-bizbucks" : "add-bizbucks-disabled"
                }
                disabled={!isAnyMemberSelected} // Disable button based on selection
              >
                Add BizBucks
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((company, index) => (
            <React.Fragment key={index}>
              {company.members.map((member, idx) => (
                <tr key={idx}>
                  <td>
                    {idx === 0 && (
                      <Avatar
                        src={
                          company.company_logo != null
                            ? company.company_logo
                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                        }
                      />
                    )}
                  </td>
                  <td>{`${member.first_name} ${member.last_name}`}</td>
                  <td>
                    <Link to={`company/${member?.Company?.id}`}>
                      {idx === 0 ? company.company_name : ""}
                    </Link>
                  </td>
                  <td>{member.job_title}</td>
                  <td>{member.email}</td>
                  <td>
                    {idx === 0 && (
                      <Checkbox
                        checked={member.selected || false}
                        onChange={() => handleCheckboxChange(index, idx)}
                      />
                    )}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    <button
                      onClick={() =>
                        handleOpenDialog(company?.company_name, member)
                      }
                      style={{ marginRight: "20px" }}
                    >
                      <EditIcon />
                    </button>

                    <button
                      onClick={() => {
                        // DeleteConfirmation
                        setSelectedMember(member?.registration_id);
                        setOpen(true);
                        // _deleteAttendees(member?.registration_id)
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
              {index < filteredData.length - 1 && (
                <tr className="divider">
                  <td colSpan="6"></td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className="profile-modal modal-wrapper">
        <Dialog
          size="sm"
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1>Number of BizBucks to add to selected members:</h1>
          </DialogHeader>
          <DialogBody>
            <div className="mt-4 mb-3">
              <input
                maxLength={5}
                type="number"
                id="bizBuck"
                className="w-[100px]"
                placeholder=""
                value={bizBuck}
                // onChange={(e) => {
                //   setBizBuck(e.target.value);
                // }}
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={() => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div>
              <Button
                color="green"
                onClick={() => _addMultipleBizBucks()}
                variant="gradient"
              >
                Add
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>

      <DeleteConfirmation
        message={"Are you sure you want to delete this registration?"}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        onConfirm={() => _deleteAttendees(selectedMember)}
      />
      <EditRegistrationFormPopup
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSave}
        registrationId={selectedMember}
        companyName={selectedCompanyName}
        memberDetails={selectedMemberDetails}
      />
    </div>
  );
};

export default EventMembersTable;
