// DONE

//indvidual blog to be mapped on blog card for content dashboard

import React from "react";
//icons and images
import blogIMG from "assets/ContentDashboradIcons/blogIMG.png";
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";

const Blog = ({ item, onDelete, onEdit }) => {
  return (
    <div className="gap-10">
      <div
        style={{
          backgroundImage: `url(${item?.blog_image})`,
        }}
        className="group bg-gray-200 h-28 rounded-lg bg-cover bg-center "
      >
        <div className="hidden group-hover:flex justify-end">
          <div className="p-2 space-x-1 pr-2 bg-white opacity-80 flex rounded-bl-lg rounded-tr-lg">
            <button
              onClick={onEdit}
              className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
            >
              <BiPencil className="text-2xl text-primary-blue-dark font-medium" />
            </button>
            <button
              onClick={onDelete}
              className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
            >
              <HiOutlineTrash className="text-2xl text-primary-blue-dark" />
            </button>
          </div>
        </div>
      </div>

      <p className="text-xs pt-3">{item?.blog_title}</p>
    </div>
  );
};

export default Blog;
