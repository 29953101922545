import AdminProfileCard from "components/PrivatePages/SuperAdminProfile/AdminProfileCard";
import React, { useState, useEffect } from "react";
import DeleteAdminModal from "./1/DeleteAdminModal";
import OwnershipTransferred from "./1/OwnershipTransferred";
import TransferOwnershipFormModal from "./1/TransferOwnershipFormModal";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { GetSuperAdminInfo } from "config/apis/SuperAdminProfile";
import GeneratePassword from "../generatePassword/GeneratePassword";
const SuperAdminProfile = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [oldAdmin, setOldAdmin] = useState(true);
  const [newAdmin, setNewAdmin] = useState("");
  const [superAdminData, setSuperAdminData] = useState("");
  const [oldAdmins, setOldAdmins] = useState("");
  const [fetching, setFetching] = useState(false);
  const [deleteOldAdmin, setDeleteOldAdmin] = useState(false);
  const dispatch = useDispatch();
  const GetSuperAdminInfoAPI = async () => {
    setFetching(true);
    try {
      const res = await GetSuperAdminInfo();

      if (res?.status === 200) {
        let data = res?.data;
        setSuperAdminData(data.superAdmin);
        setOldAdmins(data?.otherSuperAdmins);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("GET SUPER ADMIN INFO", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
    setFetching(false);
  };
  useEffect(() => {
    GetSuperAdminInfoAPI();
  }, []);

  return (
    <div className="md:pl-10 sm:pl-4 ">
      <div className="mb-10">
        <h1 className="text-4xl font-normal">Hi super admin!</h1>
      </div>
      <div>
        <AdminProfileCard
          superAdminData={superAdminData}
          open={open}
          setOpen={setOpen}
          deleteOldAdmin={deleteOldAdmin}
          setDeleteOldAdmin={setDeleteOldAdmin}
        />
      </div>
      {oldAdmins.length > 0 &&
        oldAdmins?.map((items, index) => {
          return (
            <div className="mt-12" key={index}>
              <AdminProfileCard
                superAdminData={items}
                open={open}
                setOpen={setOpen}
                adminType="old"
                deleteOldAdmin={deleteOldAdmin}
                setDeleteOldAdmin={setDeleteOldAdmin}
              />
            </div>
          );
        })}
      <div>
        <TransferOwnershipFormModal
          newAdmin={newAdmin}
          setNewAdmin={setNewAdmin}
          open={open}
          setOpen={setOpen}
          success={success}
          setSuccess={setSuccess}
        />
      </div>
      <div>
        <OwnershipTransferred
          success={success}
          setSuccess={setSuccess}
          oldAdmin={oldAdmin}
          setOldAdmin={setOldAdmin}
        />
      </div>
      <div>
        <DeleteAdminModal
          deleteOldAdmin={deleteOldAdmin}
          setDeleteOldAdmin={setDeleteOldAdmin}
          oldAdmin={oldAdmin}
          setOldAdmin={setOldAdmin}
        />
      </div>
      <div className="mt-12">
        <GeneratePassword />
      </div>
    </div>
  );
};

export default SuperAdminProfile;
