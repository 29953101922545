import React from "react";
import "./index.css";

const WaitlistModal = ({ request, closeModal, updateStatus }) => {
  return (
    <div className="modal">
      <div className="modal-content bg-[#fff] p-5 rounded-lg max-w-3xl">
        <div className="confirmation-title">
          Are you sure you want to waitlist?
        </div>
        <p>Upon confirmation, a message will be sent to them saying:</p>
        <p>
          "Apologies! We've had an overwhelming number of requests recently so
          will not be able to feature you just as yet. A BackBone Team member
          will be in touch when we have a few spots open up."
        </p>
        <button
          className="request-button waitlist text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          className="request-button bg-primary-blue-medium text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={() => {
            /* Handle waitlist action */
            closeModal();
            updateStatus("waitlist", request?.request_id);
          }}
        >
          Waitlist
        </button>
      </div>
    </div>
  );
};

export default WaitlistModal;
