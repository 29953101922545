import { useEffect, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import DeleteModal from "./DeleteModal";
import "./index.css";
import RequestItem from "./RequestItem";
import WaitlistModal from "./WaitlistModal";
import { GetAllPodcasts, UpdatePodcastStatus } from "config/apis/Requests";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import Loader from "components/Common/Loader";

const ServiceRequests = () => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [podcastRequests, setPodcastRequests] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setFetching(true);
    try {
      const [podcastRes] = await Promise.all([GetAllPodcasts()]);
      if (podcastRes?.data?.success) {
        setPodcastRequests(podcastRes?.data?.podcastRequests || []);
      } else {
        console.error("Error fetching data");
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setFetching(false);
    }
  };

  const openModal = (type, request) => {
    setModalType(type);
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setModalType(null);
    setSelectedRequest(null);
  };

  const updateStatus = async (status, request_id) => {
    try {
      const res = await UpdatePodcastStatus({ status }, request_id);
      if (res?.data?.success) {
        dispatch(showToast({ message: res.data?.message }));
        fetchData(); // Refetch data after status update
      } else {
        dispatch(showToast({ message: res.data?.message, type: "error" }));
      }
    } catch (e) {
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  const renderRequests = (status) => {
    return podcastRequests
      .filter((request) => request.status === status)
      .map((request) => (
        <RequestItem
          key={request.request_id}
          request={request}
          openModal={openModal}
        />
      ));
  };

  return (
    <>
      {fetching && <Loader />}
      <div className="grid grid-cols-1 gap-5 pl-5 pr-5">
        <div />
        <div className="mb-3">
          <h1 className="text-4xl text-medium">Search a Request</h1>
        </div>

        <div className="container">
          <div className="list-container">
            <div className="list">
              {renderRequests("pending").length > 0 && (
                <>
                  <div className="section-title">Podcast</div>
                  <div>{renderRequests("pending")}</div>
                </>
              )}

              {renderRequests("approved").length > 0 && (
                <>
                  <div className="section-title">Podcast Confirmed</div>
                  <div>{renderRequests("approved")}</div>
                </>
              )}

              {renderRequests("waitlist").length > 0 && (
                <>
                  <div className="section-title">Podcast Waitlist</div>
                  <div>{renderRequests("waitlist")}</div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Confirmation modals */}
        {modalType === "confirm" && (
          <ConfirmModal
            request={selectedRequest}
            closeModal={closeModal}
            updateStatus={updateStatus}
          />
        )}
        {modalType === "waitlist" && (
          <WaitlistModal
            request={selectedRequest}
            closeModal={closeModal}
            updateStatus={updateStatus}
          />
        )}
        {modalType === "delete" && (
          <DeleteModal
            request={selectedRequest}
            closeModal={closeModal}
            updateStatus={updateStatus}
          />
        )}
      </div>
    </>
  );
};

export default ServiceRequests;

// import { useEffect, useState } from "react";
// import ConfirmModal from "./ConfirmModal";
// import DeleteModal from "./DeleteModal";
// import "./index.css";
// import RequestItem from "./RequestItem";
// import WaitlistModal from "./WaitlistModal";
// import { GetAllPodcasts, GetAllSpotlight, UpdatePodcastStatus, UpdateSpotlightStatus } from "config/apis/Requests";
// import { useDispatch } from "react-redux";
// import { showToast } from "redux/toaster";
// import RequestSpotlightItem from "./RequestSpotlightItem";
// import ConfirmSpotlightModal from "./ConfirmSpotlightModal";
// import WaitlistSpotlightModal from "./WaitlistSpotlightModal";
// import Loader from "components/Common/Loader";

// const ServiceRequests = ({ props }) => {

//     const dispatch = useDispatch();

//       const [modalType, setModalType] = useState(null);
//       const [selectedRequest, setSelectedRequest] = useState(null);
//       const [podcastRequests, setPodcastRequests] = useState([]);
//       const [pendingPodcastRequests, setPendingPodcastRequests] = useState([]);
//       const [confirmPodcastRequests, setConfirmPodcastRequests] = useState([]);
//       const [waitlistPodcastRequests, setWaitlistPodcastRequests] = useState([]);

//       // const [spotlightRequests, setSpotlightRequests] = useState([]);
//       // const [pendingSpotlightRequests, setPendingSpotlightRequests] = useState([]);
//       // const [confirmSpotlightRequests, setConfirmSpotlightRequests] = useState([]);
//       // const [waitlistSpotlightRequests, setWaitlistSpotlightRequests] = useState([]);

//       const [fetching, setFetching] = useState(false);

//       const openModal = (type, request) => {
//         setModalType(type);
//         setSelectedRequest(request);
//       };

//       // const openModal2 = (type, request) => {
//       //   setModalType(type);
//       //   setSelectedRequest(request);
//       // };

//       const closeModal = () => {
//         setModalType(null);
//         setSelectedRequest(null);
//       };

//       // const closeModal2 = () => {
//       //   setModalType(null);
//       //   setSelectedRequest(null);
//       // };

//       useEffect(() => {
//         getPodcasts()
//         // getSpotlight()
//       }, [])

//       const getPodcasts = async () => {
//         setFetching(true)
//         try {
//           let res;
//             res = await GetAllPodcasts();

//           if (res?.data?.success) {
//             let data = res?.data;
//             setPodcastRequests(data?.podcastRequests);
//             let pendingReq = data?.podcastRequests.filter((request) => request.status === 'pending');
//             setPendingPodcastRequests(pendingReq)
//             let confirmReq = data?.podcastRequests.filter((request) => request.status === 'approved')
//             setConfirmPodcastRequests(confirmReq)
//             let waitlistReq = data?.podcastRequests.filter((request) => request.status === 'waitlist')
//             setWaitlistPodcastRequests(waitlistReq)
//           } else {
//             console.log("error");
//           }
//         } catch (e) {
//           console.log("error");
//         } finally {
//           setFetching(false)
//         }
//       };

//       // const getSpotlight = async () => {
//       //   try {
//       //     let res;
//       //       res = await GetAllSpotlight();

//       //     if (res?.data?.success) {
//       //       let data = res?.data;
//       //       // setTotalPages(Math.ceil(data?.totalEvents / 6));
//       //       setSpotlightRequests(data?.spotlightRequest);
//       //       let pendingReq = data?.spotlightRequest.filter((request) => request.status === 'pending');
//       //       setPendingSpotlightRequests(pendingReq)
//       //       let confirmReq = data?.spotlightRequest.filter((request) => request.status === 'approved')
//       //       setConfirmSpotlightRequests(confirmReq)
//       //       let waitlistReq = data?.spotlightRequest.filter((request) => request.status === 'waitlist')
//       //       setWaitlistSpotlightRequests(waitlistReq)
//       //     } else {
//       //       console.log("error");
//       //     }
//       //   } catch (e) {
//       //     console.log("error");
//       //   }
//       // };

//       const renderRequests = (status) => {
//         return podcastRequests
//           .filter((request) => request.status === status)
//           .map((request) => (
//             <RequestItem key={request.request_id} request={request} openModal={openModal} />
//           ));
//       };

//       // const renderSpotlightRequests = (status) => {
//       //   return spotlightRequests
//       //     .filter((request) => request.status === status)
//       //     .map((request) => (
//       //       <RequestSpotlightItem key={request.request_id} request={request} openModal={openModal2} />
//       //     ));
//       // };

//       const updateStatus = async (status, request_id) => {
//         // console.table([status, request_id])
//         try {
//             let res = await UpdatePodcastStatus({
//                 "status": status  // pending,approved,rejected,waitlist
//             }, request_id);

//             if (res?.data?.success) {
//                 dispatch(showToast({ message: res.data?.message }));  // Dispatch toast here
//                 getPodcasts()
//             } else {
//               console.log("error", res.data?.message);
//               dispatch(showToast({ message: res.data?.message, type: "error" }));
//             }
//           } catch (e) {
//             console.log("error");
//             dispatch(showToast({ message: e?.message, type: "error" }));
//           }
//       }

//       // const updateSpotlightStatus = async (status, request_id) => {
//       //   // console.table([status, request_id])
//       //   try {
//       //       let res = await UpdateSpotlightStatus({
//       //           "status": status  // pending,approved,rejected,waitlist
//       //       }, request_id);

//       //       if (res?.data?.success) {
//       //           dispatch(showToast({ message: res.data?.message }));  // Dispatch toast here
//       //           getSpotlight()
//       //       } else {
//       //         console.log("error", res.data?.message);
//       //         dispatch(showToast({ message: res.data?.message, type: "error" }));
//       //       }
//       //     } catch (e) {
//       //       console.log("error");
//       //       dispatch(showToast({ message: e?.message, type: "error" }));
//       //     }
//       // }

//   return (
//     <>
//     {fetching && <Loader />}
//     <div className="grid grid-cols-1 gap-5 pl-5 pr-5">
//          <div/>
//         <div className="mb-3">
//             <h1 className="text-4xl text-medium">Search a Request</h1>
//         </div>

//         {/* <h1>Remove Backings</h1>
//       <div>{''}</div> */}

//     <div className="container">
//           <div className="list-container">

//           <div className="list">
//           {pendingPodcastRequests?.length > 0 && <>
//             <div className="section-title">Podcast</div>
//             <div>{renderRequests('pending')}</div>
//             </>}

//             {confirmPodcastRequests?.length > 0 && <>
//             <div className="section-title">Podcast Confirmed</div>
//             <div>{renderRequests('approved')}</div>
//             </>}

//             {waitlistPodcastRequests?.length > 0 && <>
//             <div className="section-title">Podcast Waitlist</div>
//             <div>{renderRequests('waitlist')}</div>
//             </>}
//           </div>

//           {/* <div className="list">
//             {pendingSpotlightRequests?.length > 0 && <>
//               <div className="section-title">Spotlight</div>
//               <div>{renderSpotlightRequests('pending')}</div>
//             </>}

//             {confirmSpotlightRequests?.length > 0 && <>
//             <div className="section-title">Spotlight Confirmed</div>
//             <div>{renderSpotlightRequests('approved')}</div>
//             </>}

//             {waitlistSpotlightRequests?.length > 0 && <>
//             <div className="section-title">Spotlight Waitlist</div>
//             <div>{renderSpotlightRequests('waitlist')}</div>
//             </>}
//           </div> */}
//           </div>
//         </div>

//       {/* confirmation modals for podcasts */}
//       {modalType === 'confirm' && <ConfirmModal request={selectedRequest} closeModal={closeModal} updateStatus={updateStatus} />}
//       {modalType === 'waitlist' && <WaitlistModal request={selectedRequest} closeModal={closeModal} updateStatus={updateStatus} />}
//       {modalType === 'delete' && <DeleteModal request={selectedRequest} closeModal={closeModal} updateStatus={updateStatus} />}

//       {/* confirmation modals for spotlight */}
//     {/*   {modalType === 'confirm1' && <ConfirmSpotlightModal request={selectedRequest} closeModal={closeModal2} updateStatus={updateSpotlightStatus} />}
//       {modalType === 'waitlist1' && <WaitlistSpotlightModal request={selectedRequest} closeModal={closeModal2} updateStatus={updateSpotlightStatus} />} */}

//         <div className="spacer" />
//     </div>
//     </>
//   );
// };

// export default ServiceRequests;
