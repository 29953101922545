//Blue chips for blog card on blog list page
import React from "react";

const BlueChips = ({ chipData }) => {
  return (
    <div className="flex-wrap flex ">
      {chipData?.split(",")?.length > 0 &&
        chipData?.split(",")?.map((items, index) => {
          return (
            <div
              key={index}
              className="bg-primary-blue-medium mt-3 flex w-fit md:mr-3 mr-2 text-xs md:text-base font-medium items-center p-1 pl-3 pr-3 rounded-2xl text-white"
            >
              {items}
            </div>
          );
        })}
    </div>
  );
};

export default BlueChips;
