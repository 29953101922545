import { instance, getHeader } from "config/apis";

//to generate new password
export const GeneratePassword = (payload) => {
  console.log(payload);
  return instance.post(
    `v1/admin/auth/generate-new-password`,
    payload,
    getHeader()
  );
};
//to get admin and their types

export const GetAdminInfo = () => {
  return instance.get(`v1/admin/getByType`, {}, getHeader());
};
