/**
 * To display a list of all BizBucks
 * @param { Array } BizBucks - List of promo BizBucks
 * @returns { Component }
 */

import { BiPencil } from "react-icons/bi";
import GenerateBizBuckModal from "./GenerateBizBuckModal";
import React from "react";

const BizBucksItems = ({ BizBucks, onUpdate }) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState();

  const onRefresh = () => {
    onUpdate();
  };

  return (
    <>
      {BizBucks?.length > 0 &&
        BizBucks?.map((item, index) => {
          return (
            <div
              key={item?.id}
              className="grid grid-cols-4 items-center md:mt-3 mt-2 p-3  rounded-lg bg-neutral-gray-faqBG"
            >
              <div className="md:col-span-2 col-span-2 w-full">
                <p className="md:text-base text-xs">{item?.configLabel}</p>
              </div>

              <div className="flex md:col-span-1 col-span-1 md:w-[80px]   h-[32px] items-center  ml-5 justify-center bg-[#067eed26] rounded-[10px]">
                <p className="md:text-base text-xs">
                  {(item?.configType === "earn"
                    ? "+"
                    : item?.configType === "redeem"
                    ? "-"
                    : "") + item?.configValue?.toString() || ""}{" "}
                </p>
              </div>

              <div className="flex md:col-span-1 col-span-1 md:w-[70px]   h-[32px] items-center  ml-5 justify-center">
                <button
                  onClick={() => {
                    setItem(item);
                    setOpen(true);
                  }}
                  className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  <BiPencil className="md:text-2xl text-lg text-primary-blue-dark font-medium" />
                </button>
              </div>
            </div>
          );
        })}

      <div>
        <GenerateBizBuckModal
          item={item}
          setItem={setItem}
          open={open}
          setOpen={setOpen}
          onRefresh={onRefresh}
        />
      </div>
    </>
  );
};

export default BizBucksItems;
