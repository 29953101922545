//commonly styled navation text button
import React from "react";
//react hooks for navigation
import { Link, useHistory } from "react-router-dom";

const TertiaryButton = ({ path, onClick, text = "See all" }) => {
  return (
    <div className="align-center flex ">
      <button
        onClick={() => {
          onClick ? onClick() : null;
        }}
        className="md:text-sm text-xs underline font-normal cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-primary-blue-dark"
      >
        {path ? <Link to={path}>See all</Link> : text}
      </button>
    </div>
  );
};

export default TertiaryButton;
