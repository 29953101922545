//Open and close modal for adding generating new promo codes

import React from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
//stylesheet
import "assets/styles/scss/_styles/_UpdateWarning.scss";

const UpdateWarning = ({ warning, setWarning }) => {
  //Functions to handle opening and closing of modal
  const handleOpen = () => setWarning(true);
  const handleClose = () => {
    setWarning(false);
  };

  return (
    <div>
      <Modal
        open={warning}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal12">
          <Grid container>
            <Grid item xs={12} className="box-modal12-row1">
              <Typography className="font-medium text-xl  ">
                Cannot add multiple admins, please remove the previous ones to
                add new.
              </Typography>
            </Grid>

            <Grid item xs={12} className="box-modal12-row2">
              <button
                onClick={() => {
                  setWarning(false);
                }}
                className="box-modal12-row2-no"
              >
                Okay
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateWarning;
