import SignInForm from "components/AuthPages/SignIn";

/**
 * Page to
 * Sign in
 */

function SignIn() {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 bg-white">
      <div className="md:flex hidden">
        <img src="/assets/images/Login/LoginPage.png"></img>
      </div>

      <SignInForm />
    </div>
  );
}

export default SignIn;
