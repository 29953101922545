//Admin profile card
//Open,setOpen stores the state of modal which is displayed after transferring ownership
//DeleteOldAdmin,setDeleteOldAdmin stores the state of modal which opens on clicking delete admin to remove the old admin details card from being displayed
//AdminType stores the value, old or new based on what admin details are to be shown
//Returns a card with admin details.

import React from "react";

const AdminProfileCard = ({
  superAdminData,
  open,
  setOpen,
  adminType,
  deleteOldAdmin,
  setDeleteOldAdmin,
}) => {
  console.log(superAdminData);
  return (
    <div
      className={
        adminType == "old"
          ? "shadow-ContentCards bg-[#F2F2F2] rounded-2xl md:w-1/2 w-full p-5 divide-y"
          : "shadow-ContentCards bg-white rounded-2xl md:w-1/2 w-full p-5 divide-y"
      }
    >
      <div className="flex justify-between items-center pb-3">
        <div>
          <h1 className="font-medium text-xl">SUPER ADMIN</h1>
        </div>
        <div>
          <button
            onClick={() =>
              adminType == "old" ? setDeleteOldAdmin(true) : setOpen(true)
            }
            className={
              adminType == "old"
                ? "text-[#C11717] underline font-medium text-base"
                : "text-[#039BE5] underline font-medium text-base"
            }
          >
            {adminType == "old" ? "delete admin" : "Transfer Ownership"}
          </button>
        </div>
      </div>
      <div className="pt-8 gap-2 grid grid-cols-1 pb-2">
        <div>
          <h1 className="font-medium text-xl">{superAdminData?.name}</h1>
        </div>
        <div>
          <p className="font-medium text-xl text-[#A5A5A5]">
            Admin since Feb 2022
          </p>
        </div>
        <div>
          <p className="font-medium text-xl text-[#A5A5A5]">
            Email : {superAdminData?.email}
          </p>
        </div>
        <div>
          <p className="font-medium text-xl text-[#A5A5A5]">
            Contact number : {superAdminData?.phone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileCard;
