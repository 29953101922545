import React from "react";
import "./index.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GiSandsOfTime } from "react-icons/gi";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

const RequestItem = ({ request, openModal }) => {
  const itemClass = `request-item ${request.status}`;

  // console.log("request ==> ", JSON.stringify(request));
  return (
    <div className="card-container">
      <div className="card">
        <div className="card-header">
          <div className="request-details">
            {request?.User?.company_id && (
              <Link to={`company/${request?.User?.company_id}`}>
                <div className="request-item-title_spotlight">
                  {/* <span className="black-dot"></span> */}
                  <Avatar
                    src={
                      request?.Company?.company_logo != null
                        ? request?.Company?.company_logo
                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                    }
                  />
                  <strong className="underline">{request.company_name}</strong>
                </div>
              </Link>
            )}
          </div>

          <div className="right-pan">
            {request.status === "approved" && (
              <div className="confirmation">
                <IoMdCheckmarkCircleOutline className="icon" />
                <span>{`Confirmed on ${new Date(
                  request.updatedAt
                ).toLocaleDateString()}`}</span>
              </div>
            )}

            {request.status === "waitlist" && (
              <div className="confirmation">
                <GiSandsOfTime className="icon-waitlist" />
                <span>{`Waitlisted on ${new Date(
                  request.updatedAt
                ).toLocaleDateString()}`}</span>
              </div>
            )}

            <div className="request-actions">
              {request.status === "pending" && (
                <>
                  <button
                    className="request-button confirm"
                    onClick={() => openModal("confirm", request)}
                  >
                    Confirm
                  </button>
                  <button
                    className="request-button waitlist"
                    onClick={() => openModal("waitlist", request)}
                  >
                    Waitlist
                  </button>
                </>
              )}

              {request.status === "waitlist" && (
                <>
                  <button
                    className="request-button confirm"
                    onClick={() => openModal("confirm", request)}
                  >
                    Confirm
                  </button>
                  <button
                    className="request-button delete"
                    onClick={() => openModal("delete", request)}
                  >
                    Delete Request
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="user-name">{`${request.first_name} ${request.last_name}`}</div>
          <div className="user-email">{`${request.email}`}</div>
          <div className="job-title">{`${request.job_title}`}</div>

          <div className="event-title">{request.podcast_title}</div>
          <div className="event-description">{request?.description}</div>
        </div>
        <div className="card-footer">
          <div className="event-date">
            {"Requested: " + new Date(request.createdAt).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
