import React from "react";
import "./index.css";

const DeleteModal = ({ request, closeModal, updateStatus }) => {
  return (
    <div className="modal">
      <div className="modal-content bg-[#fff] p-5 rounded-lg max-w-3xl">
        <h2>Are you sure you want to delete this request?</h2>
        <p>
          This request will no longer be visible on the 'Requests' page. The
          company will not be notified that the request was deleted.
        </p>
        <button
          className="request-button waitlist text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          className="request-button bg-primary-blue-medium text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={() => {
            /* Handle delete action */
            closeModal();
            updateStatus("rejected", request?.request_id);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
