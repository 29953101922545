import { getHeader, instance } from "config/apis";

/**
 * Admin Sign In
 * @param { email, password } data
 * @returns {  data:user, message }
 */
const dashboardData = () => {
  return instance.get("/v1/admin/dashboard", getHeader());
};

export { dashboardData };

//to  add admin

export const AddNewAdmin = (body) => {
  return instance.post(`v1/admin/create`, body, getHeader());
};

export const getNewCustomerStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/newCustomer${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getSearchStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/search${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getSearchAnalytics = (query) => {
  return instance.get(
    `v1/admin/analytics/trends${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getCustomerAnalytics = (query) => {
  return instance.get(
    `v1/admin/analytics/newCustomers${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getReferringBusinesses = (query) => {
  return instance.get(
    `v1/admin/analytics/referringBusinesses${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getReferralStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/referral${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getRenewalsStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/renewal${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getSignUpStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/signUp${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getRenewalStats = (query) => {
  return instance.get(
    `v1/admin/dashboard/renewal${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getLoginStats = (query) => {
  return instance.get(
    `v1/admin/analytics/loginStats${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getBizBucksConfig = () => {
  return instance.get(
    `v1/admin/dashboard/config/getBizBucksConfig`,
    getHeader()
  );
};

export const updateBizBucksConfig = (body) => {
  return instance.post(
    `v1/admin/dashboard/updateBizBucksConfig`,
    body,
    getHeader()
  );
};

export const getBadgeTiersList = () => {
  return instance.get(`v1/admin/badgeTier/get-badge-tiers`, getHeader());
};

export const updateBadgeTiers = (body) => {
  return instance.put(
    `v1/admin/badgeTier/create-badge-tiers`,
    body,
    getHeader()
  );
};

export const getBusinessByTiers = (tier_name) => {
  return instance.get(
    `v1/admin/badgeTier/get-business-by-tiers?tier_name=${tier_name}`,
    getHeader()
  );
};

export const searchBySubscriptionAPI = (body) => {
  return instance.post(
    `v1/admin/dashboard/searchBySubscription`,
    body,
    getHeader()
  );
};

export const topTenBusinessActivityAPI = (body) => {
  return instance.post(
    `v1/admin/dashboard/top-business/activity`,
    body,
    getHeader()
  );
};

export const topTenBusinesssMostNumberOfBizBucksAPI = (body) => {
  return instance.post(
    `v1/admin/dashboard/top-business/withBizbucks`,
    body,
    getHeader()
  );
};
