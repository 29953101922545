//Open and close modal for adding generating new promo codes

import React from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import "assets/styles/scss/_styles/_commonModal.scss";
import { UpdateAdmin } from "config/apis/AdminAccess";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
const CommonModal = ({ openModal, setOpenModal, content, admin, onUpdate }) => {
  const dispatch = useDispatch();
  const updateAdminAccess = async () => {
    try {
      let body = { isActive: !admin?.isActive };

      const res = await UpdateAdmin(body, admin?.id);

      if (res.status === 200) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        onUpdate();
        setOpenModal(false);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("UPDATE ADMIN ACCESS", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal10">
          <Grid container>
            <Grid item xs={12} className="box-modal10-row1">
              <Typography>{content}</Typography>
            </Grid>
            <Grid item xs={12} className="box-modal10-row2">
              <button
                onClick={() => {
                  updateAdminAccess();
                }}
                className="box-modal10-row2-yes"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setOpenModal(false);
                }}
                className="box-modal10-row2-no"
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CommonModal;
