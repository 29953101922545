import React from "react";
//React Draft
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Draft
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

/**
 * RichText component
 * @param { Object } rawContent - object entity for RichText
 * @param { Function } setData -function to setdata of RichText
 * @returns
 */

const RichTextEditor = ({
  rawContent,
  setData,
  wrapperClassName,
  editorClassName,
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    if (rawContent)
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(rawContent)))
      );
  }, [rawContent]);

  return (
    <>
      <div>
        <Editor
          wrapperClassName={wrapperClassName}
          editorClassName={editorClassName}
          editorState={editorState}
          onEditorStateChange={(state) => {
            setEditorState(state);
            const rawData = convertToRaw(state.getCurrentContent());
            setData(JSON.stringify(rawData));
          }}
          toolbar={{
            fontFamily: {
              options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana",
              ],
            },
          }}
        />
      </div>
    </>
  );
};

export default RichTextEditor;
