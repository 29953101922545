import { useDispatch } from "react-redux";

// Icons
import { MenuAlt2Icon } from "@heroicons/react/outline";

// Redux
import { change } from "redux/navigation";
import MobileNav from "../AppNavigation/MobileNav";
/**
 * Top Navigation for logged in state
 */
function AppTopNav() {
  const dispatch = useDispatch();
  return (
    <>
      <div className="sticky top-0 app-top-nav flex-shrink-0 z-10   h-16">
        <MobileNav className="w-full" />
      </div>
    </>
  );
}

export default AppTopNav;
