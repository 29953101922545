//FAQ page

import React, { useState, useEffect } from "react";
//Individual question card component
import FaqForFaqPage from "components/PrivatePages/Faqs/FaqForFaqPage";
import AddFaq from "components/PrivatePages/Faqs/AddFaq";

//API
import { FAQs } from "config/apis/FAQS";
import { useLocation } from "react-router-dom";
const FaqPage = () => {
  //Variable to store the state(boolean value) for displaying a form to add a FAQ
  const location = useLocation();
  const [fetching, setFetching] = useState(false);
  const [addFaq, setAddFaq] = useState(false);
  const [allFAQS, setAllFAQS] = useState(false);
  //Function to get all faqs
  const getAllFAQs = async () => {
    setFetching(true);
    try {
      const res = await FAQs();
      console.log(res);
      if (res.status === 201) {
        let data = res?.data?.faq;
        setAllFAQS(data);
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log("error");
    }
    setFetching(false);
  };

  useEffect(() => {
    getAllFAQs();
  }, []);

  useEffect(() => {
    if (location?.state?.create) {
      setAddFaq(location?.state?.create);
    }
  }, [location?.state?.create]);

  return (
    <div className="grid grid-cols-1 gap-5 pl-5 pr-5">
      <div className="mb-3">
        <h1 className="text-4xl text-medium">Frequently asked questions</h1>
      </div>
      <div className="flex justify-center w-full">
        <div className="grid grid-cols-1 w-[38rem]">
          {addFaq ? (
            <div className="mb-5">
              <AddFaq
                addFaq={addFaq}
                setAddFaq={setAddFaq}
                onUpdate={() => {
                  getAllFAQs();
                  setAddFaq(false);
                }}
              />
            </div>
          ) : (
            <div className="col-span-1 mb-3">
              <button
                onClick={() => {
                  setAddFaq(true);
                }}
                className="bg-primary-blue-medium text-white  w-full   h-14 rounded-lg font-bold text-xl"
              >
                Add FAQ
              </button>
            </div>
          )}

          <div>
            <FaqForFaqPage allFAQS={allFAQS} onUpdate={() => getAllFAQs()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
