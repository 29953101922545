import React, { useEffect, useState } from "react";
import { BiFontSize } from "react-icons/bi";
import { IoIosNotifications } from "react-icons/io";
import { MdInfo } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotificationInfoModal from "./NotificationInfoModal";
import { UpdateStatusOfAdminNotification } from "config/apis/Notification";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { typesToEventNotificationFilter } from "helpers/constants/localJsonData";

const EventNotification = ({ notification, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [notification1, setNotification] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const notif = notification?.filter(
      (item) => !item.seen && item?.notifyToAdmin 
    )
    console.log("notification1 ==> ", JSON.stringify(notif))
    setNotification(notif)
  }, [notification]);

  const handleUpdateNotification = async (status) => {
    try {
      const res = await UpdateStatusOfAdminNotification(status);
      dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
      onUpdate();
    } catch (e) {
      console.error("Error updating badge tiers:", e);
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  const _renderNotification = () => {
    return (
      <div>
        {typesToEventNotificationFilter.map((itemp, index) => {

          // const filteredAllSeenNotifications = notification?.filter(
          //   (item) => !item.seen && item?.notifyToAdmin 
          // );

          // Filter the notificationList based on the current type in the loop
          const filteredNotifications = notification.filter(
            (item) => item.type === itemp.type
          );
          const filteredSeenNotifications = filteredNotifications?.filter(
            (item) => !item.seen && item?.notifyToAdmin 
          );

          return (
            <div className="my-5" key={index}>
              {/* Render the title */}
               
              {filteredSeenNotifications.length > 0 && <>
              <h2
                className="flex items-center gap-2"
                style={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                {itemp?.title}
              </h2>
              { filteredSeenNotifications?.map((item) => (
                  <div
                    key={item?.id}
                    className="event-notification-list bg-neutral-gray-0 flex items-center justify-between p-2 rounded-lg mt-2 w-full"
                  >
                    <div>{item?.description}</div>
                    <div className="flex items-center gap-2">
                      {/* <button>
                                  <MdInfo
                                    onClick={() => {
                                      setOpen(true);
                                    }}
                                    size={"2rem"}
                                  />
                                </button> */}
                      <button
                        className="rounded-md bg-primary-blue-dark p-1 text-white"
                        onClick={() => {
                          handleUpdateNotification({
                            id: item?.id,
                            query: {
                              seen: 1,
                            },
                          });
                        }}
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                ))
                /* ) : (
                  <p>No notifications available</p> // Display a message if there are no notifications for the type
                ) */
                }
                </> }
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div className="event-notification-box my-5">
        <div className="flex justify-between">
          <div
            className="flex items-center text-lg"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            <IoIosNotifications size={"2rem"} />
            Notifications
          </div>
          <button
            className="rounded-md bg-primary-blue-medium p-1 px-4 text-white"
            onClick={() => {
              history.push("/notifications", { showBackButton: true });
            }}
          >
            See All
          </button>
        </div>

        <div className="event-notification-card min-w-80 bg-neutral-gray-100 p-3 rounded-md mt-2">
          {notification1.length > 0 ? <div>{_renderNotification()}</div> : <p>No notifications available</p>}
        </div>
      </div>
      <NotificationInfoModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default EventNotification;
