import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { BiUser } from "react-icons/bi";
import GenerateMemberCard from "./GenerateMemberCard";
import { dashboardData } from "config/apis/dashboard.js";
import logError from "helpers/utils/logErrors";

const BadgesItem = ({ Badges, onUpdate }) => {
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [item, setItem] = useState();

  useEffect(() => {
    if (!isModalOpen2) {
      setItem();
    }
  }, [isModalOpen2]);

  return (
    <>
      {Badges?.length > 0 &&
        Badges?.map((item) => (
          <div
            key={item?.tier_id}
            className="grid grid-cols-10 md:mt-3 mt-2 p-3 rounded-lg"
          >
            <div className="md:col-span-5 col-span-5 w-full">
              <p className="md:text-base text-xs">{item?.tier_name}</p>
            </div>
            <div className="flex md:col-span-3 col-span-3 md:w-[149px] h-[32px] items-center ml-5 justify-center rounded-[10px]">
              <p className="md:text-base text-xs">
                {item?.tier_name?.toLowerCase() === "gold" &&
                parseInt(item?.max_backings) > parseInt(item?.min_backings)
                  ? `${item?.min_backings}+`
                  : `${item?.min_backings} - ${item?.max_backings}`}
                {/* {parseInt(item?.max_backings) > 5000
                  ? `${item?.min_backings}+`
                  : `${item?.min_backings} - ${item?.max_backings}`} */}
              </p>
            </div>
            <div className="flex md:col-span-2 col-span-2 md:w-[70px] h-[32px] items-center ml-5 justify-center">
              <div className="col-span-2">
                <button
                  onClick={() => {
                    setItem(item);
                    setModalOpen2(true);
                  }}
                  className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  <Avatar src={BiUser} />
                </button>
              </div>
            </div>
          </div>
        ))}

      <GenerateMemberCard
        isOpen={isModalOpen2}
        onClose={() => setModalOpen2(false)}
        item={item}
      />
    </>
  );
};

export default BadgesItem;
