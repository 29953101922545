import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import { getIndustriesAPI, updateCompanyInfoAPI } from "config/apis/Company";
import countryList from "country-list";
import moment from "moment";

const ProfileBasicDetails = ({ isMine, company, getCompanyData }) => {
  const countries = countryList.getCodeList();
  const [showModal, setShowModal] = useState(false);
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const elRef = useRef(null);
  const [view, setView] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const [location, setLocation] = useState("");
  const [otherLocation, setOtherLocation] = useState("");
  let user = company?.User;

  const editCompanyBasicInfo = async () => {
    setShowModal(false);
    await updateCompanyInfoAPI(user.id, {
      mobileNo: number,
      email: email,
      location: location == "Other" ? otherLocation : location,
    });
    setNumber("");
    setEmail("");
    await getCompanyData();
  };
  const editDetails = (number, email) => {
    setShowModal(true);
    setNumber(number);
    setEmail(email);
  };

  useEffect(() => {
    if (elRef.current.scrollWidth > elRef.current.clientWidth) {
      setView(true);
    }
  }, []);

  return (
    <>
      <div className="profile-basic-details">
        <div className="profile-basic-details-header">
          <div className="profile-business-header">Business Info</div>
          <div>
            {isMine && (
              <AiOutlineEdit
                className="profile-down-details-edit"
                onClick={() => editDetails(user?.mobileNo, user?.email)}
              />
            )}
          </div>
        </div>

        <div className="profile-basic-details-body">
          <div className="row flex">
            <div className="min-w-[100px] profile-basic-details-body-border py-3">
              <h3>Phone No.</h3>
              <h3>Email Id.</h3>
              {company?.location && <h3>Country</h3>}
              {company?.Industry?.industry_name && <h3>Industry</h3>}
              <h3>Member Since</h3>
            </div>
            <div className="py-3">
              <h4>{company?.User?.mobileNo || "NA"}</h4>
              <div ref={elRef}>
                <p
                  className="email-id"
                  style={{
                    fontSize: "15px",
                    wordBreak: "breakall",
                  }}
                >
                  <>{company?.User?.email}</>
                </p>
              </div>
              {company?.location && <h4>{company?.location}</h4>}
              {company?.Industry?.industry_name && (
                <h4>{company.Industry.industry_name}</h4>
              )}
              <h4>{moment(company?.createdAt).format("MMM DD, YYYY")}</h4>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="profile-modal modal-wrapper">
        <Dialog
          style={{
            minWidth: "345px",
            maxWidth: "600px",
            width: "100%",
          }}
          size="sm"
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>Update Company Info</DialogHeader>
          <DialogBody>
            <div className="form basic-details-form flex flex-col gap-4">
              <div className="form-group flex flex-col items-start justify-between">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  id="phone"
                  className="w-[250px]"
                  placeholder="Phone Number"
                  value={number}
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .trimStart()
                      .replace(/[^0-9\+]/gi, "");
                    if (e.target.value.length > 13) {
                    } else {
                      setNumber(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="form-group flex flex-col items-start justify-between">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-[250px]"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="location">Location*</label>
                <div className="location flex flex-row items-stretch justify-between w-100">
                  <div className="button">
                    <label
                      htmlFor="australia"
                      className={location === "Australia" ? "label-active" : ""}
                    >
                      Australia
                    </label>
                    <input
                      type="radio"
                      id="australia"
                      name="location"
                      value="Australia"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="button">
                    <label
                      htmlFor="NewZealand"
                      className={
                        location === "New Zealand" ? "label-active" : ""
                      }
                    >
                      New Zealand
                    </label>
                    <input
                      type="radio"
                      id="NewZealand"
                      name="location"
                      value="New Zealand"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="button">
                    <label
                      htmlFor="other"
                      className={location === "Other" ? "label-active" : ""}
                    >
                      Other
                    </label>
                    <input
                      type="radio"
                      id="other"
                      name="location"
                      value="Other"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {location === "Other" && (
                <div className="form-group my-3">
                  <label htmlFor="location">Country*</label>
                  <select
                    name="otherLocation"
                    id="otherLocation"
                    className={locationError ? "error-input" : ""}
                    onChange={(e) => setOtherLocation(e.target.value)}
                    value={otherLocation}
                  >
                    <option value="">Select Country</option>
                    {Object.entries(countries).map((val) => (
                      <option value={val[1]}>{val[1]}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="save-button">
              <Button
                color="green"
                onClick={(e) => editCompanyBasicInfo()}
                variant="gradient"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default ProfileBasicDetails;
