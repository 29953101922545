import { instance, getHeader } from "config/apis";

//Update admin Access

export const addEcosystem = (body) => {
  return instance.post(`/v1/ecosystem`, body, getHeader());
};
export const removeEcosystem = (id) => {
  return instance.delete(`/v1/ecosystem/${id}`, getHeader());
};
