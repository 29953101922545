import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import logError from "helpers/utils/logErrors";
import { adminForgotPassword } from "config/apis/auth";

const ForgotPassword = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      onForgotPassword(values);
    },
  });

  const onForgotPassword = async (values) => {
    try {
      const res = await adminForgotPassword(values);
      if (res.data?.success) {
        setOpen(false);
        dispatch(showToast({ message: res.data?.message }));
      } else {
        dispatch(showToast({ message: res.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Forgot Password", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal4">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="box-modal-header">
                Forgot Your Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p className="text-sm">
                Please enter your email address. If you have an account with us,
                you will get a link to reset your password.
              </p>
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal-label">Email ID</Typography>
              <input
                id="email"
                name="email"
                type="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="box-modal-field"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-600 text-sm">{formik.errors.email}</p>
              ) : null}
            </Grid>
            <Grid item xs={12} className="box-modal4-lastRow">
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="box-modal4-lastRow-button1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                className="box-modal4-lastRow-button2 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                Reset
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
