// Icons
import * as OutlineIcons from "@heroicons/react/outline";
import * as SolidIcons from "@heroicons/react/solid";
import { BiSolidDashboard } from "react-icons/bi";
import { TbTriangleSquareCircleFilled } from "react-icons/tb";
import { FaUserAltSlash } from "react-icons/fa";
import { LiaUserSlashSolid } from "react-icons/lia";
import { TbTriangleSquareCircle } from "react-icons/tb";
import { LuLayoutDashboard } from "react-icons/lu";

// Utils
import {
  DASHBOARD,
  ADMIN,
  SETTINGS,
  USERS,
  ECOSYSTEM,
  UNVERIFIEDUSERS,
  EVENTS,
  SERVICEREQUESTS,
  PERKS,
  STATS,
  NOTIFICATION,
} from "./MenuTabs";

// Navigation List
const navigation = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: LuLayoutDashboard,
    selectedIcon: BiSolidDashboard,
    tab: DASHBOARD,
  },
  {
    name: "Ecosystem",
    path: "/ecosystem",
    icon: TbTriangleSquareCircle,
    selectedIcon: TbTriangleSquareCircleFilled,
    tab: ECOSYSTEM,
  },
  {
    name: "Unverified Users",
    path: "/unverifiedusers",
    icon: LiaUserSlashSolid,
    selectedIcon: FaUserAltSlash,
    tab: UNVERIFIEDUSERS,
  },
  {
    name: "Events",
    path: "/events",
    icon: OutlineIcons.CalendarIcon,
    selectedIcon: SolidIcons.CalendarIcon,
    tab: EVENTS,
  },
  {
    name: "Requests",
    path: "/servicerequests",
    icon: OutlineIcons.ExclamationCircleIcon,
    selectedIcon: SolidIcons.ExclamationCircleIcon,
    tab: SERVICEREQUESTS,
  } /* ,
  {
    name: "Perks",
    path: "/perks",
    icon: OutlineIcons.HandIcon,
    selectedIcon: SolidIcons.HandIcon,
    tab: PERKS,
  } */ /* ,
  {
    name: "Stats",
    path: "/stats",
    icon: OutlineIcons.ChartBarIcon,
    selectedIcon: SolidIcons.ChartBarIcon,
    tab: STATS,
  } */,
  {
    name: "Notification",
    path: "/notifications",
    icon: OutlineIcons.BellIcon,
    selectedIcon: SolidIcons.BellIcon,
    tab: NOTIFICATION,
  },
  // {
  //     name: "Users",
  //     path: "/users",
  //     icon: OutlineIcons.UsersIcon,
  //     selectedIcon: SolidIcons.UsersIcon,
  //     tab: USERS,
  // },
];
// Admin Navigation List
const adminNavigation = [
  {
    name: "Admin",
    path: "/admin",
    icon: OutlineIcons.ViewGridIcon,
    selectedIcon: SolidIcons.ViewGridIcon,
    tab: ADMIN,
  },
];

const userNavigation = [
  {
    name: "Settings",
    path: "/settings",
    icon: OutlineIcons.CogIcon,
    selectedIcon: SolidIcons.CogIcon,
    tab: SETTINGS,
  },
  {
    name: "Log out",
    icon: OutlineIcons.LogoutIcon,
    selectedIcon: SolidIcons.LogoutIcon,
  },
];

export { navigation, userNavigation, adminNavigation };
