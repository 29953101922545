//Sign in form component for sign in page
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//icons
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Initial values for form
import initialValues from "helpers/defaultValues/SignIn/initialValues";

// Redux
import { useDispatch } from "react-redux";
import { userUpdate } from "redux/user";
import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";

// APIs
import { adminSignIn } from "config/apis/auth";

// Components
import SpinLoader from "components/Common/SpinLoader";
import ForgotPassword from "../ForgotPassword";

/**
 * The Sign in form
 * @returns { Component }
 */
const SignInForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [signingIn, setSigningIn] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: initialValues.email,
      password: initialValues.password,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      onSignIn(values);
    },
  });

  const onSignIn = async (values) => {
    setSigningIn(true);

    try {
      let body = { ...values };
      const res = await adminSignIn(body);
      if (res.data?.data || res.data?.token) {
        dispatch(
          userUpdate({
            admin: res.data?.admin,
            backboneAdmin: res.data?.token,
          })
        );

        if (res?.data?.admin?.isSuperAdmin && res?.data?.firstLogin) {
          setTimeout(() => {
            history.push({
              pathname: "/settings",
            });
          }, 1000);
        } else {
          setTimeout(() => {
            history.push({
              pathname: "/dashboard",
            });
          }, 1000);
        }

        dispatch(showToast({ message: "Sign In successfull!" }));
      } else {
        setSigningIn(false);
        dispatch(showToast({ message: res.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Sign In", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <div className="w-full h-screen items-center justify-center flex-row flex px-4 py-8">
        <div className="w-full max-w-lg md:w-3/4 h-full flex flex-row items-center justify-center">
          <div className="w-full grid grid-cols-1 gap-3">
            <div className="justify-center items-center flex mb-5">
              <img src="/assets/images/logos/Logo.png"></img>
            </div>
            <div className="justify-center items-center flex">
              <h1 className="font-semibold text-4xl">Admin Access</h1>
            </div>
            <div className="justify-center items-center flex md:mb-5">
              <p>
                Please enter the credentials that has been sent to your email ID
              </p>
            </div>
            <div className="justify-center items-center grid-cols-1 ">
              <p className="text-base justify-start items-center flex text-neutral-gray-light font-semibold">
                Email ID
              </p>
              <input
                className="w-full rounded bg-transparent border-[1px] border-[#8D8D8D] focus:ring-transparent  "
                type="email"
                id="email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <p className="text-red-600 text-sm">
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </p>
            </div>
            <div className="justify-center items-center grid-cols-1 mt-2 ">
              <p className="text-base justify-start items-center flex  text-neutral-gray-light font-semibold">
                Password
              </p>
              <div className="w-full rounded bg-transparent border-[1px] border-[#8D8D8D] flex items-center">
                <input
                  className="border-none w-[95%] rounded focus:ring-transparent focus:border-transparent"
                  type={visible ? "text" : "password"}
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <button onClick={() => setVisible(!visible)}>
                  {visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-red-600 text-sm">{formik.errors.password}</p>
              ) : null}
            </div>
            <div className="mb-8">
              <p className="text-xs justify-start font-light items-center flex text-primary-blue-dark">
                <button
                  onClick={() => setIsForgotPasswordOpen(true)}
                  className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 font-semibold text-[1rem]"
                >
                  Forgot password?
                </button>
              </p>
            </div>
            <div className="justify-center items-center flex">
              <button
                onClick={formik.handleSubmit}
                type="submit"
                className="bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-2/5 w-3/5 h-10 rounded-lg font-bold text-xl"
              >
                {signingIn ? <SpinLoader /> : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ForgotPassword
        open={isForgotPasswordOpen}
        setOpen={setIsForgotPasswordOpen}
      />
    </>
  );
};

export default SignInForm;
