import { instance, getHeader } from "config/apis";

export const getAdminNotification = (page = 0) => {
  return instance.get(
    `/notifications/admin_super_all?page=${page}`,
    getHeader()
  );
};

export const UpdateStatusOfAdminNotification = (payload) => {
  return instance.put(`/notifications/update`, payload, getHeader());
};
