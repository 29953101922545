import { instance, getHeader } from "config/apis";

//Update user status

export const updateUserStatus = (body, id) => {
  return instance.put(`/v1/userStatus/${id}`, body, getHeader());
};

export const moveSubscription = (body, id) => {
  return instance.put(
    `/v1/userStatus/moveSubscription/${id}`,
    body,
    getHeader()
  );
};

export const deleteUser = (id) => {
  return instance.delete(`/user/deleteUser/${id}`, getHeader());
};

//send message to user
export const sendMessageAPI = (body) => {
  return instance.post(`userMail/send-message-to-user`, body, getHeader());
};

export const sendVerificationMailAPI = (body) => {
  return instance.post(`/v1/admin/user/verifyEmail`, body, getHeader());
};

export const sendRequestToChangeAPI = (body) => {
  return instance.put(`/v1/superAdmin/requesttochange`, body, getHeader());
};
