import React, { useEffect, useState } from "react";
import DropDownMenu from "./DropDownMenu";
import SelectOptions from "./SelectOptions";
import { strToMonthNumMap } from "helpers/defaultValues/dashboard";
import emptyImg from "../../../../assets/illustrations/empty.svg";
import { getReferringBusinesses } from "config/apis/dashboard.js";

const TopBusiness = ({ cardData, cardType }) => {
  console.log("TopBusiness Data: ", cardData);

  const [filter, setFilter] = useState("all");
  const [option, setOption] = useState();
  const [data, setData] = useState();
  const [year, setYear] = useState();

  const fetchData = async () => {
    try {
      const month = strToMonthNumMap[option];
      let query = { year, filter };
      if (filter === "monthly") query.month = month;
      const response = await getReferringBusinesses(query);
      console.log("TopBusiness Data: ", response.data.data);
      setData(response?.data?.data);
      if (filter === "all") setOption(undefined);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (cardData) {
      fetchData();
    }
  }, [cardData, filter, option, year]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full  p-5   ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">Top 10 referring businesses</p>
        </div>
        <div className="flex items-center gap-2">
          {filter !== "all" && (
            <div>
              <SelectOptions
                filter={filter}
                setOption={setOption}
                setYear={setYear}
              />
            </div>
          )}
          <div>
            <DropDownMenu setFilter={setFilter} setYear={setYear} />
          </div>
        </div>
      </div>
      <div className="overflow-scroll overflow-x-hidden h-[16.5rem]">
        {data?.length > 0 ? (
          data?.map((item, index) => {
            return (
              <div
                key={index}
                className="rounded-[10px] bg-[#00000005] grid grid-cols-12 mt-3 "
              >
                <div className="col-span-2 rounded-tl-[10px] rounded-bl-[10px] p-2 bg-[#8BF693]  flex justify-center items-center flex-col">
                  <div>
                    <p className="text-[#1C9925] text-3xl font-bold">
                      {item.referralUserCount}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#464646]">referrals</p>
                  </div>
                </div>
                <div className="flex justify-start items-center p-2 col-span-10">
                  <p className="text-xl font-medium"> {item.company_name}</p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex w-full h-full justify-center gap-4 items-center">
            <img src={emptyImg} alt="No Data Found!" className="max-h-full" />
            <h1 className="text-light-blue-900 text-3xl">No Data Found!</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBusiness;
