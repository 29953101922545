// The commented out routes are not being used for now and the associated components are marked as Deprecated ( These are not to be deleted )
import ResetPassword from "pages/AuthPages/ResetPassword";
import SignIn from "pages/AuthPages/SignIn";

// Public Routes
// Routes for Authenticating Users
const publicRoutes = [
  // Authentication
  { path: "/auth/signIn", component: SignIn },
  { path: "/auth/admin/reset-password/:token", component: ResetPassword },
];

export { publicRoutes };
