//MUI accordion component to be mapped on free trial subscription card

import React from "react";
//React hooks
import { useState } from "react";

//Icons
import { AiFillCaretDown } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { MdEditNote } from "react-icons/md";

//MUI accordion component
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//MUI typography
import Typography from "@mui/material/Typography";
//MUI avatar
import Avatar from "@mui/material/Avatar";
//Avatar image
// import avatarIMG from "assets/subscriptionAdmin/avatarCircle.png";
import avatarIMG from "assets/logos/BackboneLogo.svg";
//MUI toggle button
import ToggleButton from "../common/toggleButton";
import SendMessage from "../modals/SendMessage";
import ActionMembershipModal from "../modals/actionMembershipModal";
import moment from "moment";
import { HiTrash } from "react-icons/hi";

import { sendVerificationMailAPI } from "config/apis/UserAPIs";

import { Link } from "react-router-dom";
import ConfirmRemoveModal from "../modals/ConfirmRemoveModal";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import MoveSubscription from "../modals/MoveSubscription";
export default function UserAccordion({ users, onUpdate, unverified }) {
  const [open, setOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [action, setAction] = useState("");
  const [userID, setUserID] = useState("");
  const [updateUserStatusID, setUpdateUserStatusID] = useState("");
  const [openMove, setOpenMove] = useState(false);
  const dispatch = useDispatch();

  console.log(updateUserStatusID);
  console.log(users);

  const sendVerificationMail = async (id) => {
    const body = { id: id };
    try {
      const res = await sendVerificationMailAPI(body);
      dispatch(
        showToast({
          message: res?.data?.message,
          type: "success",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
    console.log("Verification Mail Sent");
  };

  return (
    <>
      {users?.length > 0 &&
        users?.map((item) => {
          console.log(item);
          return (
            <div id="root" key={item?.id}>
              <Accordion className="shadow-none">
                <AccordionSummary
                  expandIcon={
                    <AiFillCaretDown className="text-2xl text-primary-blue-dark" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex items-center gap-2">
                      <Avatar
                        alt="Remy Sharp"
                        src={item?.Company?.company_logo || avatarIMG}
                      />
                      <Typography className="font-semibold text-base">
                        {item?.Company?.company_name}
                      </Typography>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-12 rounded-lg border-[1px] border-[#EBEBEB] p-5">
                    <div className="col-span-12">
                      <Typography className="text-[#646464] font-normal text-xs">
                        Name : {item?.Company?.company_name}
                      </Typography>
                    </div>
                    <div className="col-span-12">
                      <Typography className="text-[#646464] font-normal text-xs">
                        Subscription Start :{" "}
                        {moment(item?.subscriptionStart).format("LL")}
                      </Typography>
                    </div>
                    <div className="col-span-12">
                      <Typography className="text-[#646464] font-normal text-xs">
                        Subscription End :{" "}
                        {moment(item?.subscriptionValidity).format("LL")}
                      </Typography>
                    </div>

                    <div className="col-span-12 flex justify-between items-center">
                      <div className="flex gap-4 mt-3">
                        {unverified ? (
                          <div
                            className="text-blue-400 underline underline-offset-1 cursor-pointer"
                            onClick={() => {
                              sendVerificationMail(item?.id);
                            }}
                          >
                            <h1>Send Verification Mail</h1>
                          </div>
                        ) : (
                          <>
                            {" "}
                            <div>
                              <button
                                onClick={() => {
                                  setOpenMessage(true);
                                  setUserID(item.id);
                                }}
                                className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                              >
                                <AiOutlineMail className="text-2xl text-[#505050]" />
                              </button>
                            </div>
                            <div>
                              <button
                                className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                                onClick={() => {
                                  console.log(item);
                                  if (
                                    item?.subscription?.toLowerCase()?.trim() ==
                                    "free"
                                  ) {
                                    setUpdateUserStatusID(item?.id);
                                    setAction("move");
                                    setOpenMove(true);
                                  }
                                  if (!item?.subscription) {
                                    setUpdateUserStatusID(item?.id);
                                    setAction("unassigned");
                                    setOpenMove(true);
                                  }
                                  // setOpen(true);
                                }}
                              >
                                <BiTransfer className="text-2xl text-[#505050]" />
                              </button>
                            </div>
                          </>
                        )}
                        {item.hasCompany && (
                          <div>
                            <Link to={`company/${item.company_id}`}>
                              <button
                                className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                                onClick={() => {
                                  setAction("move");
                                  setOpen(true);
                                  console.log(item);
                                }}
                              >
                                <MdEditNote className="text-2xl text-[#505050]" />
                              </button>
                            </Link>
                          </div>
                        )}
                        <div>
                          <button
                            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                            onClick={() => {
                              setRemove(true);
                              setUpdateUserStatusID(item?.id);
                            }}
                          >
                            <HiTrash className="text-2xl text-red-500" />
                          </button>
                        </div>
                      </div>
                      {/* Change to show toggle on based on item?.status  */}
                      <div>
                        <ToggleButton
                          setUpdateUserStatusID={setUpdateUserStatusID}
                          open={open}
                          setOpen={setOpen}
                          action={action}
                          setAction={setAction}
                          status={item?.status}
                          userID={item?.id}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      <MoveSubscription
        openMove={openMove}
        setOpenMove={setOpenMove}
        action={action}
        updateUserStatusID={updateUserStatusID}
        onUpdate={onUpdate}
      />
      <ActionMembershipModal
        open={open}
        setOpen={setOpen}
        action={action}
        updateUserStatusID={updateUserStatusID}
        onUpdate={onUpdate}
      />

      <ConfirmRemoveModal
        open={remove}
        setOpen={setRemove}
        id={updateUserStatusID}
        onUpdate={onUpdate}
      />
      <SendMessage
        userID={userID}
        openMessage={openMessage}
        setOpenMessage={setOpenMessage}
      />
    </>
  );
}
