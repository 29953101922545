import PromoCodeCard from "components/PrivatePages/SystemDashboard/promoCodeCard";
import BadgesCard from "components/PrivatePages/SystemDashboard/SystemBadges/BadgesCard";
import BizBucksCard from "components/PrivatePages/SystemDashboard/SystemBizBucks/BizBucksCard";

/**
 * System Admin Dashboard View
 * @returns { Component }
 */

function SystemAdmin({ promocodes, onUpdate }) {
  return (
    <div className="grid lg:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-x-2 gap-y-6 mb-7">
      <PromoCodeCard promocodes={promocodes} onUpdate={onUpdate} />
      <BizBucksCard onUpdate={onUpdate} />
      <BadgesCard onUpdate={onUpdate} />
    </div>
  );
}

export default SystemAdmin;
