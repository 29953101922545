import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import DropDownMenu from "./DropDownMenu";
import {
  signUpData,
  numToMonthStrMap,
  fillMonth,
  fillDays,
} from "helpers/defaultValues/dashboard";
import SelectOptions from "./SelectOptions";
import { strToMonthNumMap } from "helpers/defaultValues/dashboard";
import { getSignUpStats } from "config/apis/dashboard.js";

const SignUps = () => {
  const [filter, setFilter] = useState("annual");
  const [year, setYear] = useState(new Date().getFullYear());
  const [option, setOption] = useState();
  const [data, setData] = useState(signUpData);

  const filteringSignUps = async (type = "all", month, year = "2023") => {
    let arr = [];
    // if (filter === "all" && cardData[type]?.length > 0) {
    //   arr = cardData[type]?.map((item) => {
    //     let month = numToMonthStrMap[item.month];

    //     return {
    //       groupBy: item.month,
    //       groupByName: month,
    //       self: item.selfUserCount,
    //       referrals: item.referralUserCount,
    //     };
    //   });
    // } else {
    let tempArr = [];
    try {
      let obj = { year: year };
      if (filter === "monthly") obj["month"] = month;
      const response = await getSignUpStats(obj);
      tempArr = response.data.data;

      if (filter == "monthly") {
        tempArr = fillDays(tempArr);
      } else {
        tempArr = fillMonth(tempArr);
      }
      arr =
        filter == "monthly"
          ? tempArr?.map((item) => {
              let day = item?.justDate;
              return {
                groupBy: day,
                groupByName: day,
                self: item.selfUserCount || 0,
                referrals: item.referralUserCount || 0,
              };
            })
          : tempArr?.map((item) => {
              let month = numToMonthStrMap[item.month] || 0;

              return {
                groupBy: item.month,
                groupByName: month,
                self: item.selfUserCount || 0,
                referrals: item.referralUserCount || 0,
              };
            });
    } catch (error) {
      console.error(error);
    }
    // }
    arr = arr.sort((a, b) => parseInt(a.groupBy) - parseInt(b.groupBy));

    return arr;
  };
  const fetchData = async () => {
    const month = strToMonthNumMap[option];
    const tempData = { ...data };
    tempData.list = await filteringSignUps(filter, month, year);
    setData(tempData);
    if (filter === "all") setOption(undefined);
  };

  useEffect(() => {
    fetchData();
  }, [filter, option, year]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full p-5 ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">{data?.type}</p>
          <p className="text-xs pt-1">{new Date().getFullYear()}</p>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <DropDownMenu setFilter={setFilter} setYear={setYear} />
          </div>{" "}
          {filter !== "all" && (
            <div>
              <SelectOptions
                filter={filter}
                setOption={setOption}
                setYear={setYear}
              />
            </div>
          )}
        </div>
      </div>

      <div style={{ height: "270px", marginTop: "10px" }}>
        {console.log(data)}
        <ResponsiveBar
          groupMode="grouped"
          data={data?.list}
          margin={{ top: 60, right: 30, bottom: 50, left: 30 }}
          padding={0.1}
          keys={data?.keys}
          indexBy="groupByName"
          colors={{ scheme: data?.scheme }}
          innerPadding={4} /* inner padding between multiple bars same month */
          enableLabel={false} /* turns off label values on chart */
          valueFormat=""
          enableGridX={true}
          enableGridY={true} /* turns off grid lines */
          axisTop={null}
          axisRight={null}
          onClick={(data) => {
            console.log(data["data"]?.groupBy);
          }}
          axisLeft={{
            tickSize: 2,
            tickPadding: 2,
            tickRotation: 0,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          legendLabel={(e) => data?.legendLabel[e.id]}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 200,
              translateY: -30,
              itemsSpacing: 10,
              itemWidth: 50,
              itemHeight: 14,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              toggleSerie: true,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SignUps;
