export const typesToEventNotificationFilter = [
  {
    title: "Podcast Request",
    type: "podcast_request",
  },
  {
    title: "Confirmed Podcast",
    type: "podcast_confirm",
  },
  {
    title: "Waitlisted Podcast",
    type: "podcast_waitlist",
  },
  {
    title: "Spotlight Request",
    type: "spotlight_request",
  },
  {
    title: "Confirmed Spotlight",
    type: "spotlight_confirm",
  },
  {
    title: "Waitlisted Spotlight",
    type: "spotlight_waitlist",
  },
  {
    title: "BB Event Registration",
    type: "bb_event_registration_new",
  },
  {
    title: "BB Event Registration Update",
    type: "bb_event_resgitration_update",
  },
  {
    title: "BB Event Registration Update",
    type: "bb_event_registration_update",
  },
  {
    title: "BB Event Registration Cancel",
    type: "bb_event_resgitration_cancel",
  },
  {
    title: "BB Event Attendees Change",
    type: "bb_event_attendee_change",
  },
  {
    title: "User Event Registration",
    type: "user_event_registration_new",
  },
  {
    title: "User Event Registration Cancel",
    type: "user_event_registration_cancel",
  },
  {
    title: "User Event Registration Update",
    type: "user_event_registration_update",
  },
  {
    title: "User Event Registration Changed",
    type: "user_event_registration_change",
  },
  {
    title: "Backing",
    type: "backing",
  },
  {
    title: "Earned Rewards",
    type: "earn_reward",
  },
];

export const typesToAdminNotificationFilter = [
  {
    title: "Podcast Request",
    type: "podcast_request",
  },
  {
    title: "Confirmed Podcast",
    type: "podcast_confirm"
  },
  {
    title: "Waitlisted Podcast",
    type: "podcast_waitlist"
  },
  {
    title: "Spotlight Request",
    type: "spotlight_request",
  },
  {
    title: "Confirmed Spotlight",
    type: "spotlight_confirm"
  },
  {
    title: "Waitlisted Spotlight",
    type: "spotlight_waitlist"
  },
  {
    title: "BB Event Registration",
    type: "bb_event_registration_new",
  },
  {
    title: "BB Event Registration Update",
    type: "bb_event_resgitration_update",
  },
  {
    title: "BB Event Registration Update",
    type: "bb_event_registration_update",
  },
  {
    title: "BB Event Registration Cancel",
    type: "bb_event_resgitration_cancel",
  },
  {
    title: "BB Event Attendees Change",
    type: "bb_event_attendee_change",
  },
  {
    title: "User Event Registration",
    type: "user_event_registration_new"
  },
  {
    title: "User Event Registration Cancel",
    type: "user_event_registration_cancel"
  },
  {
    title: "User Event Registration Update",
    type: "user_event_registration_update"
  },
  {
    title: "User Event Registration Changed",
    type: "user_event_registration_change"
  },
  {
    title: "Backing",
    type: "backing"
  },
  {
    title: "Earned Rewards",
    type: "earn_reward"
  }
];
