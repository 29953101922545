import AvatarIMG from "assets/subscriptionAdmin/avatarCircle.png";
export const userList = [
  {
    type: "Free trial",
    users: [
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
    ],
  },
  {
    type: "Monthly",
    users: [
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
    ],
  },
  {
    type: "Annually",
    users: [
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
      { name: "Boton Ltd.", dateOfJoining: "21st Sept. 2021" },
    ],
  },
];

export const subscriptionPlans = [
  {
    subscription_name: "Monthly",
    subscription_cost: "$150",
    subscription_description1: "Unlimited  hello with other businesses",
    subscription_description2:
      "Promote your brand and create an online digital channel for customers",
    subscription_description3:
      "Earn $100 advertising credit for every successful endorement recieved/given",
    subscription_description4: "Cancel at anytime",
  },
  {
    subscription_name: "Annual",
    subscription_cost: "$1200",
    subscription_description1: "Unlimited hi with other businesses",
    subscription_description2:
      "Promote your brand and create an online digital channel for customers",
    subscription_description3:
      "Earn $100 advertising credit for every successful endorement recieved/given",
    subscription_description4: "Cancel at anytime",
  },
  {
    subscription_name: "Platinum",
    subscription_cost: "$3000",
    subscription_description1: "Unlimited hey with other businesses",
    subscription_description2:
      "Promote your brand and create an online digital channel for customers",
    subscription_description3:
      "Earn $100 advertising credit for every successful endorement recieved/given",
    subscription_description4: "Cancel at anytime",
  },
];

export const admins = [
  {
    adminType: "System",
    admins: [
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
    ],
  },
  {
    adminType: "Content",
    admins: [
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
    ],
  },
  {
    adminType: "Subscription",
    admins: [
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
    ],
  },
  {
    adminType: "Subscription",
    admins: [
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
      {
        name: "Marilyn Workman ",
        adminSince: "Admin since Feb 2022",
        image: AvatarIMG,
      },
    ],
  },
];

export const signUpData = {
  scheme: "set2", //color scheme for graph bars
  type: "Sign-ups",
  list: [
    {
      groupBy: 1,
      groupByName: "Jan",
      Self: 800,
      Referals: 200,
    },
    {
      groupBy: 2,
      groupByName: "Feb",
      Self: 150,
      Referals: 250,
    },
    {
      groupBy: 3,
      groupByName: "Mar",
      Self: 326,
      Referals: 444,
    },
    {
      groupBy: 4,
      groupByName: "Apr",
      Self: 526,
      Referals: 326,
    },
    {
      groupBy: 5,
      groupByName: "May",
      Self: 911,
      Referals: 36,
    },
    {
      groupBy: 6,
      groupByName: "Jun",
      Self: 104,
      Referals: 324,
    },
    {
      groupBy: 7,
      groupByName: "Jul",
      Self: 10,
      Referals: 318,
    },
    {
      groupBy: 8,
      groupByName: "Aug",
      Self: 80,
      Referals: 259,
    },
    {
      groupBy: 9,
      groupByName: "Sep",
      Self: 12,
      Referals: 245,
    },
    {
      groupBy: 10,
      groupByName: "Oct",
      Self: 123,
      Referals: 125,
    },
    {
      groupBy: 11,
      groupByName: "Nov",
      Self: 300,
      Referals: 400,
    },
    {
      groupBy: 12,
      groupByName: "Dec",
      Self: 500,
      Referals: 600,
    },
  ],

  count: 12,
  tickValues: [0, 10, 20, 30, 40],
  keys: ["self", "referrals"],
  legendLabel: { self: "Self", referrals: "Referrals" },
};

export const renewalData = {
  scheme: "paired",
  type: "Renewals",
  list: [
    {
      groupBy: 1,
      groupByName: "Jan",
      monthlySubscription: 800,
      annualSubscription: 200,
    },
    {
      groupBy: 2,
      groupByName: "Feb",
      monthlySubscription: 150,
      annualSubscription: 250,
    },
    {
      groupBy: 3,
      groupByName: "Mar",
      monthlySubscription: 326,
      annualSubscription: 444,
    },
    {
      groupBy: 4,
      groupByName: "Apr",
      monthlySubscription: 526,
      annualSubscription: 326,
    },
    {
      groupBy: 5,
      groupByName: "May",
      monthlySubscription: 911,
      annualSubscription: 36,
    },
    {
      groupBy: 6,
      groupByName: "Jun",
      monthlySubscription: 104,
      annualSubscription: 324,
    },
    {
      groupBy: 7,
      groupByName: "Jul",
      monthlySubscription: 10,
      annualSubscription: 318,
    },
    {
      groupBy: 8,
      groupByName: "Aug",
      monthlySubscription: 80,
      annualSubscription: 259,
    },
    {
      groupBy: 9,
      groupByName: "Sep",
      monthlySubscription: 12,
      annualSubscription: 245,
    },
    {
      groupBy: 10,
      groupByName: "Oct",
      monthlySubscription: 123,
      annualSubscription: 125,
    },
    {
      groupBy: 11,
      groupByName: "Nov",
      monthlySubscription: 300,
      annualSubscription: 400,
    },
    {
      groupBy: 12,
      groupByName: "Dec",
      monthlySubscription: 500,
      annualSubscription: 600,
    },
  ],

  count: 12,
  tickValues: [0, 250, 500, 750, 1000],
  keys: ["monthlySubscription", "annualSubscription"],
  legendLabel: {
    monthlySubscription: "Monthly Subscription",
    annualSubscription: "Annual Subscription",
  },
};

export const newJoinerData = [
  {
    id: "New customers",
    color: "#521EFD",
    data: [
      {
        x: "Jan",
        y: 632,
      },
      {
        x: "Feb",
        y: 210,
      },
      {
        x: "Mar",
        y: 223,
      },
      {
        x: "Apr",
        y: 122,
      },
      {
        x: "May",
        y: 231,
      },
      {
        x: "Jun",
        y: 782,
      },
      {
        x: "Jul",
        y: 444,
      },
      {
        x: "Aug",
        y: 925,
      },
      {
        x: "Sep",
        y: 195,
      },
      {
        x: "Oct",
        y: 189,
      },
      {
        x: "Nov",
        y: 844,
      },
      {
        x: "Dec",
        y: 204,
      },
    ],
  },
];

export const pieData1 = [
  {
    id: "Browse Service",
    label: "Browse Service",
    value: 120,
    color: "#00B027",
  },
  {
    id: "Advertising",
    label: "Advertising",
    value: 24,
    color: "#7080FF",
  },
  {
    id: "Promote Events",
    label: "Promote Events",
    value: 36,
    color: "#E38B06;",
  },
];

export const numToMonthStrMap = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const strToMonthNumMap = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
};

export const wordCloud = [
  { value: "JavaScript", count: 38 },
  { value: "React", count: 30 },
  { value: "Nodejs", count: 28 },
  { value: "Express.js", count: 25 },
  { value: "HTML5", count: 33 },
  { value: "MongoDB", count: 18 },
  { value: "CSS3", count: 20 },
];

export const fillMonth = (list) => {
  let temp = [...list];
  let allmonths = list?.map((i) => i?.month);
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i) => {
    if (allmonths?.includes(i)) {
    } else {
      temp.push({ month: i });
    }
  });
  temp.sort((a, b) => a.month - b.month);
  return temp;
};

export const fillDays = (list) => {
  let temp = [...list];
  let allDates = list?.map((i) => i?.justDate);
  [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]?.map((i) => {
    if (allDates?.includes(i)) {
    } else {
      temp.push({ justDate: i });
    }
  });
  temp.sort((a, b) => a.justDate - b.justDate);
  return temp;
};
