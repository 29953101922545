import React, { useEffect, useState } from "react";
import "./badges.css";

const GenerateBadgesModal = ({ isOpen, onClose, onSave, badgesData }) => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    setBadges(badgesData);
  }, [badgesData]);

  const handleBadgeChange = (tier_id, field, value) => {
    setBadges((prevBadges) =>
      prevBadges.map((badge) =>
        badge.tier_id === tier_id ? { ...badge, [field]: value } : badge
      )
    );
  };

  const sortBadges = (badges) => {
    const order = { Gold: 3, Silver: 2, Bronze: 1 };
    return badges.sort((a, b) => order[a.tier_id] - order[b.tier_id]);
  };

  const validateBadgeOrder = () => {
    const sortedBadges = sortBadges([...badges]).reverse();
    console.log("validateBadgeOrder ==> " + JSON.stringify(sortedBadges));

    // Validate each badge's min_backings and max_backings
    for (let badge of sortedBadges) {
      if (parseInt(badge.min_backings) >= parseInt(badge.max_backings)) {
        alert(`Invalid range within ${badge.tier_name}`);
        return false;
      }
    }

    // Validate the order between badges
    for (let i = 0; i < sortedBadges.length - 1; i++) {
      console.log(
        sortedBadges[i].max_backings +
          " max_backings and min_backings " +
          sortedBadges[i + 1].min_backings
      );
      if (
        parseInt(sortedBadges[i].max_backings) >=
        parseInt(sortedBadges[i + 1].min_backings)
      ) {
        alert(
          `Invalid range between ${sortedBadges[i].tier_name} and ${
            sortedBadges[i + 1].tier_name
          }`
        );
        return false;
      }
    }

    return true;
  };

  const handleSave = () => {
    if (validateBadgeOrder()) {
      onSave(badges);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Update Badges</h2>
        <div className="count-section">
          {badges.map((badge) => (
            <div key={badge.tier_id} className="count-row">
              <label>{badge.tier_name}</label>
              <input
                type="text"
                value={badge.min_backings}
                onChange={(e) =>
                  handleBadgeChange(
                    badge.tier_id,
                    "min_backings",
                    e.target.value
                  )
                }
              />
              <span>-</span>
              <input
                type="text"
                value={badge.tier_name === "Gold" ? "+" : badge.max_backings}
                onChange={(e) =>
                  handleBadgeChange(
                    badge.tier_id,
                    "max_backings",
                    e.target.value
                  )
                }
                disabled={badge.tier_name === "Gold"}
              />
            </div>
          ))}
        </div>
        <div className="button-section">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateBadgesModal;
