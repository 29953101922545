// Components
import AppNavigation from "layout/Navigation/AppNavigation";
import AppTopNav from "layout/Navigation/AppTopNav";

/**
 * Wrapping the App for logged in state
 */
export default function PortalLayout({ children }) {
  return (
    <>
      <div>
        <AppNavigation />

        <div className="lg:pl-32 flex flex-col flex-1">
          {/* Top bar  */}
          <AppTopNav />
          <main className="flex-1">
            <div className="mx-auto bg-white">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
