// // DONE
import { editorState, convertToRaw } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
// import draftToHtml from "draftjs-to-html";
//Blog card design for the blog list page
import React from "react";
//Blue chips for the blog card (blog list page)
import BlueChips from "../../../Common/blueChips";
// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import logError from "helpers/utils/logErrors";
import { deleteABlog } from "config/apis/Blogs";
//Icons
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";
import { useHistory } from "react-router-dom";
// TODO : Description coming as html to be showm properly
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

const BlogCard = ({ blogData, onUpdate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const rawContentState = convertToRaw(editorState.blogData.blog_description);
  // const markup = draftToHtml(rawContentState);
  // console.log(markup);
  // console.log(convertToRaw(editorState?.blogData?.blog_description));
  let desc = {};
  try {
    desc = JSON.parse(blogData?.blog_description);
  } catch (error) {
    console.log(error);
  }
  const deleteBlog = async (id) => {
    try {
      const res = await deleteABlog(id);
      if (res?.status == 200) {
        dispatch(showToast({ message: res?.data?.message }));
        onUpdate();
      }
    } catch (e) {
      logError("DELETE Blog", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  // const htmlPuri = draftToHtmlPuri(convertToRaw(blogData.blog_description));
  // Const rawContentState = convertToRaw(
  //   EditorState.createWithContent(
  //     ConvertFromRaw(JSON.parse(blogData?.blog_description))
  //   )
  // );
  // Const markup = draftToHtml(rawContentState);
  return (
    <div className="shadow-ContentCards bg-white rounded-2xl p-4 tab:w-full md:w-3/4 w-full divide-[#9F9F9F] divide-y">
      <div className="grid grid-cols-12 mb-5 gap-2 h-28">
        <div className="col-span-5 h-28">
          {blogData?.blog_image ? (
            <img
              src={blogData?.blog_image}
              className="w-[-webkit-fill-available] h-[94%] object-cover rounded-md"
            />
          ) : (
            <div className="rounded-md bg-gray-200 w-full h-[94%] flex justify-center items-center">
              <small className="text-gray-500">No image</small>
            </div>
          )}
        </div>

        <div className="col-span-5">
          <h1 className="font-normal text-lg line-clamp-1">
            {blogData?.blog_title}
          </h1>

          <div className="overflow-hidden h-16">
            {desc?.blocks?.length > 0 &&
              desc?.blocks?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="text-xs text-[#4E4E4E] font-light line-clamp-4"
                  >
                    {item.text}
                  </p>
                );
              })}
          </div>

          {/* <div
            dangerouslySetInnerHTML={{
              __html: htmlPuri,
            }}
          ></div> */}
          {/* <div className="">
            <div
              dangerouslySetInnerHTML={{
                __html: markup,
              }}
            />
          </div> */}
        </div>
        <div className="col-span-2 flex items-start ">
          <button
            onClick={() => {
              history.push({
                pathname: `/blog/edit/${blogData?.id}`,
              });
            }}
            className="cursor-pointer mr-2 transform transition ease-in-out duration-150 hover:scale-105"
          >
            <BiPencil className="text-2xl text-primary-blue-dark font-medium" />
          </button>
          <button
            onClick={() => {
              deleteBlog(blogData.id);
            }}
            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
          >
            <HiOutlineTrash className="text-2xl text-alert" />
          </button>
        </div>
      </div>
      <div>
        {blogData?.tags ? (
          <BlueChips chipData={blogData?.tags} />
        ) : (
          <div className="mt-3 text-gray-400 italic">No tags</div>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
