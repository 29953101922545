import React, { useEffect, useState } from "react";
import {
  getBadgeTiersList,
  getBizBucksConfig,
  updateBadgeTiers,
} from "config/apis/dashboard.js";
import { BiPencil } from "react-icons/bi";
import BadgesItem from "./BadgesItem";
import GenerateBadgesModal from "./GenerateBadgesModal";
import BizBucksItems from "../SystemBizBucks/BizBucksItems";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

const Badges = ({ onUpdate }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [badgesData, setBadgesData] = useState([]);
  const [bizBucksEventAttendees, setBizBucksEventAttendees] = useState([]);

  const dispatch = useDispatch();

  const sortBadges = (badges) => {
    const order = { Gold: 3, Silver: 2, Bronze: 1 };
    return badges.sort((a, b) => order[a.tier_id] - order[b.tier_id]);
  };

  const handleSave = async (updatedData) => {
    try {
      const res = await updateBadgeTiers(updatedData);
      if (res?.data?.success) {
        dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
        let badges = res?.data?.badgeTiers;
        let sortedbadges = sortBadges([...badges]).reverse();
        setBadgesData(sortedbadges);
      } else {
        console.log("error");
        dispatch(showToast({ message: res.data?.message, type: "error" }));
      }
    } catch (e) {
      console.error("Error updating badge tiers:", e);
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  useEffect(() => {
    getBadgeTiersListAPI();
  }, []);

  const getBadgeTiersListAPI = async () => {
    try {
      const res = await getBadgeTiersList();
      if (res?.data?.success) {
        setBadgesData(res.data.badgeTiers);
        getBizBucksConfigAPI();
      }
    } catch (e) {
      console.error("Error fetching badge tiers:", e);
    }
  };

  const getBizBucksConfigAPI = async () => {
    // setIsLoading(true);
    try {
      const res = await getBizBucksConfig();
      if (res) {
        let config = res.data.config;
        const eventArray = config.filter((item) => item.configType === "event");
        setBizBucksEventAttendees(eventArray);
      }
    } catch (e) {
      logError("getBizBucksConfigAPI : ", e);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className="shadow-ContentCards bg-white rounded-2xl w-[97%] md:p-5 p-3">
      <div className="md:grid-cols-4 grid-cols-4 grid w-full pb-2">
        <div className="flex md:col-span-3 col-span-3">
          <h1 className="text-normal md:text-2xl text-base flex justify-start">
            Badges
          </h1>
        </div>
        <div className="flex md:col-span-1 col-span-1 md:w-[70px] h-[32px] items-center ml-5 justify-center">
          <button
            onClick={() => setModalOpen(true)}
            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
          >
            <BiPencil className="md:text-2xl text-lg text-primary-blue-dark font-medium" />
          </button>
        </div>
      </div>
      <div className="mt-2 h-[20rem] overflow-y-scroll">
        <BadgesItem Badges={badgesData} onUpdate={onUpdate} />
      </div>
      <br />
      <br />
      <span>Event</span>
      <div className="mt-2">
        <BizBucksItems
          BizBucks={bizBucksEventAttendees}
          onUpdate={getBizBucksConfigAPI}
        />
      </div>
      <GenerateBadgesModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        badgesData={badgesData}
      />
    </div>
  );
};

export default Badges;
