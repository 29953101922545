import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import ChipInput from "material-ui-chip-input";
import { MuiChipsInput } from "mui-chips-input";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";

import {
  addPreferenceAPI,
  addTagsAPI,
  companyDataAPI,
  deletePreferenceAPI,
  deleteTagsAPI,
} from "config/apis/Company";

const Tags = ({
  isMine,
  industry1,
  prefs,
  serv,
  company_id,
  getCompanyData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [addTags, setAddTags] = useState([]);
  const [deleteTags, setDeleteTags] = useState([]);

  const [showModalPerf, setShowModalPerf] = useState(false);
  const [Preftags, setPrefTags] = useState([]);
  const [addPrefTags, setAddPrefTags] = useState([]);
  const [deletePrefTags, setDeletePrefTags] = useState([]);

  const handleAddChip = async (tag) => {
    if (!addTags.find((atag) => atag === tag)) {
      setTags([...tags, tag]);
      if (deleteTags.find((dtag) => dtag === tag)) {
        setDeleteTags(deleteTags.filter((dtag) => dtag !== tag));
        return;
      }
      const tagsHistoryList = [...addTags, tag];
      setAddTags(tagsHistoryList);
    }
  };

  const handleAddChipPerf = async (tag) => {
    if (!addPrefTags.find((atag) => atag === tag)) {
      setPrefTags([...Preftags, tag]);
      if (deletePrefTags.find((dtag) => dtag === tag)) {
        setDeletePrefTags(deletePrefTags.filter((dtag) => dtag !== tag));
        return;
      }
      const tagsHistoryList = [...addPrefTags, tag];
      setAddPrefTags(tagsHistoryList);
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setTags(tags.filter((tag) => tag !== chipToDelete));
    if (addTags.find((atag) => atag === chipToDelete)) {
      setAddTags(addTags.filter((atag) => atag !== chipToDelete));
      return;
    }
    const tagsDeleteList = [...deleteTags, chipToDelete];
    setDeleteTags(tagsDeleteList);
  };

  const handleDeleteChipPerf = (chipToDelete) => {
    // console.log("handleDeleteChipPerf ==> ", chipToDelete)
    setPrefTags(Preftags.filter((tag) => tag !== chipToDelete));
    if (addPrefTags.find((atag) => atag === chipToDelete)) {
      setAddPrefTags(addPrefTags.filter((atag) => atag !== chipToDelete));
      return;
    }
    const tagsDeleteList = [...deletePrefTags, chipToDelete];
    setDeletePrefTags(tagsDeleteList);
  };

  const handleTags = async () => {
    setShowModal(false);
    if (addTags.length > 0) {
      addTags.map(async (tag) => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("service_name", tag);
        await addTagsAPI(company_id, urlencoded);
      });
      setAddTags([]);
    }
    if (deleteTags.length > 0) {
      deleteTags.map(async (chipToDelete) => {
        let obj = serv?.find((o) => o.service_name === chipToDelete);
        await deleteTagsAPI(obj.id);
        await companyDataAPI();
      });
      setDeleteTags([]);
    }
    await getCompanyData();
  };

  const handlePreferencesTags = async () => {
    setShowModalPerf(false);
    if (addPrefTags.length > 0) {
      addPrefTags.map(async (tag) => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("industry", tag);
        await addPreferenceAPI(company_id, urlencoded);
      });
      setAddPrefTags([]);
    }
    if (deletePrefTags.length > 0) {
      deletePrefTags.map(async (chipToDelete) => {
        let obj = prefs?.find((o) => o.industry === chipToDelete);
        await deletePreferenceAPI(obj.id);
        await companyDataAPI();
      });
      setDeletePrefTags([]);
    }
    await getCompanyData();
  };

  useEffect(() => {
    let arr = serv?.map((item, i) => {
      return item.service_name;
    });
    setTags(arr);

    let prefsarr = prefs?.map((item, i) => {
      return item.industry;
    });
    setPrefTags(prefsarr);
  }, []);

  return (
    <>
      <div className="profile-tags-section">
        <div className="flex justify-between">
          <div className="flex items-baseline">
            <h1 className="mr-3">Tags</h1>
            <p>Improve your search ability</p>
          </div>
          <div>
            <button className="add-tag-btn" onClick={() => setShowModal(true)}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="profile-tags">
          <div className="profile-tags-each">
            {industry1?.industry_name}
            {isMine && <AiOutlineCloseCircle className="tags-close-icon" />}
          </div>
          {serv?.map((service, ind) => (
            <div className="profile-tags-each" key={ind}>
              {service?.service_name}
              {isMine && (
                <AiOutlineCloseCircle
                  className="tags-close-icon"
                  onClick={() => handleDeleteChip(service?.id)}
                />
              )}
              {isMine && (
                <AiOutlineCloseCircle
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="tags-close-icon"
                />
              )}
            </div>
          ))}
          {/* {serv?.length < 19 && isMine ? (
            <div
              className="profile-tags-add"
              onClick={() => setShowModal(true)}
            >
              Add Tag
            </div>
          ) : (
            isMine && <div className="profile-tags-disable">Add Tag</div>
          )} */}
        </div>
      </div>
      <div className="profile-tags-section">
        <div className="flex justify-between">
          <div className="flex items-baseline">
            <h1 className="mr-3">My Preferences</h1>
            <p>Industries you'd like to be matched to</p>
          </div>
          <div>
            <button
              className="add-tag-btn"
              onClick={() => setShowModalPerf(true)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="profile-tags">
          <div className="profile-tags-each">
            {industry1?.industry_name}
            {isMine && <AiOutlineCloseCircle className="tags-close-icon" />}
          </div>
          {prefs?.map((ind, i) => (
            <div className="profile-tags-each" key={i}>
              {ind?.industry}
              {isMine && (
                <AiOutlineCloseCircle
                  className="tags-close-icon"
                  onClick={() => handleDeleteChipPerf(ind?.id)}
                />
              )}
              {isMine && (
                <AiOutlineCloseCircle
                  onClick={() => {
                    setShowModalPerf(true);
                  }}
                  className="tags-close-icon"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="profile-modal modal-wrapper">
        <Dialog
          style={{
            minWidth: "345px",
            maxWidth: "600px",
            width: "100%",
          }}
          size="sm"
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1>
              Add Tags <span>(upto 10)</span>
            </h1>
          </DialogHeader>
          <DialogBody>
            <div className="mt-4 mb-3">
              <MuiChipsInput
                style={{ margin: "0" }}
                required
                blurBehavior="add"
                value={tags}
                onAddChip={(chip) => {
                  if (tags.length < 10) {
                    handleAddChip(chip);
                  } else {
                    console.log("juihfuih");
                  }
                }}
                fullWidth
                onDeleteChip={(chip) => handleDeleteChip(chip)}
                variant="outlined"
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="save-button">
              <Button
                color="green"
                onClick={(e) => handleTags()}
                variant="gradient"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>

      <div className="profile-modal modal-wrapper">
        <Dialog
          style={{
            minWidth: "345px",
            maxWidth: "600px",
            width: "100%",
          }}
          size="sm"
          open={showModalPerf}
          handler={() => setShowModalPerf(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1>
              Add Industry Preferences <span>(upto 10)</span>
            </h1>
          </DialogHeader>
          <DialogBody>
            <div className="mt-4 mb-3">
              <MuiChipsInput
                style={{ margin: "0" }}
                required
                blurBehavior="add"
                value={Preftags}
                onAddChip={(chip) => {
                  if (Preftags.length < 10) {
                    handleAddChipPerf(chip);
                  } else {
                    console.log("handleAddChipPerf");
                  }
                }}
                fullWidth
                onDeleteChip={(chip) => handleDeleteChipPerf(chip)}
                variant="outlined"
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModalPerf(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="save-button">
              <Button
                color="green"
                onClick={(e) => handlePreferencesTags()}
                variant="gradient"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default Tags;
