// DONE

//Event card for content dashboard

import React, { useState } from "react";
//Mapped Event div for Event card on content dashboard
import Event from "./Event";
//Commonly styled  buttons
import TertiaryButton from "../../../../Common/TertiaryButton";
import Button from "../../../../Common/Button";
import { DelEvent } from "config/apis/Events";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
const EventCard = ({ events, onUpdate }) => {
  const [eventId, setEventId] = useState(null);
  const [openCnf, setOpenCnf] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const deleteEvent = async (eventID) => {
    try {
      const res = await DelEvent(eventID);
      if (res?.status === 200) {
        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE EVENT", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <div className="shadow-ContentCards rounded-2xl w-full bg-white p-5">
      <div className="md:grid-cols-2 grid-cols-3 grid w-full">
        <div className="flex  col-span-1">
          <h1 className="text-normal text-2xl flex   justify-start">Events</h1>
        </div>
        <div className="flex justify-end md:space-x-4 md:col-span-1 space-x-3 col-span-2 items-center">
          <TertiaryButton path="/events" />
          <Button
            onClick={() => {
              history.push({
                pathname: "/events",
                state: { add: true },
              });
            }}
            text="Add Event"
          />
        </div>
      </div>
      <div>
        {events?.length > 0 ? (
          events?.map((item, index) => {
            return (
              <div key={index}>
                <Event
                  item={item}
                  onDelete={() => {
                    setEventId(item?.id);
                    setOpenCnf(true);
                  }}
                  onEdit={() => {
                    history.push({
                      pathname: "/events",
                      state: { id: item?.id, event: item },
                    });
                  }}
                />
              </div>
            );
          })
        ) : (
          <div className="text-gray-400 mt-2">No Events</div>
        )}
      </div>
      <ConfirmationDialog
        open={openCnf}
        setOpen={setOpenCnf}
        handleYes={() => deleteEvent(eventId)}
        message={`Are you sure you want to delete this Event?`}
      />
    </div>
  );
};

export default EventCard;
