import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import DropDownMenuTopTen from "./DropDownMenuTopTen";
import { topTenBusinesssMostNumberOfBizBucksAPI } from "config/apis/dashboard.js";

const TopTenCompaniesWithMostNumbersOfBizBucks = () => {
  const [duration, setDuration] = useState(1);
  const [filter, setFilter] = useState("MONTH");
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(2000); // Default max value

  const fetchData = async () => {
    try {
      let payload = {
        duration,
        type: filter,
      };

      const response = await topTenBusinesssMostNumberOfBizBucksAPI(payload);
      if (response.data.success) {
        const top10Businesses = response.data.top10Businesses;
        setData(top10Businesses);

        // Find the max value in total_biz_bucks
        const maxActivityCount = Math.max(
          ...top10Businesses.map((item) => parseInt(item.total_biz_bucks))
        );

        // Round up to the nearest number divisible by 10
        const nearestDivisibleByTen = Math.ceil(maxActivityCount / 10) * 10;
        setMaxValue(nearestDivisibleByTen);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching top 10 businesses:", error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [duration, filter]);

  return (
    <div className="shadow-ContentCards bg-white h-[32rem] rounded-2xl w-full p-5">
      <div className="flex justify-between flex-wrap 2xl:flex-nowrap items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">BizBucks</p>
          <span> (Top 10 Businesses)</span>

          <div className="flex items-center gap-2">
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#6CE5E7",
                borderRadius: "5px",
              }}
            />
            <span>BizBucks</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <input
              type="number"
              name="duration"
              placeholder="Duration"
              className="my-1 rounded-md w-20"
              value={duration}
              onChange={(e) => setDuration(Math.max(1, Number(e.target.value)))} // No negative values
            />
          </div>
          <div>
            <DropDownMenuTopTen
              options={["MONTH", "YEAR"]}
              selectedOption={filter}
              onSelectOption={setFilter}
            />
          </div>
        </div>
      </div>

      <div style={{ height: "400px", marginTop: "10px" }}>
        <ResponsiveBar
          data={data}
          keys={["total_biz_bucks"]}
          indexBy="company_name"
          margin={{ top: 20, right: 50, bottom: 70, left: 50 }}
          padding={0.1}
          colors={() => "#6CE5E7"}
          axisBottom={{
            tickSize: 5,
            tickPadding: 8,
            tickRotation: -20,
            legend: "",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -40,
            tickValues: [
              0,
              maxValue * 0.25,
              maxValue * 0.5,
              maxValue * 0.75,
              maxValue,
            ],
          }}
          labelSkipWidth={100}
          labelSkipHeight={100}
          yScale={{ type: "linear", min: 0, max: maxValue }}
          gridYValues={[
            0,
            maxValue * 0.25,
            maxValue * 0.5,
            maxValue * 0.75,
            maxValue,
          ]}
          tooltip={({ id, value, color, indexValue }) => (
            <div
              style={{
                padding: "5px 10px",
                color: "#666",
                background: "#fff",
                borderRadius: "3px",
                border: `1px solid ${color}`, // Border matching the bar color
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Adding shadow
              }}
            >
              <strong>{indexValue}</strong>: {value}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default TopTenCompaniesWithMostNumbersOfBizBucks;
