import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import DropDownMenu from "./DropDownMenu";
import SelectOptions from "./SelectOptions";
import { getLoginStats } from "config/apis/dashboard.js";
import {
  fillDays,
  fillMonth,
  numToMonthStrMap,
  strToMonthNumMap,
} from "helpers/defaultValues/dashboard";

const LineGraph = () => {
  const [filter, setFilter] = useState("annual");

  const [option, setOption] = useState();
  const [year, setYear] = useState(new Date().getFullYear());

  const [loginStatistics, setLoginStatistics] = useState([]);
  const filterNewJoiners = (d, tempArr, type = "all") => {
    if (type === "all") {
      return d?.yearlyCounts?.map((item) => {
        return {
          x: item.year,
          y: item.count,
        };
      });
    }

    if (type === "monthly") {
      return tempArr?.map((item) => {
        return {
          x: item?.justDate,
          y: d?.find((a) => a?.justDate == item?.justDate)?.count || 0,
        };
      });
    }

    return tempArr?.map((item) => {
      let month = numToMonthStrMap[item.month] || 0;

      return {
        x: month,
        y: d?.find((a) => a?.month == item?.month)?.count || 0,
      };
    });
  };

  const processData = async () => {
    const month = strToMonthNumMap[option];
    let query = { year, filter };
    if (filter === "monthly") query.month = month;
    const response = await getLoginStats(query);

    let tempArr = response.data.data;

    if (filter == "monthly") {
      tempArr = fillDays(tempArr);
    } else {
      tempArr = fillMonth(tempArr);
    }

    const newJoiners = filterNewJoiners(response.data.data, tempArr, filter);
    console.log("1212", newJoiners);
    setLoginStatistics([
      {
        id: "Login Counts",
        color: "#521EFD",
        data: newJoiners,
      },
    ]);
  };

  useEffect(() => {
    processData();
  }, [filter, option, year]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full p-5 ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">Login Stats</p>
          {/* <p className="text-xs pt-1">{new Date().getFullYear()}</p> */}
        </div>
        <div className="flex items-center gap-4">
          <div>
            <DropDownMenu setFilter={setFilter} setYear={setYear} />
          </div>{" "}
          {filter !== "all" && (
            <div>
              <SelectOptions
                filter={filter}
                setOption={setOption}
                setYear={setYear}
              />
            </div>
          )}
        </div>
      </div>
      <ResponsiveLine
        data={loginStatistics}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 2,
          tickPadding: 2,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        // ... (the rest of the properties are unchanged)
      />
    </div>
  );
};

export default LineGraph;
