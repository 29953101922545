// Components
import DesktopNavigation from "./DesktopNavigation";

/**
 * Displaying Navigation
 */
function AppNavigation() {
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex side-nav lg:flex-col lg:fixed lg:inset-y-0 desktop-side-nav-shadow">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <DesktopNavigation />
      </div>
    </>
  );
}

export default AppNavigation;
