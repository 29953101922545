import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdOutlineClose } from "react-icons/md";

const DeleteConfirmation = ({
  message,
  open,
  setOpen,
  handleClose,
  onConfirm,
}) => {
  return (
    <>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal6">
          <h2
            className="text-center"
            style={{ fontSize: "1.3rem", fontWeight: "600" }}
          >
            {message}
          </h2>
          <br />
          <div className="flex justify-center items-center mt-5">
            <button
              className="rounded-md bg-neutral-gray-500 p-1 px-4 text-white ms-2"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="rounded-md bg-primary-blue-medium p-1 px-4 text-white ms-2"
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConfirmation;
