//Open and close modal for adding new events or editing events

import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
//style sheet
import "assets/styles/scss/_styles/_offlineModal.scss";
import { AddNewEvent, UpdateEvent } from "config/apis/Events";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";

const OfflineEventModal = ({
  offlineModal,
  setOfflineModal,
  open,
  setOpen,
  payload,
  setPayload,
  edit,
  setEdit,
  editEventData,
  setEditEventData,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  //Functions to handle opening and closing of modal

  const handleClose = () => {
    setOfflineModal(false);
    setEdit(false);
  };
  const [initialValuesObject, setInitialValuesObject] = useState({
    streetName: edit ? editEventData.streetName : "",
    subUrb: edit ? editEventData.subUrb : "",
    pincode: edit ? editEventData.pincode : "",
    city: edit ? editEventData.city : "",
  });
  const addEvent = async (eventData) => {
    try {
      const res = await AddNewEvent(eventData);

      if (res.status === 201) {
        let data = res?.data;
        setOfflineModal(false);
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("ADD EVENT", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  const updateEventAPI = async (body, id) => {
    try {
      const res = await UpdateEvent(body, id);

      if (res.status === 200) {
        setEdit(false);
        setOfflineModal(false);
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("UPDATE EVENT", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValuesObject,
    validationSchema: Yup.object({
      streetName: edit
        ? editEventData.eventMode
          ? Yup.string().required("Required")
          : ""
        : Yup.string().required("Required"),
      subUrb: edit
        ? editEventData.eventMode
          ? Yup.string().required("Required")
          : ""
        : Yup.string().required("Required"),
      pincode: edit
        ? editEventData.eventMode
          ? Yup.string().required("Required")
          : ""
        : Yup.string().required("Required"),
      city: edit
        ? editEventData.eventMode
          ? Yup.string().required("Required")
          : ""
        : Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      let location = {
        streetName: edit
          ? values.streetName == ""
            ? editEventData.streetName
            : values.streetName
          : values.streetName,
        subUrb: edit
          ? values.subUrb == ""
            ? editEventData.subUrb
            : values.subUrb
          : values.subUrb,
        pincode: edit
          ? values.pincode == ""
            ? editEventData.pincode
            : values.pincode
          : values.pincode,
        city: edit
          ? values.city == ""
            ? editEventData.city
            : values.city
          : values.city,
      };
      let eventData = { ...payload, ...location };

      setPayload({ ...payload, ...values });
      let eventID = editEventData.id;
      edit ? updateEventAPI(eventData, eventID) : addEvent(eventData);
    },
  });

  return (
    <div>
      <Modal
        open={offlineModal}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box className="box-modal9">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="box-modal9-header">
                {edit ? "Edit event location" : "Add event location"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal9-label">Street Name</Typography>
              <input
                id="streetName"
                name="streetName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                defaultValue={edit ? editEventData.streetName : ""}
                className="box-modal9-field"
              />
              <p className="text-red-600 text-sm">
                {formik.touched.streetName && formik.errors.streetName
                  ? formik.errors.streetName
                  : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              <Typography className="box-modal9-label">Suburb</Typography>
              <input
                id="subUrb"
                name="subUrb"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                defaultValue={edit ? editEventData.subUrb : ""}
                className="box-modal9-field"
              />
              {formik.touched.subUrb && formik.errors.subUrb ? (
                <p className="text-red-600 text-sm">{formik.errors.subUrb}</p>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <Typography className="box-modal9-label">Pincode</Typography>
              <input
                id="pincode"
                name="pincode"
                type="text"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .trimStart()
                    .replace(/[^0-9]/gi, "");
                  if (e.target.value.length > 6) {
                    return;
                  } else {
                    formik.handleChange(e);
                  }
                }}
                defaultValue={edit ? editEventData.pincode : ""}
                className="box-modal9-field"
              />
              {formik.touched.pincode && formik.errors.pincode ? (
                <p className="text-red-600 text-sm">{formik.errors.pincode}</p>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <Typography className="box-modal9-label">City</Typography>
              <input
                id="city"
                name="city"
                onBlur={formik.handleBlur}
                defaultValue={edit ? editEventData.city : ""}
                onChange={formik.handleChange}
                className="box-modal9-field"
              />
              {formik.touched.city && formik.errors.city ? (
                <p className="text-red-600 text-sm">{formik.errors.city}</p>
              ) : null}
            </Grid>
            <Grid item xs={12} className="box-modal9-lastrow">
              <button
                onClick={() => {
                  setOfflineModal(false);
                  setOpen(true);
                }}
                className="box-modal9-lastrow-button1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                Back
              </button>
              <button
                onClick={() => {
                  formik.handleSubmit();
                }}
                type="submit"
                className="box-modal9-lastrow-button2 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
              >
                {edit ? "Update" : "Add"}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default OfflineEventModal;
