//Open and close modal for generating new promo codes
//Open,setOpen are state variables with boolean value for the state of the modal
//Returns a form inside a modal and generates new promo code on submission
import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { MenuItem, Select } from "@mui/material";
//Icons
import { AiOutlineClose } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";
// External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { GeneratePromoCode } from "config/apis/PromoCode";
import logError from "helpers/utils/logErrors";

const GeneratePromoCodeModal = ({ open, setOpen, onUpdate }) => {
  //Variable to store the state for what kind promo code is to generated, subscription credit or advertising credit
  const [subCredit, setSubCredit] = useState(true);
  //Variable to store the states of whether the promo code is generated or not
  const [generated, setGenerated] = useState(false);
  //Variable to store the promo code to be copied on the clipboard
  const [code, setCode] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setGenerated(false);
    console.log(generated);
  };

  const GeneratePromoCodeAPI = async (payload) => {
    try {
      const res = await GeneratePromoCode(payload);

      if (res.status === 200) {
        let data = res?.data?.data;

        setCode(data?.coupon_code);
        setGenerated(true);
        onUpdate();
      } else {
        console.log("error");
      }
    } catch (e) {
      logError("Generate Promo Code", e);
    }
  };

  const formik = useFormik({
    initialValues: {
      discountAmount: "",
      numberOfMonths: "",
      member: "",
      creditType: "",
    },
    validationSchema: Yup.object({
      discountAmount: subCredit ? "" : Yup.string().required("Required"),
      numberOfMonths: subCredit ? Yup.string().required("Required") : "",
      member: Yup.string().required("Required"),
      creditType: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      let payload = {
        type: values.creditType?.includes("subscription")
          ? "Subscription"
          : "Advertisement",
        amount: subCredit ? values.numberOfMonths : values.discountAmount,
        available_for: values.member,
      };
      GeneratePromoCodeAPI(payload);
    },
  });

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal4">
          {console.log(code)}
          {generated ? (
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8} className="box-modal4-middleColumn">
                <div className="box-modal4-middleColumn-icon">
                  <AiFillCheckCircle />
                </div>
                <Typography className="box-modal4-middleColumn-text">
                  Promo code has been generated
                </Typography>
                <div className="box-modal4-middleColumn-codeDiv">
                  <Typography className="box-modal4-middleColumn-codeDiv-code">
                    {code}
                  </Typography>
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(code);
                    setIsCopied(!isCopied);
                  }}
                  className="box-modal4-middleColumn-copyLink"
                >
                  {isCopied ? "Copied" : "Copy promo code"}
                </button>
              </Grid>
              <Grid item xs={2} className="box-modal4-lastColumn">
                <button
                  onClick={() => {
                    setOpen(false);
                    setGenerated(false);
                  }}
                >
                  <AiOutlineClose />
                </button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="box-modal4-header">
                  Generate promo code
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  id="creditType"
                  name="creditType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.creditType}
                >
                  <FormControlLabel
                    value="subscription credit"
                    className="box-modal4-radioLabel"
                    control={
                      <Radio
                        onChange={(event) => {
                          setSubCredit(event.target.checked);
                        }}
                        sx={{
                          color: "#5A5A5A!important",
                          "&.Mui-checked": {
                            color: "#5A5A5A",
                          },
                        }}
                      />
                    }
                    label="Subscription credit"
                  />
                  <FormControlLabel
                    value="advertisements credit"
                    className="box-modal4-radioLabel"
                    control={
                      <Radio
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSubCredit(false);
                          }
                        }}
                        sx={{
                          color: "#5A5A5A!important",
                          "&.Mui-checked": {
                            color: "#5A5A5A",
                          },
                        }}
                      />
                    }
                    label="Advertisements credit"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography className="box-modal4-label">member</Typography>
                <Select
                  id="member"
                  name="member"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.member}
                  className="box-modal4-SelectTextfield"
                  placeholder="hello"
                >
                  <MenuItem
                    value="All members/New Users"
                    className="box-modal4-SelectTextfield-options"
                  >
                    All members/New Users
                  </MenuItem>
                  <MenuItem
                    value="Trial members"
                    className="box-modal4-SelectTextfield-options"
                  >
                    Trial members
                  </MenuItem>
                  <MenuItem
                    value="Monthly membership"
                    className="box-modal4-SelectTextfield-options"
                  >
                    Monthly membership
                  </MenuItem>
                  <MenuItem
                    value="Annual membership"
                    className="box-modal4-SelectTextfield-options"
                  >
                    Annual membership
                  </MenuItem>
                </Select>
                <p className="text-red-600 text-sm">
                  {formik.touched.member && formik.errors.member
                    ? formik.errors.member
                    : null}
                </p>
              </Grid>

              {subCredit ? (
                <Grid item xs={12}>
                  <Typography className="box-modal4-label">
                    Number of months
                  </Typography>
                  <input
                    className="box-modal4-SelectTextfield"
                    placeholder="Enter number of months"
                    id="numberOfMonths"
                    name="numberOfMonths"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.numberOfMonths}
                  />
                  <p className="text-red-600 text-sm">
                    {formik.touched.numberOfMonths &&
                    formik.errors.numberOfMonths
                      ? formik.errors.numberOfMonths
                      : null}
                  </p>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography className="box-modal4-label">
                    Discount Amount
                  </Typography>
                  <input
                    className="box-modal4-SelectTextfield"
                    placeholder="Enter amount"
                    id="discountAmount"
                    name="discountAmount"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.discountAmount}
                  />
                  <p className="text-red-600 text-sm">
                    {formik.touched.discountAmount &&
                    formik.errors.discountAmount
                      ? formik.errors.discountAmount
                      : null}
                  </p>
                </Grid>
              )}

              <Grid item xs={12} className="box-modal4-lastRow">
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="box-modal4-lastRow-button1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    // setGenerated(true);
                  }}
                  className="box-modal4-lastRow-button2 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  Generate
                </button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default GeneratePromoCodeModal;
