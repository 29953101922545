import { instance, getHeader } from "config/apis";

//To get all Blogs
export const GetAllBlogs = (page, search) => {
  return instance.get(
    `blogs/getBlogs?page=${page}&search=${search}`,
    getHeader()
  );
};

//To add Blogs
export const addBlogs = (body) => {
  return instance.post(`blogs/addBlog`, body, getHeader());
};
//To update Blogs
export const updateBlogs = (id, body) => {
  return instance.put(`blogs/updateBlog/${id}`, body, getHeader());
};
//To get Blogs
export const getABlog = (id) => {
  return instance.get(`blogs/getBlogById/${id}`, getHeader());
};

//To delete Blogs
export const deleteABlog = (id) => {
  return instance.delete(`blogs/deleteBlog/${id}`, getHeader());
};
