import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { BsLink } from "react-icons/bs";
import deleteImg from "../../../../assets/illustrations/deleteImg.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { MdAddLink } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import {
  addSocialAPI,
  companyDataAPI,
  deleteSocialAPI,
  updateSocialAPI,
} from "config/apis/Company";
import { TbArrowWaveRightDown } from "react-icons/tb";

const ProfileSocialLinks = ({ isMine, socials, company, getCompanyData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalEditLink, setShowModalEditLink] = useState(false);
  const [checkDefaultLink, setCheckDefaultLink] = useState(false);
  const [showModalAddLink, setShowModalAddLink] = useState(false);
  const [socialName, setSocialName] = useState("");
  const [defaultLinkId, setDefaultLinkId] = useState(null);
  const [editLink, setEditLink] = useState("");
  const [editValue, setEditValue] = useState("");
  const [webside, setWebside] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [linkedIn, setLinkedIn] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [instagram, setInstagram] = useState(false);
  const [socialLink, setSocialLink] = useState("");
  const [updateData, setUpdateData] = useState({
    id: null,
    socialName: "",
    socialLink: "",
  });
  const [soc, setSoc] = useState([
    { id: null, socialName: "Website" },
    { id: null, socialName: "Facebook" },
    { id: null, socialName: "LinkedIn" },
    { id: null, socialName: "Twitter" },
    { id: null, socialName: "Instagram" },
  ]);

  const addOtherLink = () => {
    socials?.map((item) => {
      if (item?.socialName === "Website") {
        setWebside(true);
      }
      if (item?.socialName === "Facebook") {
        setFacebook(true);
      }
      if (item?.socialName === "LinkedIn") {
        setLinkedIn(true);
      }
      if (item?.socialName === "Twitter") {
        setTwitter(true);
      }
      if (item?.socialName === "Instagram") {
        setInstagram(true);
      }
    });
    setShowModal(true);
  };

  const editLinkFunction = (e) => {
    const http = "http://";
    const https = "https://";
    var value = e.target.value;
    if (value.startsWith(http) || value.startsWith(https)) {
      setSocialLink(e.target.value);
    } else {
      setSocialLink(https.concat(e.target.value));
    }

    setEditValue(e.target.value);
  };
  const addSocial = async (e) => {
    setShowModalEditLink(false);
    setShowModalAddLink(false);

    console.log(company);
    await addSocialAPI(company?.id, company?.User?.id, {
      socialName,
      socialLink,
    });
    await getCompanyData();
  };
  const updateSocial = async () => {
    if (checkDefaultLink) {
      setEditLink("");
      const http = "http://";
      const https = "https://";
      if (
        updateData.socialLink.startsWith("http://") ||
        updateData.socialLink.startsWith("https://")
      ) {
        const newData = { ...updateData, id: defaultLinkId };
        setUpdateData(newData);
        await updateSocialAPI(newData);
      } else {
        setUpdateData({
          ...updateData,
          id: defaultLinkId,
        });
        await updateSocialAPI({
          id: defaultLinkId,
          socialName: updateData.socialName,
          socialLink: https.concat(updateData.socialLink),
        });
      }
      await getCompanyData();
      setCheckDefaultLink(false);
    } else {
      setEditLink("");
      const https = "https://";
      if (
        updateData.socialLink.startsWith("http://") ||
        updateData.socialLink.startsWith("https://")
      ) {
        setUpdateData({ ...updateData, id: defaultLinkId });
        await addSocialAPI(company?.id, company?.User?.id, {
          socialName: updateData.socialName,
          socialLink: updateData.socialLink,
        });
      } else {
        setUpdateData({
          ...updateData,
          id: defaultLinkId,
          socialLink: https.concat(updateData.socialLink),
        });
        await addSocialAPI(company?.id, company?.User?.id, {
          socialName: updateData.socialName,
          socialLink: https.concat(updateData.socialLink),
        });
      }
      await getCompanyData();
    }
    setCheckDefaultLink(false);
    setShowModalEditLink(false);
    setShowModalAddLink(false);
  };

  const onChange = (e) => {
    setEditLink(e.target.value);
    setUpdateData({ ...updateData, socialLink: e.target.value });
  };

  const showEditLinkModal = (item) => {
    setUpdateData({
      ...updateData,
      id: item.id,
      socialName: item.socialName,
      socialLink: item.socialLink,
    });
    setShowModal(false);
    if (socials.length > 0) {
      socials.map((data) => {
        if (data.socialName === item.socialName) {
          setEditLink(data.socialLink);
          setDefaultLinkId(data.id);

          setCheckDefaultLink(true);
          setShowModalEditLink(true);
        } else {
          setShowModalEditLink(true);
        }
      });
    } else {
      setShowModalEditLink(true);
    }
  };

  const deleteLink = (item) => {
    if (socials.length > 0) {
      socials.map(async (data) => {
        if (data.socialName === item.socialName) {
          setEditLink(data.socialLink);
          setDefaultLinkId(data.id);
          setCheckDefaultLink(false);
          await deleteSocialAPI(data.id);
          await getCompanyData();
          setShowModalEditLink(false);
          setEditLink("");
          setShowModal(false);
          setWebside(false);
          setFacebook(false);
          setTwitter(false);
          setLinkedIn(false);
          setInstagram(false);
          setEditLink("");
          setShowModalEditLink(false);
        } else {
        }
      });
    } else {
    }
  };

  const showAddLinkModal = (e) => {
    setEditLink("");
    setEditValue("");
    setShowModalAddLink(true);
    setShowModal(false);
  };

  return (
    <>
      <div className="profile-social-links">
        <div className="profile-social-items">
          {socials?.map((item, i) => {
            return (
              <div id={item.id} className="profile-social-item">
                <a
                  href={item.socialLink}
                  target="_blank"
                  className="d-flex"
                  rel="noreferrer"
                >
                  <div className="flex">
                    <div className="profile-social-icon-wrapper">
                      {item.socialName === "Website" ? (
                        <BsLink />
                      ) : item.socialName === "LinkedIn" ? (
                        <FaLinkedin />
                      ) : item.socialName === "Facebook" ? (
                        <FaFacebookF />
                      ) : item.socialName === "Twitter" ? (
                        <FaTwitter />
                      ) : item.socialName === "Instagram" ? (
                        <FaInstagram />
                      ) : (
                        <MdAddLink />
                      )}
                    </div>
                    <div className="profile-social-icon-name">
                      {item.socialName}
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
        {isMine && (
          <div className="profile-social-add-button">
            <button onClick={() => addOtherLink()}>
              Add other link <AiFillPlusCircle />
            </button>
          </div>
        )}
      </div>

      {/* Link Modal */}
      <div className="profile-modal modal-wrapper">
        <Dialog
          style={{
            minWidth: "345px",
            maxWidth: "800px",
            width: "100%",
          }}
          size="md"
          open={showModal}
          handler={() => setShowModal(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1 className="modal-head">Add Social Media</h1>
          </DialogHeader>
          <DialogBody className="max-w-screen">
            <div className="pt-3 link-modal-cont scrollbar">
              <div className="profile-social-item">
                <div
                  className="flex w-380-px"
                  onClick={() => showEditLinkModal(soc[0])}
                >
                  <div className="profile-social-icon-wrapper">
                    <BsLink />
                  </div>
                  <div className="profile-social-icon-name">Website</div>
                </div>
                {webside && (
                  <img
                    src={deleteImg}
                    alt="delete"
                    className="m-r-20px"
                    onClick={() => {
                      deleteLink(soc[0]);
                    }}
                  />
                )}
              </div>
              <div className="profile-social-item">
                <div
                  className="flex w-380-px"
                  onClick={() => showEditLinkModal(soc[1])}
                >
                  <div className="profile-social-icon-wrapper">
                    <FaFacebookF />
                  </div>
                  <div className="profile-social-icon-name">Facebook</div>
                </div>
                {facebook && (
                  <img
                    src={deleteImg}
                    alt="delete"
                    className="m-r-20px"
                    onClick={() => {
                      deleteLink(soc[1]);
                    }}
                  />
                )}
              </div>
              <div className="profile-social-item">
                <div
                  className="flex w-380-px"
                  onClick={() => showEditLinkModal(soc[2])}
                >
                  <div className="profile-social-icon-wrapper">
                    <FaLinkedin />
                  </div>
                  <div className="profile-social-icon-name">LinkedIn</div>
                </div>
                {linkedIn && (
                  <img
                    src={deleteImg}
                    alt="delete"
                    className="m-r-20px"
                    onClick={() => {
                      deleteLink(soc[2]);
                    }}
                  />
                )}
              </div>
              <div className="profile-social-item">
                <div
                  className="flex w-380-px"
                  onClick={() => showEditLinkModal(soc[3])}
                >
                  <div className="profile-social-icon-wrapper">
                    <FaTwitter />
                  </div>
                  <div className="profile-social-icon-name">Twitter</div>
                </div>
                {twitter && (
                  <img
                    src={deleteImg}
                    alt="delete"
                    className="m-r-20px"
                    onClick={() => {
                      deleteLink(soc[3]);
                    }}
                  />
                )}
              </div>
              <div className="profile-social-item">
                <div
                  className="flex w-380-px"
                  onClick={() => showEditLinkModal(soc[4])}
                >
                  <div className="profile-social-icon-wrapper">
                    <FaInstagram />
                  </div>
                  <div className="profile-social-icon-name">Instagram</div>
                </div>
                {instagram && (
                  <img
                    src={deleteImg}
                    alt="delete"
                    className="m-r-20px"
                    onClick={() => {
                      deleteLink(soc[4]);
                    }}
                  />
                )}
              </div>
              {socials?.map((item, i) => {
                return (
                  <>
                    {item.socialName !== "Website" &&
                      item.socialName !== "Twitter" &&
                      item.socialName !== "LinkedIn" &&
                      item.socialName !== "Facebook" &&
                      item.socialName !== "Instagram" && (
                        <div
                          id={item.id}
                          className="profile-social-item"
                          onClick={(e) => {
                            showEditLinkModal(item);
                          }}
                        >
                          <div className="flex w-380-px">
                            <div className="profile-social-icon-wrapper">
                              {item.socialName === "Website" ? (
                                <BsLink />
                              ) : item.socialName === "LinkedIn" ? (
                                <FaLinkedin />
                              ) : item.socialName === "Facebook" ? (
                                <FaFacebookF />
                              ) : item.socialName === "Twitter" ? (
                                <FaTwitter />
                              ) : item.socialName === "Instagram" ? (
                                <FaInstagram />
                              ) : (
                                <MdAddLink />
                              )}
                            </div>
                            <div className="profile-social-icon-name">
                              {item.socialName}
                            </div>
                          </div>
                          <img
                            src={deleteImg}
                            alt="delete"
                            className="m-r-20px"
                            onClick={() => {
                              deleteLink(item);
                              setShowModalEditLink(false);
                            }}
                          />
                        </div>
                      )}
                  </>
                );
              })}
            </div>
            <div className="profile-social-add-button">
              <button
                onClick={() => showAddLinkModal()}
                style={{ justifyContent: "flex-end" }}
              >
                <AiFillPlusCircle />
              </button>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => setShowModal(false)}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div onClick={(e) => setShowModal(false)} className="save-button">
              <Button color="green" variant="gradient">
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>

      {/* Edit Link Modal */}
      <div className="profile-modal modal-wrapper">
        <Dialog
          size="md"
          open={showModalEditLink}
          handler={() => setShowModalEditLink(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1 className="modal-head">Add {updateData.socialName} Link</h1>
          </DialogHeader>
          <DialogBody>
            <div className="editLink-cont">
              <div className="editLink">
                <div className="select-social">
                  <input
                    id={updateData.id}
                    name={updateData.socialName}
                    value={editLink}
                    type="url"
                    placeholder="Paste here"
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => {
                  setShowModalEditLink(false);
                  setShowModal(true);
                  setEditLink("");
                }}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="save-button">
              <Button
                color="green"
                onClick={(e) => updateSocial()}
                variant="gradient"
                name="edit"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>

      {/* Add Link Modal */}
      <div className="profile-modal modal-wrapper">
        <Dialog
          size="md"
          open={showModalAddLink}
          handler={() => setShowModalAddLink(false)}
          className="profile-tailwind-modal"
        >
          <DialogHeader>
            <h1 className="modal-head">Add Link</h1>
          </DialogHeader>
          <DialogBody>
            <div className="editLink-cont">
              <div className="editLink mb-2">
                <div className="select-social">
                  <input
                    id="title"
                    name="title"
                    type="url"
                    value={editLink}
                    placeholder="Title"
                    onChange={(e) => {
                      setSocialName(e.target.value);
                      setEditLink(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="editLink">
                <div className="select-social">
                  <input
                    id="addLink"
                    name="addLink"
                    type="url"
                    value={editValue}
                    placeholder="Paste here"
                    onChange={(e) => {
                      editLinkFunction(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="close-button mr-4">
              <Button
                color="red"
                onClick={(e) => {
                  setShowModalAddLink(false);
                  setShowModal(true);
                }}
                variant="gradient"
              >
                Close
              </Button>
            </div>
            <div className="save-button">
              <Button
                color="green"
                name="other"
                onClick={(e) => {
                  addSocial(e);
                  setEditLink("");
                }}
                variant="gradient"
              >
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default ProfileSocialLinks;
