import React from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

const DeleteAdminModal = ({
  deleteOldAdmin,
  setDeleteOldAdmin,
  setOldAdmin,
  oldAdmin,
}) => {
  const handleOpen = () => setDeleteOldAdmin(true);
  const handleClose = () => {
    setDeleteOldAdmin(false);
  };
  return (
    <div>
      <Modal
        open={deleteOldAdmin}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box className="box-modal8">
          <Grid container>
            <Grid item xs={12} className="box-modal8-middleColumn">
              <Typography className="box-modal8-middleColumn-subtext">
                Delete previous admin to proceed further
              </Typography>
              <button
                onClick={() => {
                  setOldAdmin(false);
                  setDeleteOldAdmin(false);
                }}
                className="box-modal8-middleColumn-delbttn"
              >
                Delete now
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteAdminModal;
