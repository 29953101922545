// DONE
// DO NOT CHANGE THE COMPONENT

//Blue chips for create blog page

//React hooks
import React from "react";
import { useState } from "react";
//External library for form handling and validation
import { useFormik } from "formik";
import * as Yup from "yup";
//Icons
import { TiDeleteOutline } from "react-icons/ti";

const Chips = ({ tags, setTags }) => {
  //Variable to store the state of chips

  //Variable to store the state of input field for adding tag (blue chip)
  const [addTag, setAddTag] = useState(false);
  const formik = useFormik({
    initialValues: {
      label: "",
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      console.log(values);
      setAddTag(false);
      resetForm({ values: "" });
      let newTag =
        tags?.length > 0 ? tags + "," + values?.label : values?.label;
      setTags(newTag);
    },
  });

  return (
    <div className="flex-wrap flex ">
      {tags &&
        tags?.split(",") &&
        tags?.split(",")?.length > 0 &&
        tags?.split(",")?.map((value, index) => (
          <div
            key={index}
            className="bg-primary-blue-medium mt-5 flex w-fit mr-5 items-center p-1 pl-3 pr-3 rounded-2xl text-white"
          >
            {value}
            <button
              onClick={() => {
                console.log(index);
                let arr = tags
                  ?.split(",")
                  .filter((_, index1) => index1 !== index);
                console.log(arr);
                setTags(arr.join(","));
              }}
              className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 pl-1"
            >
              <TiDeleteOutline />
            </button>
          </div>
        ))}

      <form>
        {addTag ? (
          <div className="flex justify-center mt-5 relative">
            <input
              id="label"
              name="label"
              className="border-primary-blue-medium p-1 pl-3 pr-16 rounded-2xl"
              type="text"
              autoFocus={true}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.label}
            />
            <button
              type="submit"
              className="bg-primary-blue-medium text-white text-sm p-0.5 px-2 rounded-full absolute top-1 right-1"
              onClick={formik.handleSubmit}
            >
              Done
            </button>
          </div>
        ) : (
          <div className="border-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 border-[1px] mt-5 flex w-fit items-center p-1 pl-3 pr-3 rounded-2xl text-primary-blue-medium">
            <button onClick={() => setAddTag(true)}>Add tag</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Chips;
