import { instance, getHeader } from "config/apis";

//to admin transfer ownership

export const TransferOwnership = (body) => {
  return instance.post(`v1/transfer`, body, getHeader());
};

//to get super admin  proflie

export const GetSuperAdminInfo = () => {
  return instance.get(`v1/superAdmin/getAdmin`, getHeader());
};
