import React, { useEffect, useState } from "react";
import "./badges.css";
import UserAccordion from "components/PrivatePages/SubscriptionDashboard/userCards/userAccordion";
import { getBusinessByTiers } from "config/apis/dashboard.js";
import logError from "helpers/utils/logErrors";
// import { BiCross, BiWindowClose } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import BadgesUserAccordian from "./badgesUserAccordian";

const GenerateMemberCard = ({ isOpen, onClose, item }) => {
  const [businesses, setBusinesses] = useState([]);

  const _getBusinessByTiers = async () => {
    try {
      const res = await getBusinessByTiers(item?.tier_name?.toLowerCase());
      if (res) {
        let config = res.data;
        if (config?.success) {
          // console.log("config==>>> ", JSON.stringify(item));
          console.log("config==>>> ", JSON.stringify(config?.businesses[0]));
          setBusinesses(config?.businesses); // Update state with the fetched data
        }
      }
    } catch (e) {
      logError("getBizBucksConfigAPI : ", e);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      console.log("Modal is open. Fetching data...", item);
      _getBusinessByTiers();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-container">
          <div className="tab:col-span-1 md:col-span-3 col-span-3 ">
            <div className="modal-header flex md:col-span-5 col-span-3 align-center justify-center">
              <h1 className="text-normal capitalize text-xl center">
                {`${item?.tier_name} Badge Businesses`}
              </h1>
              <div className="flex md:col-span-2 col-span-2 md:w-[50px] h-[50px] items-center ml-5 justify-center">
                <button
                  onClick={() => onClose(true)}
                  className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  <RxCross1 className="md:text-2xl text-lg text-primary-black-dark font-medium" />
                </button>
              </div>
            </div>
            <br />
            {businesses && businesses?.length > 0 ? (
              <div className="shadow-ContentCards bg-white rounded-2xl mx-auto md:w-3/4 w-full tab:w-full p-5">
                {/* <div className="grid-cols-1 grid w-full border-b-2 border-gray-400 p-2"> */}
                <div className="flex-[1_0_0] col-span-2 p-2"></div>
                {/* </div> */}

                {/* Uncomment and use UserAccordion once data fetching is working */}
                <div className="h-[25rem] overflow-y-scroll">
                  <BadgesUserAccordian
                    business={businesses}
                    /* users={data.filter(
                          (l) =>
                            (!search ||
                              (search !== "" &&
                                l?.Company?.company_name
                                  ?.toLowerCase()
                                  .includes(search.toLowerCase()))) &&
                            (!filterCompany ||
                              (filterCompany !== "" &&
                                l?.Company?.company_name
                                  ?.toLowerCase()
                                  .includes(filterCompany.toLowerCase())))
                        )} */
                    // onUpdate={onSave}
                    // unverified={unverified}
                  />
                </div>
              </div>
            ) : (
              <div className="shadow-ContentCards bg-white rounded-2xl mx-auto md:w-3/4 w-full tab:w-full p-5">
                <div className="grid-cols-1 grid w-full p-2">
                  <div className="flex-[1_0_0] col-span-2 p-2">
                    <h1 className="text-normal capitalize text-xl flex center">
                      {"No Businesses Found!"}
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateMemberCard;
