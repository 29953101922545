import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Activity = ({
  isMine,
  company,
  getCompanyData,
  findAllBackedCompany,
  findAllBackedUsCompany,
}) => {
  const [mergedBackedUsData, setMergedBackedUsData] = useState([]);
  const [mergedBackedCompanyData, setMergedBackedCompanyData] = useState([]);

  // Function to merge the arrays
  const mergeArrays = (type, backed_detail, backed_company_detail) => {
    if (type === "findAllBackedUsCompany") {
      return (
        backed_detail &&
        backed_detail
          ?.map((backedDetail) => {
            const companyDetail = backed_company_detail.find(
              (company) => company.id === backedDetail.backer_company_id
            );

            // Merge the companyDetail into backedDetail if a match is found
            return companyDetail ? { ...backedDetail, ...companyDetail } : null;
          })
          .filter((item) => item !== null)
      ); // Remove any items that don't have a matching company
    }

    if (type === "findAllBackedCompany") {
      return (
        backed_detail &&
        backed_detail
          ?.map((backedDetail) => {
            const companyDetail = backed_company_detail.find(
              (company) => company.id === backedDetail.backed_company_id
            );

            // Merge the companyDetail into backedDetail if a match is found
            return companyDetail ? { ...backedDetail, ...companyDetail } : null;
          })
          .filter((item) => item !== null)
      ); // Remove any items that don't have a matching company
    }
  };

  useEffect(() => {
    if (findAllBackedUsCompany) {
      const mergedData = mergeArrays(
        "findAllBackedUsCompany",
        findAllBackedUsCompany.backed_detail,
        findAllBackedUsCompany.backed_company_detail
      );
      setMergedBackedUsData(mergedData);
    }
  }, [findAllBackedUsCompany]);

  useEffect(() => {
    if (findAllBackedCompany) {
      const mergedData = mergeArrays(
        "findAllBackedCompany",
        findAllBackedCompany.backed_detail,
        findAllBackedCompany.backed_company_detail
      );
      setMergedBackedCompanyData(mergedData);
    }
  }, [findAllBackedCompany]);

  return (
    <div className="company-activity-wrapper">
      <h2>Activity</h2>
      <div className="company-activity-box">
        <div className="company-you-backed">
          <h3>Companies you have backed</h3>
          {mergedBackedCompanyData &&
            mergedBackedCompanyData?.map((item, index) => (
              <>
                <div key={index} className="comp-activity-card my-2">
                  <div className="comp-activity-details">
                    <div className="activity-comp-name flex items-center mb-2">
                      <span className="activity-comp-icon mr-2">
                        <img
                          src={
                            item.company_logo ||
                            "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                          } // Fallback if no logo
                          alt={item.company_name}
                        />
                      </span>
                      {item.company_name}
                    </div>
                    <div className="comp-info flex">
                      <p>
                        {item.interaction_type
                          ?.toString()
                          .includes("Other: Enter Free Text")
                          ? item?.free_text
                          : item.interaction_type}
                      </p>
                      {/* <div className="comp-card-controls">
                    <button>
                      <EditIcon sx={{ fontSize: 24 }} />
                    </button>
                    <button>
                      <DeleteIcon sx={{ fontSize: 24 }} />
                    </button>
                  </div> */}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>

        <div className="company-backed-you">
          <h3>Companies that have backed you</h3>
          {mergedBackedUsData &&
            mergedBackedUsData?.map((item, index) => (
              <div key={index} className="comp-activity-card my-2">
                <div className="comp-activity-details">
                  <div className="activity-comp-name flex items-center mb-2">
                    <span className="activity-comp-icon mr-2">
                      <img
                        src={
                          item.company_logo ||
                          "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                        } // Fallback if no logo
                        alt={item.company_name}
                      />
                    </span>
                    {item.company_name}
                  </div>
                  <div className="comp-info flex">
                    <p>
                      {item.interaction_type
                        ?.toString()
                        .includes("Other: Enter Free Text")
                        ? item?.free_text
                        : item.interaction_type}
                    </p>
                    {/* <div className="comp-card-controls">
                    <button>
                      <EditIcon sx={{ fontSize: 24 }} />
                    </button>
                    <button>
                      <DeleteIcon sx={{ fontSize: 24 }} />
                    </button>
                    
                  </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Activity;
