import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const ConfirmationDialog = ({
  open,
  setOpen,
  message,
  handleYes,
  handleNo = () => {},
}) => {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className="box-modal1">
          <Grid container>
            <Grid item xs={12} className="box-modal1-row1">
              <Typography>{message || "Do you want to continue?"}</Typography>
            </Grid>
            <Grid item xs={12} className="box-modal1-row2">
              <button
                onClick={() => {
                  handleYes();
                  handleClose();
                }}
                className="box-modal1-row2-yes"
              >
                Yes
              </button>
              <button
                autoFocus
                onClick={() => {
                  handleNo();
                  handleClose();
                }}
                className="box-modal1-row2-no"
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationDialog;
