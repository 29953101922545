import GeneratePasswordForm from "components/PrivatePages/generatePassword/GeneratePasswordForm";
import { GetAdminInfo } from "config/apis/GenerateNewPasswordAPI";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { useState, useEffect } from "react";

const GeneratePassword = () => {
  const [fetching, setFetching] = useState(false);
  const [adminData, setAdminData] = useState({});
  const dispatch = useDispatch();
  const GetAdminInfoAPI = async () => {
    setFetching(true);
    try {
      const res = await GetAdminInfo();

      if (res?.status === 200) {
        let data = res?.data;
        setAdminData(data);
        console.log(data);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("GET ADMIN INFO", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
    setFetching(false);
  };
  useEffect(() => {
    GetAdminInfoAPI();
  }, []);

  return (
    <div>
      <GeneratePasswordForm adminData={adminData} setAdminData={setAdminData} />
    </div>
  );
};

export default GeneratePassword;
