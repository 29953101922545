// DONE

//Faq component to be mapped on FAQ page
//Individual question card for FAQ page with onclick drop down answer
//Functions to edit or delete an FAQ
import React from "react";
//React hooks
import { useState, useEffect } from "react";

//Icons
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";
import { AiFillCaretDown } from "react-icons/ai";
//MUI accordion component
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//MUI typography
import Typography from "@mui/material/Typography";
//API
import { FAQs, deleteFAQs } from "config/apis/FAQS";
import EditFAQ from "components/PrivatePages/Faqs/EditFaq";
import { useLocation } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";

export default function FaqForFaqPage({ allFAQS, onUpdate }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [faqId, setFaqId] = useState(null);
  const [editFaqID, setEditFaqID] = useState({});
  const [fetching, setFetching] = useState(false);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (location?.state?.id) {
      setEditFaqID(location?.state?.id);
    }
  }, [location?.state?.id]);

  //Function to delete selected FAQ
  const deleteItem = async (id) => {
    setFetching(true);
    try {
      const res = await deleteFAQs(id);

      if (res.status === 200) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );

        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE FAQ", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }

    setFetching(false);
  };

  const handleDelete = () => {
    deleteItem(faqId);
  };

  return (
    <>
      {allFAQS &&
        allFAQS?.length > 0 &&
        allFAQS?.map((item) => {
          if (editFaqID === item?.id) {
            return (
              <EditFAQ
                key={item?.id}
                onUpdate={onUpdate}
                edit={edit}
                setEdit={setEdit}
                editFaq={item}
                setEditFaqID={() => setEditFaqID()}
                onDelete={() => setOpen(true)}
              />
            );
          } else
            return (
              <div id="root" key={item?.id} className="mb-3">
                <Accordion className="shadow-FaqCard rounded-lg bg-white">
                  <AccordionSummary
                    expandIcon={
                      <AiFillCaretDown className="text-2xl text-primary-blue-dark" />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex">
                        <Typography className="font-semibold text-base">
                          {item?.question}
                        </Typography>
                      </div>
                      <div className="flex mr-2">
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditFaqID(item?.id);
                            setEdit(true);
                            setFaqId(item?.id);
                          }}
                          className="cursor-pointer mr-2 transform transition ease-in-out duration-150 hover:scale-105"
                        >
                          <BiPencil className="text-2xl text-primary-blue-dark font-medium" />
                        </button>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            setFaqId(item?.id);
                            setOpen(true);
                          }}
                          className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                        >
                          <HiOutlineTrash className="text-2xl text-alert" />
                        </button>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.entries(item?.answer).map((ans, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Typography className="text-sm font-light">
                            {ans[1]}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
        })}

      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        handleYes={handleDelete}
        message="Are you sure you want to delete this FAQ?"
      />
    </>
  );
}
