// The commented out routes are not being used for now and the associated components are marked as Deprecated ( These are not to be deleted )

import React from "react";
import { Redirect } from "react-router-dom";

// Utils
import {
  DASHBOARD,
  ECOSYSTEM,
  EVENTS,
  NOTIFICATION,
  SERVICEREQUESTS,
  SETTINGS,
  STATS,
  UNVERIFIEDUSERS,
  USERS,
} from "helpers/constants/Navigation/MenuTabs";
import {
  ANALYTICS,
  CONTENT,
  SUBSCRIPTION,
  SYSTEM,
} from "helpers/constants/Common/Admin";

// Authenticated Paths
import Dashboard from "pages/PrivatePages/Dashboard";
import SuperAdminProfile from "pages/PrivatePages/SuperAdminProfile/SuperAdminProfile";
import FaqPage from "pages/PrivatePages/Faqs";
import AddBlog from "pages/PrivatePages/Blogs";
import AllEvents from "pages/PrivatePages/Events/AllEvents";
import SeeBlogs from "pages/PrivatePages/Blogs/SeeBlogs";
import Company from "pages/PrivatePages/Company";
import Users from "pages/PrivatePages/User";
import GeneratePassword from "pages/PrivatePages/generatePassword/GeneratePassword";
import UnverifiedUsers from "pages/PrivatePages/UnverifiedUsers";
import Ecosystem from "pages/PrivatePages/Ecosystem";
import Stats from "pages/PrivatePages/Stats";
import PowerHour from "components/PrivatePages/Events/PowerHour";
import SeeAllNotification from "components/PrivatePages/Events/SeeAllNotification";
import ServiceRequests from "pages/PrivatePages/ServiceRequest";

// Array of routes only a logged in user can access
// The menuTab key should have the value for the tab that needs to be highlighted when the user is on that page
const privateRoutes = [
  // Main Dashboard
  {
    path: "/dashboard",
    component: Dashboard,
    menuTab: DASHBOARD,
    admins: [CONTENT, SUBSCRIPTION, SYSTEM, ANALYTICS],
    isSuperAdmin: true,
  },
  // {
  //   path: "/users",
  //   component: Users,
  //   menuTab: USERS,
  //   admins: [CONTENT, SUBSCRIPTION, SYSTEM, ANALYTICS],
  //   isSuperAdmin: true,
  // },
  {
    path: "/Faqs",
    component: FaqPage,
    admins: [CONTENT],
  },
  {
    path: "/blog/:type/:id",
    component: AddBlog,
    admins: [CONTENT],
  },
  {
    path: "/events",
    component: AllEvents,
    menuTab: EVENTS,
    admins: [CONTENT],
  },
  {
    path: "/power-hour",
    component: PowerHour,
    admins: [CONTENT],
  },
  {
    path: "/notifications",
    component: SeeAllNotification,
    menuTab: NOTIFICATION,
    admins: [CONTENT],
  },
  {
    path: "/blogs",
    component: SeeBlogs,
    admins: [CONTENT],
  },

  // {
  //   path: "/generatePassword",
  //   component: GeneratePassword,
  //   admins: [],
  //   isSuperAdmin: true,
  // },
  {
    path: "/settings",
    component: () =>
      JSON.parse(localStorage.getItem("backboneAdminData"))?.isSuperAdmin ? (
        <SuperAdminProfile />
      ) : (
        <Redirect to="/dashboard" />
      ),
    admins: [CONTENT, SUBSCRIPTION, SYSTEM, ANALYTICS],
    isSuperAdmin: true,
    menuTab: SETTINGS,
  },
  {
    path: "/company/:id",
    component: Company,
    admins: [SUBSCRIPTION],
    isSuperAdmin: true,
  },
  {
    path: "/unverifiedusers",
    component: UnverifiedUsers,
    menuTab: UNVERIFIEDUSERS,
    admins: [SUBSCRIPTION],
    isSuperAdmin: true,
  },
  {
    path: "/ecosystem",
    component: Ecosystem,
    menuTab: ECOSYSTEM,
    admins: [SUBSCRIPTION],
    isSuperAdmin: true,
  },
  {
    path: "/stats",
    component: Stats,
    menuTab: STATS,
    admins: [SUBSCRIPTION],
    isSuperAdmin: true,
  },
  {
    path: "/servicerequests",
    component: ServiceRequests,
    menuTab: SERVICEREQUESTS,
    admins: [SUBSCRIPTION],
    isSuperAdmin: true,
  },
  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("backboneAdmin") ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
