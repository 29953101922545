import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import DropDownMenu from "./DropDownMenu";
import {
  newJoinerData,
  strToMonthNumMap,
} from "helpers/defaultValues/dashboard";
import SelectOptions from "./SelectOptions";
import { getCustomerAnalytics } from "config/apis/dashboard.js";

const LineGraph = () => {
  const [filter, setFilter] = useState("annual");
  const [data, setData] = useState(newJoinerData);
  const [option, setOption] = useState();
  const [year, setYear] = useState(new Date().getFullYear());

  const filterNewJoiners = (d, type = "all") => {
    if (type === "all") {
      return d?.yearlyCounts?.map((item) => {
        return {
          x: item.year,
          y: item.count,
        };
      });
    }

    if (type === "monthly") {
      return d?.oneMonthCounts?.map((item) => {
        return {
          x: item.day,
          y: item.count,
        };
      });
    }

    return d?.monthlyCounts?.map((item) => {
      return {
        x: item.month,
        y: item.count,
      };
    });
  };

  const fetchData = async () => {
    try {
      const month = strToMonthNumMap[option];
      let query = { year, filter };
      if (filter === "monthly") query.month = month;
      const response = await getCustomerAnalytics(query);

      const newJoiners = filterNewJoiners(response.data.data, filter);
      setData([
        {
          id: "New Joiners",
          color: "#521EFD",
          data: newJoiners,
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    if (filter === "all") setOption(undefined);
  }, [filter, option, year]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full p-5 ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">New customers</p>
          {/* <p className="text-xs pt-1">{new Date().getFullYear()}</p> */}
        </div>
        <div className="flex items-center gap-4">
          <div>
            <DropDownMenu setFilter={setFilter} setYear={setYear} />
          </div>{" "}
          {filter !== "all" && (
            <div>
              <SelectOptions
                filter={filter}
                setOption={setOption}
                setYear={setYear}
              />
            </div>
          )}
        </div>
      </div>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 2,
          tickPadding: 2,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        // legends={[
        //   {
        //     anchor: "bottom-right",
        //     direction: "column",
        //     justify: false,
        //     translateX: 100,
        //     translateY: 0,
        //     itemsSpacing: 0,
        //     itemDirection: "left-to-right",
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemOpacity: 0.75,
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     symbolBorderColor: "rgba(0, 0, 0, .5)",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemBackground: "rgba(0, 0, 0, .03)",
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </div>
  );
};

export default LineGraph;
