import { newJoinerData, renewalData } from "helpers/defaultValues/dashboard";
import React, { useEffect, useState } from "react";
import Renewals from "./Renewals";
import SignUps from "./SignUps";
import { signUpData } from "helpers/defaultValues/dashboard";
import TopBusiness from "./TopBusiness";
import WordCloudCard from "./WordCloud";
import LineGraph from "./LineGraph";
import PieChartCard from "./PieChartCard";
import LoginStat from "./LoginCount";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import TopTenActivity from "./TopTenActivity";
import TopTenCompaniesWithMostNumbersOfBizBucks from "./TopTenCompaniesWithMostNumbersOfBizBucks";

/**
 * Analytics Admin Dashboard View
 * @returns { Component }
 */

const pieData1 = [
  { id: "Gold", label: "Gold", value: 10, color: "255, 203, 118" },
  { id: "Silver", label: "Silver", value: 60, color: "#D9D9D8" },
  { id: "Bronze", label: "Bronze", value: 30, color: "#D16868" },
];

function AnalyticsAdmin({ analyticsData }) {
  const [newJoiners, setNewJoiners] = useState(newJoinerData);
  const [renewals, setRenewals] = useState(renewalData);
  const [signUp, setSignUp] = useState(signUpData);
  const [searchCountTrends, setSearchCountTrends] = useState();
  const [mostUsedFeatures, setMostUsedFeatures] = useState();
  const [referringBusiness, setReferringBusiness] = useState();

  useEffect(() => {
    setNewJoiners(analyticsData?.newJoiners);
    setRenewals(analyticsData?.renewalUsers);
    setSignUp(analyticsData?.signUps);
    setSearchCountTrends(analyticsData?.searchCountTrends);
    setReferringBusiness(analyticsData?.referringBusiness);
    setMostUsedFeatures(analyticsData?.mostUsedFeatures);
  }, [analyticsData]);

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
      <div>
        <WordCloudCard
          cardData={searchCountTrends}
          cardType="searchCountTrends"
        />
      </div>
      <div>
        <LineGraph cardData={newJoiners} cardType="newJoiners" />
      </div>

      {/* <div>
        <LoginStat />
      </div> */}

      <div>
        <TopBusiness
          cardData={referringBusiness}
          cardType="referringBusiness"
        />
      </div>
      <div>
        <LoginStat />{" "}
        {/* <Renewals cardData={renewals} cardType="renewals" /> */}
      </div>
      <div>
        <SignUps cardData={signUp} cardType="signUps" />
      </div>
      <div>
        <PieChartCard cardData={mostUsedFeatures} cardType="mostUsedFeatures" />
      </div>

      <div>
        <TopTenActivity />
      </div>

      <div>
        <TopTenCompaniesWithMostNumbersOfBizBucks />
      </div>
    </div>
  );
}

export default AnalyticsAdmin;
