// TODO: Add search functionality

//Blog list page to view all blogs along with pagination
import React, { useState, useEffect } from "react";
//Image imports
import BlogIMG from "assets/BlogPageImages/BlogIMG.png";
//Components for blog list page
import BlogCard from "components/PrivatePages/Blogs/BlogList/BlogCard";
import Pagination from "components/Common/Pagination";
import { GetAllBlogs } from "config/apis/Blogs";
import { data } from "autoprefixer";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { useHistory } from "react-router-dom";
const SeeBlogs = () => {
  const history = useHistory();
  const [blogPageNo, setBlogPageNo] = useState(1);
  const [fetching, setFetching] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [search, setSearch] = useState("");
  const [blogData, setBlogData] = useState({
    chips: ["branding", "branding", "branding"],
  });
  const dispatch = useDispatch();
  const getBlogs = async () => {
    setFetching(true);
    try {
      const res = await GetAllBlogs(blogPageNo, search);

      if (res.status === 200) {
        let data = res?.data?.blogs;
        console.log(res);
        setTotalPages(Math.ceil(res?.data?.totalBlogsCount / 9));

        setBlogData(data);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("GET BLOGS", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
    setFetching(false);
  };

  useEffect(() => {
    getBlogs();
  }, [blogPageNo, search]);

  return (
    <div>
      <div className=" mb-10 mt-3.5 grid grid-cols-12 gap-5">
        <div className="tab:col-span-7 md:col-span-7 col-span-12">
          <h1 className="font-medium text-2xl">My Blogs</h1>
        </div>
        <div className="tab:col-span-3 md:col-span-5 col-span-12">
          <div className="flex md:justify-end  justify-start ">
            <input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="md:w-64 w-full pl-5 h-[40px] rounded-tab"
              placeholder="Search by keyword"
            />
          </div>
        </div>
        <div className="tab:col-span-2 md:col-span-12 col-span-12 md:flex md:justify-end p  ">
          <button
            onClick={() => {
              history.push({
                pathname: "/blog/create/0",
              });
            }}
            className="bg-primary-blue-medium text-white w-48 h-10 rounded-lg font-bold text-xl"
          >
            Add Blog
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 tab:grid-cols-3 tab:space-x-3 space-x-0 gap-5">
        {blogData?.length > 0 &&
          blogData?.map((item, index) => {
            return (
              <div key={index} className="col-span-1 flex justify-center">
                <BlogCard blogData={item} onUpdate={getBlogs} />
              </div>
            );
          })}
      </div>
      {totalPages > 0 ? (
        <div className="mt-8">
          <Pagination
            pageNo={blogPageNo}
            setPageNo={setBlogPageNo}
            totalPages={totalPages}
          />
        </div>
      ) : (
        <div className="text-center text-gray-400 mt-2">No Blogs</div>
      )}
    </div>
  );
};

export default SeeBlogs;
