import React from "react";

function Loader() {
  return (
    <div
      className="w-full h-full fixed top-0 left-0 z-[2000] flex items-center justify-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="w-12 h-12 border-4 border-white border-b-transparent rounded-full box-border animate-spin"></div>
    </div>
  );
}

export default Loader;
