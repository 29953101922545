import Divider from "components/Common/Divider";
import AnalyticsAdmin from "../Analytics";
import ContentAdmin from "../Conetnt";
import SubscriptionAdmin from "../Subscription";
import SystemAdmin from "../System";
import Admins from "./Admins";
import Button from "components/Common/Button";
import { useState } from "react";
import AddEcosystemModal from "components/PrivatePages/SubscriptionDashboard/modals/AddEcosystem";

/**
 * Super Admin Dashboard View
 * @params { Object } data
 * @returns { Component }
 */
function SuperAdmin({ data, onUpdate }) {
  const [addEco, setAddEco] = useState(false);
  const getAdminsList = () => {
    let l = [];
    let obj = { type: "Content", admins: data?.contentAdmins };
    l.push(obj);

    obj = { type: "Subscription", admins: data?.subscriptionAdmins };
    l.push(obj);

    obj = { type: "System", admins: data?.systemAdmins };
    l.push(obj);

    obj = { type: "Analytical", admins: data?.analyticsAdmins };
    l.push(obj);

    return l;
  };

  return (
    <>
      <AddEcosystemModal
        isOpen={addEco}
        closeModal={() => {
          setAddEco(false);
        }}
        onUpdate={onUpdate}
      />
      <div className="w-full flex flex-col space-y-10">
        <Admins adminData={getAdminsList()} onUpdate={onUpdate} />
        <Divider />
        <div className="w-full flex flex-col space-y-4">
          <h1 className="text-xl md:text-4xl font-poppins font-normal">
            Content Admin
          </h1>
          <ContentAdmin
            onUpdate={onUpdate}
            faqs={data?.faq || []}
            events={data?.events || []}
            blogs={data?.blogs || []}
          />
        </div>
        <Divider />
        <div className="w-full flex flex-col space-y-4">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-xl md:text-4xl font-poppins font-normal">
              Subscription Admin
            </h1>

            <Button
              onClick={() => {
                setAddEco(true);
              }}
              text="Add Ecosystem"
            />
          </div>
          <SubscriptionAdmin
            onUpdate={onUpdate}
            users={data?.users || []}
            ecosystems={data?.ecosystems || []}
            subscriptions={data?.subscriptions || []}
            unverified={data?.unverified || []}
            unassigned={data?.unassigned || []}
          />
        </div>
        <Divider />
        {/* <div className="w-full flex flex-col space-y-4">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-xl md:text-4xl font-poppins font-normal">
              Unverified Users
            </h1>
            <Button text="Export as CSV" />
          </div> */}

        {/* </div> */}

        <div className="w-full flex flex-col space-y-4">
          <h1 className="text-xl md:text-4xl font-poppins font-normal">
            Research & Analytics Admin
          </h1>
          <AnalyticsAdmin analyticsData={data?.analytics} />
        </div>
        <Divider />
        <div className="w-full flex flex-col space-y-4">
          <h1 className="text-xl md:text-4xl font-poppins font-normal">
            System Admin
          </h1>
          <SystemAdmin promocodes={data?.promocodes} onUpdate={onUpdate} />
        </div>
      </div>
    </>
  );
}

export default SuperAdmin;
