import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import _ from "lodash";
import "./AutocompleteCompanySearchComponent.css";
import { getAllCompanies } from "config/apis/Events";

const AutocompleteCompanySearchComponent = ({
  formik,
  fieldName,
  onSelectCompany,
  editEventData,
}) => {
  // console.log("formik.values[fieldName] ==> ", formik.values[fieldName])
  const [search, setSearch] = useState(
    formik.values[fieldName]?.companyName || ""
  );
  const [companyList, setCompanyList] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showCompaniesList, setShowCompaniesList] = useState(false);
  const [isEditEventDataHandled, setIsEditEventDataHandled] = useState(false);

  const debounceFetchCompanies = useCallback(
    _.debounce(async (searchText) => {
      if (searchText.length > 0) {
        try {
          const response = await getAllCompanies(searchText);
          if (response?.data?.success) {
            const companies = response?.data?.company?.filter(
              (company) => company.User !== null && company.User !== undefined
            );
            setCompanyList(companies);
          } else {
            setCompanyList([]);
            setShowCompaniesList(false);
          }
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      } else {
        setCompanyList([]);
        setShowCompaniesList(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    debounceFetchCompanies(search);
  }, [search, debounceFetchCompanies]);

  useEffect(() => {
    if (editEventData && editEventData.company_id && !isEditEventDataHandled) {
      const fetchAndSetCompany = async () => {
        try {
          const response = await getAllCompanies("");
          if (response?.data?.success) {
            const companies = response?.data?.company?.filter(
              (company) => company.User !== null && company.User !== undefined
            );
            setCompanyList(companies);
            const selectedCompany = companies.find(
              (company) => company.id === editEventData.company_id
            );
            if (selectedCompany) {
              setSearch(selectedCompany.company_name);
              formik.setFieldValue(fieldName, selectedCompany.company_name);
              formik.setFieldValue("companyId", selectedCompany.id);
              formik.setFieldValue("userId", selectedCompany.User.id);
            }
            setIsEditEventDataHandled(true);
          }
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      };
      fetchAndSetCompany();
    }
  }, [editEventData, formik, fieldName, isEditEventDataHandled]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    formik.setFieldValue(fieldName, e.target.value);
    setShowCompaniesList(true);
  };

  const handleCompanyClick = (company) => {
    setSearch(company.company_name);
    formik.setFieldValue(fieldName, company.company_name);
    formik.setFieldValue("companyId", company.id);
    formik.setFieldValue("userId", company.User.id);
    setShowCompaniesList(false);
    setFilteredCompanies([]);
    // if (onError) onError(""); // Clear the error when a valid company is selected

    if (onSelectCompany) {
      onSelectCompany({
        companyId: company.id,
        userId: company.User.id,
        companyName: company.company_name,
      });
    }
  };

  /*  const validateCompanyName = () => {
    const selectedCompany = companyList.find(company => company.company_name === search);
    if (!selectedCompany && onError) {
      onError("Please select a valid company from the list.");
    }
  }; */

  /* const handleBlur = () => {
    validateCompanyName();
  }; */

  useEffect(() => {
    setFilteredCompanies(
      companyList.filter((company) =>
        company.company_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [companyList, search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".auto-complete")) {
        setShowCompaniesList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="auto-complete">
      <input
        type="text"
        value={search}
        onChange={handleSearchChange}
        // onBlur={handleBlur}
        placeholder="Search company..."
        className="box-modal-field"
        onFocus={() => !editEventData?.company_id && setShowCompaniesList(true)}
      />
      {showCompaniesList && filteredCompanies.length > 0 && (
        <ul className="auto-complete-list">
          {filteredCompanies.map((company) => (
            <li
              key={company.id}
              onClick={() => handleCompanyClick(company)}
              className="auto-complete-item"
            >
              {company.company_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteCompanySearchComponent;
