import React from "react";
import AdminCard from "./AdminCard";
const Admins = ({ adminData, onUpdate }) => {
  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
      {adminData?.length > 0 &&
        adminData?.map((item, index) => {
          return (
            <div key={index}>
              <h1 className="text-2xl mb-2 font-normal">{item?.type}</h1>
              <div>
                {item?.admins?.length > 0 &&
                  item?.admins?.map((item, i) => {
                    return (
                      <AdminCard key={i} onUpdate={onUpdate} admin={item} />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Admins;
