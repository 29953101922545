//Pagination component for blog list and event list page

//React hooks
import React, { useState, useEffect } from "react";
//Icons
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { change } from "redux/navigation";

const Pagination = ({ pageNo, setPageNo, totalPages }) => {
  //Variable to store and set the state of the page no

  const changePage = (item) => {
    setPageNo(item);
  };
  const arr = Array.from({ length: totalPages }, (_, index) => index + 1);
  useEffect(() => {
    changePage(pageNo);
  }, [pageNo]);

  return (
    <div className="grid grid-cols-12 w-full mt-5">
      <div className="col-span-3 flex justify-center">
        <button
          disabled={pageNo == 1 ? true : false}
          onClick={() => setPageNo(pageNo - 1)}
          className="flex justify-around items-center bg-white rounded-md w-[120px]   pt-2 pb-2"
        >
          <AiOutlineLeft />
          <p>Previous</p>
        </button>
      </div>
      <div className="col-span-6 flex justify-center items-center">
        {arr?.length &&
          arr?.map((items, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  changePage(items);
                }}
                className={
                  pageNo == items
                    ? "text-[#01579B] bg-white rounded-md ml-3 mr-3 p-1 pl-3 pr-3 text-2xl hidden md:block font-normal"
                    : "text-[#A4A4A4] font-normal text-xl ml-3 mr-3 hidden md:block"
                }
              >
                {items}
              </button>
            );
          })}
      </div>
      <div className="col-span-3   flex justify-center">
        <button
          disabled={pageNo == arr.length ? true : false}
          onClick={() => setPageNo(pageNo + 1)}
          className="flex justify-around items-center  bg-white rounded-md w-[120px] pt-2 pb-2"
        >
          <p className="text-base font-normal">Next</p>
          <AiOutlineRight />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
