//Promo code card for system dashboard
import React, { useState } from "react";
//Mapped promo codes for promo code card on system dashboard
import PromoCodes from "./PromoCodes";
//Commonly styled  buttons
import TertiaryButton from "../../Common/TertiaryButton";
import GeneratePromoCodeModal from "./GeneratePromoCodeModal";

const PromoCodeCard = ({ promocodes, onUpdate }) => {
  //Variable to store the state of the modal for generating promo code
  const [open, setOpen] = React.useState(false);

  return (
    <div className="shadow-ContentCards bg-white rounded-2xl w-[97%] md:p-5 p-3">
      <div className="md:grid-cols-12 grid-cols-5 grid w-full pb-2">
        <div className="flex md:col-span-5 col-span-3">
          <h1 className="text-normal md:text-2xl text-base flex justify-start">
            Promo Codes
          </h1>
        </div>
        <div className="flex md:col-span-2 col-span-2 md:pr-4 justify-end md:space-x-4 space-x-2 items-center">
          {/* <TertiaryButton /> */}
        </div>
        <div className="md:space-x-4 col-span-5 mt-2 md:mt-0 md:col-span-5 relative">
          <button
            className="bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-[7.5rem] w-[6rem] md:h-10 h-7 rounded-lg font-normal text-sm absolute right-0 top-0"
            onClick={() => {
              setOpen(true);
            }}
          >
            Generate
          </button>
          {/* <button className="md:w-[7.5rem] w-[6rem] text-sm border-[1px] cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 border-primary-blue-medium text-primary-blue-medium md:h-10 h-7 rounded-md">
            Share
          </button> */}
        </div>
      </div>
      <div className="mt-2 h-[34rem] overflow-y-scroll">
        <PromoCodes promoCodes={promocodes} />
      </div>
      <div>
        <GeneratePromoCodeModal
          open={open}
          setOpen={setOpen}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

export default PromoCodeCard;
