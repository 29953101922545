import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const EditRegistrationFormPopup = ({
  open,
  onClose,
  onSave,
  registrationId,
  companyName,
  memberDetails,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const handleSave = () => {
    const formData = {
      registration_id: registrationId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      job_title: jobTitle,
      company_name: companyName,
    };
    onSave(formData);
    onClose(); // Close the dialog after saving
  };

  useEffect(() => {
    setFirstName(memberDetails?.first_name);
    setLastName(memberDetails?.last_name);
    setEmail(memberDetails?.email);
    setPhoneNumber(memberDetails?.phone_number);
    setJobTitle(memberDetails?.job_title);
  }, [memberDetails]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Registration</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Job Title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Company Name"
          value={companyName}
          disabled
          fullWidth
          margin="dense"
          InputProps={{
            sx: {
              color: "black", // Change the text color
              backgroundColor: "#ffffff", // Change background color
              "& .Mui-disabled": {
                color: "#000000", // Text color when disabled
                WebkitTextFillColor: "#000000", // Ensures color change in WebKit browsers
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRegistrationFormPopup;
