import { useEffect } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { updateBizBucksConfig } from "config/apis/dashboard.js";
import { useFormik } from "formik";
import logError from "helpers/utils/logErrors";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

const GenerateBizBuckModal = ({ item, setItem, open, setOpen, onRefresh }) => {
  const dispatch = useDispatch();

  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setItem(null);
  };

  const addBizbucks = async (payload) => {
    try {
      const res = await updateBizBucksConfig(payload);
      if (res?.data?.success) {
        payload?.key === "attend_an_event" && localStorage.setItem("attend_an_event", payload?.value);
        handleClose();
        dispatch(showToast({ message: res.data?.message })); // Dispatch toast here
        onRefresh();
      } else {
        console.log("error");
        dispatch(showToast({ message: res.data?.message, type: "error" }));
      }
    } catch (e) {
      logError("addBizbucks ==> ", e);
      dispatch(showToast({ message: e?.message, type: "error" }));
    }
  };

  const formik = useFormik({
    initialValues: {
      key: item?.configKey || "",
      label: item?.configLabel || "",
      value: item?.configValue || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      key: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // console.log("onSubmit ==> ", JSON.stringify(values));
      const payload = {
        key: values.key,
        value: values.value,
      };
      addBizbucks(payload);
    },
  });

  useEffect(() => {
    if (item) {
      formik.setValues({
        key: item?.configKey,
        label: item?.configLabel,
        value: item?.configValue,
      });
    }
  }, [item]);

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal4">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="box-modal4-label">Name</Typography>
                <input
                  className="box-modal4-SelectTextfield"
                  placeholder="Enter key"
                  id="key"
                  name="key"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.label}
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.key && formik.errors.key
                    ? formik.errors.key
                    : null}
                </p>
              </Grid>

              <Grid item xs={12}>
                <Typography className="box-modal4-label">
                  {item?.configKey === "number_of_event_attendees"
                    ? "Maximum Number of Attendees"
                    : "BizBucks"}
                </Typography>
                <input
                  className="box-modal4-SelectTextfield"
                  placeholder="Enter value"
                  id="value"
                  name="value"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.value}
                />
                <p className="text-red-600 text-sm">
                  {formik.touched.value && formik.errors.value
                    ? formik.errors.value
                    : null}
                </p>
              </Grid>

              <Grid item xs={12} className="box-modal4-lastRow">
                <button
                  onClick={() => {
                    setOpen(false);
                    setItem(null);
                  }}
                  className="box-modal4-lastRow-button1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  /* onClick={() => {
                    formik.handleSubmit();
                  }} */
                  className="box-modal4-lastRow-button2 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default GenerateBizBuckModal;
