import { sendVerificationMailAPI } from "config/apis/UserAPIs";
import { dashboardData } from "config/apis/dashboard.js";
import React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { HiTrash } from "react-icons/hi";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showToast } from "redux/toaster";
import ConfirmRemoveModal from "components/PrivatePages/SubscriptionDashboard/modals/ConfirmRemoveModal";
import Button from "components/Common/Button";
import Loader from "components/Common/Loader";
const UnverifiedUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [filter, setFilter] = useState(4);
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [open, setOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const [updateUserStatusID, setUpdateUserStatusID] = useState("");
  const [unverified, setUnverified] = useState();
  const filters = [
    {
      id: 1,
      name: "Last Month",
    },
    {
      id: 2,
      name: "Last Two Months",
    },
    {
      id: 3,
      name: "Prior to two months",
    },
    {
      id: 4,
      name: "All",
    },
  ];
  const sendVerificationMail = async (id) => {
    const body = { id: id };
    try {
      const res = await sendVerificationMailAPI(body);
      dispatch(
        showToast({
          message: res?.data?.message,
          type: "success",
        })
      );
      getDashboardData();
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
    console.log("Verification Mail Sent");
  };
  const filterData = (user) => {
    let string = user?.firstName + " " + user?.lastName;
    string = string.toLowerCase();
    if (
      filter == 1 &&
      string.includes(name) &&
      moment().diff(moment(user?.createdAt), "months") <= 1
    ) {
      console.log();
      return user;
    } else if (
      filter == 2 &&
      string.includes(name) &&
      moment().diff(moment(user?.createdAt), "months") == 2
    ) {
      return user;
    } else if (
      filter == 3 &&
      string.includes(name) &&
      moment().diff(moment(user?.createdAt), "months") > 2
    ) {
      return user;
    } else if (filter == 4 && string.includes(name)) {
      return user;
    }
  };

  const downloadCSV = (event, done) => {
    setLoading(false);
    const data = unverified
      ?.filter((user) => filterData(user))
      ?.map((user) => [
        user?.firstName + " " + user?.lastName,
        user?.email,
        user?.mobileNo,
      ]);
    data.unshift(["Name", "Email", "Mobile Number"]);
    setDownloadData(data);

    setLoading(true);
    done();
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardData();
      if (res?.data?.result) {
        setUnverified(res?.data?.result?.unverified || []);
      }
    } catch (e) {
      logError("Dashboard", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
      if (e?.response?.status == 401) {
        localStorage.removeItem("backboneAdmin");
        history.push("/");
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="w-full flex flex-col space-y-4 p-6">
      {isLoading && <Loader />}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl md:text-4xl font-poppins font-normal">
          Unverified Users
        </h1>
        <CSVLink data={downloadData} asyncOnClick={true} onClick={downloadCSV}>
          <Button text="Export as CSV" />
        </CSVLink>
      </div>
      <div>
        <input
          className="rounded-md min-w-full md:min-w-0 my-3"
          placeholder="Enter Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value.toLowerCase())}
        />
        <select
          name="month"
          id="month"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
            console.log(e.target.value);
          }}
          className="ml-0 md:ml-6 rounded-md w-full md:w-auto"
        >
          {filters.map((f) => (
            <option value={f.id}>{f.name}</option>
          ))}
        </select>
      </div>
      <div className="h-vh overflow-y-scroll">
        <table className="table-auto w-full text-md text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="break-words ">
              <th className="p-2 text-base">Name</th>
              <th className="p-2 text-base">Email</th>
              <th className="p-2 text-base">Phone Number</th>
              <th className="p-2 text-base">Send Reminder Email</th>
              <th className="p-2 text-base">Delete</th>
            </tr>
          </thead>
          <tbody>
            {unverified
              ?.filter((user) => filterData(user))
              ?.map((user) => (
                <tr className=" break-words bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="p-2">
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td className="p-2">{user?.email}</td>
                  <td className="p-2">{user?.mobileNo}</td>
                  <td className="p-2">
                    <div
                      className="text-blue-400 underline underline-offset-1 cursor-pointer"
                      onClick={() => {
                        sendVerificationMail(user?.id);
                      }}
                    >
                      <h1 className="text-base text-primary-blue-dark">
                        {user?.requestSent ? "Resend" : "Send"}
                      </h1>
                    </div>
                  </td>
                  <td className="p-2">
                    <div>
                      <button
                        className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                        onClick={() => {
                          setRemove(true);
                          setUpdateUserStatusID(user?.id);
                        }}
                      >
                        <HiTrash className="text-2xl text-red-500" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ConfirmRemoveModal
        open={remove}
        setOpen={setRemove}
        id={updateUserStatusID}
        onUpdate={getDashboardData}
      />
    </div>
  );
};

export default UnverifiedUsers;
