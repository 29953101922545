import React, { useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { MdInfo } from "react-icons/md";
import EventFillModal from "./EventFillModal";
import { useState } from "react";
import SlotInfoModal from "./SlotInfoModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import { removeSpotlightMember } from "config/apis/Events";
import DeleteConfirmation from "./DeleteConfirmation";
import { showToast } from "redux/toaster";

const EventSlots = ({ eventDate, eventId, data, onUpdate }) => {
  console.log("EventSlots ==> ", JSON.stringify(eventDate));

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [RequestData, setRequestData] = useState();
  const [spotlightRequests, setSpotlightRequests] = useState([]);

  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setRequestData();
  };

  const handleClose2 = () => {
    setOpen2(false);
    setRequestData();
  };

  useEffect(() => {
    setSpotlightRequests(data);
  }, [data]);

  const deleteSpotlightMember = async (request) => {
    try {
      const res = await removeSpotlightMember(request?.request_id);

      if (res.data?.success) {
        onUpdate();
        handleCloseDelete();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        handleCloseDelete();
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      console.log("response eroro ==> : ", JSON.stringify(e));
      logError("Add Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const renderSpotlightSlots = () => {
    const approvedRequests =
      spotlightRequests?.filter((request) => request.status === "approved") ||
      [];

    if (approvedRequests.length === 0) {
      // Case 3: No approved items, show the placeholder component twice
      return (
        <>
          <div className="slot-list max-w-md my-3">
            <div className="event-slot-box flex gap-3 items-start bg-neutral-gray-100 p-3 rounded-md">
              <div className="slot-details">
                <h2 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  Business Spotlight{" "}
                  <span className="slot-time">{`${moment(eventDate)?.format(
                    "hh:mm A"
                  )}`}</span>
                </h2>
                <p>Open</p>
              </div>
              <div className="slot-actions">
                <button
                  className="rounded-md bg-primary-blue-medium p-1 px-4 text-white"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Fill
                </button>
              </div>
            </div>
          </div>
          <div className="slot-list max-w-md my-3">
            <div className="event-slot-box flex gap-3 items-start bg-neutral-gray-100 p-3 rounded-md">
              <div className="slot-details">
                <h2 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  Business Spotlight{" "}
                  <span className="slot-time">{`${moment(eventDate)?.format(
                    "hh:mm A"
                  )}`}</span>
                </h2>
                <p>Open</p>
              </div>
              <div className="slot-actions">
                <button
                  className="rounded-md bg-primary-blue-medium p-1 px-4 text-white"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Fill
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else if (approvedRequests.length === 1) {
      // Case 1: 1 approved item, show it and one placeholder
      return (
        <>
          <div className="slot-list max-w-md my-3">
            <div className="event-slot-box flex gap-3 items-start bg-neutral-gray-100 p-3 rounded-md">
              <div className="slot-details">
                <h2 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  {`${approvedRequests[0]?.eventName} `}
                  <span className="slot-time">{`${moment(
                    approvedRequests[0]?.eventDate
                  )?.format("hh:mm A")}`}</span>
                </h2>
                {/* <p>{`${approvedRequests[0]?.company_name}`}</p> */}
                <div>
                  {`${approvedRequests[0]?.first_name} ${approvedRequests[0]?.last_name} `}
                  <strong>{`(${approvedRequests[0]?.company_name})`}</strong>
                </div>
              </div>
              <div className="slot-actions">
                <button
                  className="rounded-full bg-primary-blue-medium p-1 text-white me-3"
                  onClick={async () => {
                    await setRequestData(approvedRequests[0]);
                    await setOpen2(true);
                  }}
                >
                  <MdInfo size={"1.5rem"} />
                </button>
                <button
                  onClick={async () => {
                    await setRequestData(approvedRequests[0]);
                    await setOpenDelete(true);
                  }}
                  className="rounded-full bg-primary-blue-medium p-1 text-white"
                >
                  <MdDelete size={"1.5rem"} />
                </button>
              </div>
            </div>
          </div>

          <div className="slot-list max-w-md my-3">
            <div className="event-slot-box flex gap-3 items-start bg-neutral-gray-100 p-3 rounded-md">
              <div className="slot-details">
                <h2 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  Business Spotlight{" "}
                  <span className="slot-time">{`${moment(eventDate)?.format(
                    "hh:mm A"
                  )}`}</span>
                </h2>
                <p>Open</p>
              </div>
              <div className="slot-actions">
                <button
                  className="rounded-md bg-primary-blue-medium p-1 px-4 text-white"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Fill
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      // Case 2: 2 or more approved items, show the first 2
      return (
        <>
          {approvedRequests.slice(0, 2).map((request, index) => (
            <div key={index} className="slot-list max-w-md my-3">
              <div className="event-slot-box flex gap-3 items-start bg-neutral-gray-100 p-3 rounded-md">
                <div className="slot-details">
                  <h2 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                    {`${request?.eventName} `}
                    <span className="slot-time">{`${moment(
                      request?.eventDate
                    )?.format("hh:mm A")}`}</span>
                  </h2>
                  <div>
                    {`${request?.first_name} ${request?.last_name} `}
                    <strong>{`(${request?.company_name})`}</strong>
                  </div>
                </div>
                <div className="slot-actions">
                  <button
                    className="rounded-full bg-primary-blue-medium p-1 text-white me-3"
                    onClick={async () => {
                      await setRequestData(request);
                      await setOpen2(true);
                    }}
                  >
                    <MdInfo size={"1.5rem"} />
                  </button>
                  <button
                    onClick={async () => {
                      await setRequestData(request);
                      await setOpenDelete(true);
                    }}
                    className="rounded-full bg-primary-blue-medium p-1 text-white"
                  >
                    <MdDelete size={"1.5rem"} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <h2
        className="mt-8 mb-3"
        style={{ fontSize: "1.8rem", fontWeight: "bold" }}
      >
        Slots
      </h2>

      <div className="slot-wrapper">
        {/* for loop */}
        {renderSpotlightSlots()}

        <EventFillModal
          onUpdate={onUpdate}
          eventId={eventId}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
        />
        <SlotInfoModal
          data={RequestData}
          open2={open2}
          setOpen2={setOpen2}
          handleClose2={handleClose2}
        />

        <DeleteConfirmation
          message={
            "Are you sure you want to remove this member from the spotlight?"
          }
          open={openDelete}
          setOpen={setOpenDelete}
          handleClose={handleCloseDelete}
          onConfirm={() => deleteSpotlightMember(RequestData)}
        />
      </div>
    </>
  );
};

export default EventSlots;
