/**
 * To display a list of all promopromoCodes
 * @param { Array } promoCodes - List of promo promoCodes
 * @returns { Component }
 */

const PromoCodes = ({ promoCodes }) => {
  console.log(promoCodes);
  return (
    <>
      {promoCodes?.length > 0 &&
        promoCodes?.map((item, index) => {
          return (
            <div
              key={item?.id}
              className="grid grid-cols-2 md:mt-3 mt-2 p-3 items-center rounded-lg bg-neutral-gray-faqBG"
            >
              <div className="md:col-span-1 col-span-1 w-full">
                <p className="md:text-base text-xs">
                  Earn {item?.amount} {item?.type || ""} credits
                </p>
              </div>
              <div className="flex md:col-span-1 col-span-1 md:w-[149px]   h-[32px] items-center  ml-5 justify-center bg-[#067eed26] rounded-[10px]">
                <p className="md:text-base text-xs">
                  {item?.coupon_code || ""}{" "}
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PromoCodes;
