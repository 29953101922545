//Open and close modal for adding generating new promo codes

import React, { useEffect, useState } from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { updateUserStatus, moveSubscription } from "config/apis/UserAPIs";

const MoveSubscription = ({
  openMove,
  setOpenMove,
  action,
  updateUserStatusID,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [moveType, setMoveType] = useState("");
  const updateUserStatusAPI = async () => {
    if (action == "move") {
      // if(moveType==""){
      //     dispatch(
      //         showToast({
      //           message: "Select Move Subscription Type",
      //           type: "error",
      //         })
      //       );
      //  }
      //  else{
      let body = { status: "move", type: "Annual" };

      const res = await moveSubscription(body, updateUserStatusID);
      if (res?.data?.success) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        onUpdate();
        setOpenMove(false);
        setMoveType("");
      } else {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "error",
          })
        );
      }
      //  }
    } else if (action == "unassigned") {
      let body = { status: "unassigned", type: "Annual" };
      const res = await moveSubscription(body, updateUserStatusID);
      if (res?.data?.success) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        onUpdate();
        setOpenMove(false);
        setMoveType("");
      } else {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "error",
          })
        );
      }
    }
    // try {

    //   if (res.status === 200) {
    // dispatch(
    //   showToast({
    //     message: res?.data?.message,
    //     type: "success",
    //   })
    // );
    //     onUpdate();
    //     setOpen(false);
    //   } else {
    //     dispatch(showToast({ message: res?.data?.error, type: "error" }));
    //   }
    // } catch (e) {
    //   logError("UPDATE USER STATUS", e);
    //   dispatch(
    //     showToast({
    //       message: e?.response?.data?.error,
    //       type: "error",
    //     })
    //   );
    // }
  };

  useEffect(() => {
    console.log(action, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
  }, []);

  //Functions to handle opening and closing of modal
  const handleClose = () => {
    setOpenMove(false);
  };

  return (
    <div>
      <Modal open={openMove} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal1">
          <Grid container>
            <Grid item xs={12} className="box-modal1-row1">
              <Typography>
                {action == "move"
                  ? `Are you sure you want to ${action} membership?`
                  : `Are you sure you want to move membership to annual?`}
              </Typography>
            </Grid>
            <Grid item md={12} className="box-modal1-row2">
              {/* {
                    action=="move" && <div>
                        <select value={moveType} onChange={(e)=>{
                             setMoveType(e?.target?.value)
                        }}>
                             <option value="">Select Move Subscription</option>
                             <option value="Monthly">Monthly</option>
                             <option value="Annual">Annual</option>
                        </select>
                    </div>
                 } */}
            </Grid>

            <Grid item md={12} className="box-modal1-row2">
              <button
                onClick={() => {
                  updateUserStatusAPI();
                }}
                className="box-modal1-row2-yes"
              >
                Move
              </button>
              <button
                onClick={() => {
                  setOpenMove(false);
                }}
                className="box-modal1-row2-no"
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default MoveSubscription;
