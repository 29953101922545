// DONE

//FAQ card for content dashboard

import React from "react";

import { useState } from "react";
//Mapped faqs for faq card on content dashboard
import Faq from "./Faq";
//Commonly styled  buttons
import TertiaryButton from "components/Common/TertiaryButton";
import Button from "components/Common/Button";
//API
import { deleteFAQs } from "config/apis/FAQS";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
const FaqCard = ({ faqs, onUpdate }) => {
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [faqId, setFaqId] = useState(null);
  const [openCnf, setOpenCnf] = useState(false);

  const dispatch = useDispatch();
  //Function to delete selected FAQ
  const deleteItem = async (id) => {
    setFetching(true);
    try {
      const res = await deleteFAQs(id);

      console.log(res);
      if (res.status === 200) {
        console.log(res);
        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE FAQ", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }

    setFetching(false);
  };

  return (
    <div className="shadow-ContentCards bg-white rounded-2xl w-full p-5">
      <div className="md:grid-cols-2 grid-cols-3 grid w-full">
        <div className="flex col-span-1 ">
          <h1 className="text-normal text-2xl flex justify-start">Faqs</h1>
        </div>

        <div className="flex justify-end md:space-x-4 space-x-3 md:col-span-1 col-span-2 items-center">
          <TertiaryButton path="/faqs" />

          <Button
            text="Add FAQ"
            onClick={() => {
              history.push({ pathname: "/faqs", state: { create: true } });
            }}
          />
        </div>
      </div>

      {faqs?.length > 0 &&
        faqs?.map((item, index) => {
          return (
            <div key={index}>
              <Faq
                item={item}
                onDelete={() => {
                  setFaqId(item?.id);
                  setOpenCnf(true);
                }}
                onEdit={() => {
                  history.push({ pathname: "/faqs", state: { id: item?.id } });
                }}
              />
            </div>
          );
        })}

      <ConfirmationDialog
        open={openCnf}
        setOpen={setOpenCnf}
        handleYes={() => deleteItem(faqId)}
        message={`Are you sure you want to delete this FAQ?`}
      />
    </div>
  );
};

export default FaqCard;
