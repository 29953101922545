import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdOutlineClose } from "react-icons/md";
import { HiOutlineArrowLongDown } from "react-icons/hi2";

const NotificationInfoModal = ({ open, setOpen, handleClose }) => {
  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal14 relative ">
          <button
            className="absolute top-4 right-4"
            onClick={() => {
              setOpen(false);
            }}
          >
            <MdOutlineClose size={"1.5rem"} />
          </button>
          <div className="modal-content my-3">
            <div className="modal-heading text-primary-blue-dark text-lg mb-3">
              Edits
            </div>
            <table className="table-auto w-full text-left">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Title</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
                <div className="arrow-icon">
                  <HiOutlineArrowLongDown
                    className="ms-3 text-[#01BF21]"
                    size={"2rem"}
                  />
                </div>
                <tr>
                  <td>Johnny Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-content my-3">
            <div className="modal-heading text-[#01BF21] text-lg mb-3">
              Registrations
            </div>
            <table className="table-auto w-full text-left">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Title</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
                <tr>
                  <td>Johnny Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-content my-3">
            <div className="modal-heading text-[#BF080E] text-lg mb-3">
              Cancellations
            </div>
            <table className="table-auto w-full text-left">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Title</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
                <tr>
                  <td>Johnny Doe</td>
                  <td>Backbone</td>
                  <td>COE</td>
                  <td>johnd@bizbackbone.com</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NotificationInfoModal;
