//Open and close modal for adding generating new promo codes

import React, { useEffect, useState } from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

//Icons
import { BiPencil } from "react-icons/bi";
//External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { updateSubscription } from "config/apis/SubscriptionAdmin";
import TertiaryButton from "components/Common/TertiaryButton";

const EditSubscription = ({
  editCard,
  setEditCard,
  cardDetails,
  setCardDetails,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState([]);

  const handleClose = () => {
    setEditCard(false);
    setEdit(false);
  };

  const changeDescription = () => {
    setList(cardDetails?.subscription_description?.split(",") || []);
  };
  useEffect(() => {
    changeDescription();
  }, [editCard]);

  const EditSubscriptionAPI = async (values) => {
    try {
      let desc = list.map((i) => i?.trim())?.filter((i) => i !== "");
      let body = {
        subscription_cost: cardDetails.subscription_cost,
        subscription_description: desc.join(","),
        subscription_name: cardDetails.subscription_name,
      };
      const res = await updateSubscription(body, cardDetails.id);

      if (res?.status === 200) {
        onUpdate();
        setEditCard(false);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Edit SUBSCRIPTION", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <div>
      <Modal
        open={editCard}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal5">
          <Grid container>
            <Grid item xs={12} className="flex gap-3 pb-[3%]">
              <Grid item xs={11}>
                <Typography className="text-[#5B5B5B] text-2xl font-normal">
                  Subscriptions
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <button
                  onClick={() => setEdit(true)}
                  className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                >
                  <BiPencil className="md:text-2xl text-lg text-primary-blue-dark font-medium" />
                </button>
              </Grid>
              {/* <Grid item xs={1}>
                <button className="  cursor-pointer transform transition ease-in-out duration-150 hover:scale-105">
                  <HiOutlineTrash className="md:text-2xl text-lg text-[#C11717] " />
                </button>
              </Grid> */}
            </Grid>
            <Grid item xs={12} className="pb-[3%]">
              <div className="grid grid-cols-12   border-[1px] border-[#5F5F5F] bg-white rounded-lg p-4 pl-6 pr-6">
                <div className="col-span-12 flex justify-center">
                  {edit ? (
                    <input
                      disabled="true"
                      type="name"
                      id="name"
                      name="name"
                      onChange={(e) => {
                        setCardDetails({
                          ...cardDetails,
                          subscription_name: e.target.value,
                        });
                      }}
                      className="text-[#7D7D7D] text-lg font-semibold text-center h-10 mb-2"
                      defaultValue={cardDetails.subscription_name}
                    />
                  ) : (
                    <h1 className="text-[#7D7D7D] text-lg font-semibold">
                      {cardDetails.subscription_name}
                    </h1>
                  )}
                </div>
                <div className="col-span-12 flex justify-center">
                  {edit ? (
                    <input
                      disabled="true"
                      type="cost"
                      id="cost"
                      name="cost"
                      onChange={(e) => {
                        setCardDetails({
                          ...cardDetails,
                          subscription_cost: e.target.value,
                        });
                      }}
                      className="font-medium text-[2.8rem] text-center w-32"
                      value={cardDetails.subscription_cost}
                    />
                  ) : (
                    <h1 className="font-medium text-[2.8rem] ">
                      {cardDetails.subscription_cost}
                    </h1>
                  )}
                </div>
                <div className="col-span-12   ">
                  <ul className="text-base text-[#646464] font-normal flex-row items-center list-disc pl-5 fixed-sub-card">
                    {edit ? (
                      <>
                        {list?.map((i, index) => {
                          return (
                            <li>
                              <textarea
                                type="subscription_description1"
                                id="subscription_description1"
                                name="subscription_description1"
                                onChange={(e) => {
                                  let t = [...list];
                                  t[index] = e.target.value;
                                  setList(t);
                                }}
                                className="w-full mb-3 h-14 border-0 resize-none flex"
                                value={i}
                              />
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {list?.map((i) => {
                          return <li>{i}</li>;
                        })}
                      </>
                    )}
                  </ul>
                  {edit && (
                    <div className="mt-2">
                      <TertiaryButton
                        text="Add +"
                        onClick={() => {
                          let t = [...list];
                          t.push("  ");
                          setList(t);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-span-12 flex justify-center mt-5">
                  <button
                    onClick={(e) => {
                      EditSubscriptionAPI();
                      setEdit(false);
                    }}
                    type="submit"
                    className="bg-[#067EED] cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white text-2xl font-semibold w-[260px] h-12 rounded-md "
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="box-modal5-row2">
              <button
                onClick={() => {
                  setEditCard(false);
                  setEdit(false);
                }}
                className="box-modal5-row2-cancel"
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSubscription;
