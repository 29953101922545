//Open and close modal for adding generating new promo codes

import React from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { updateUserStatus } from "config/apis/UserAPIs";

const ActionMembershipModal = ({
  open,
  setOpen,
  action,
  updateUserStatusID,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const updateUserStatusAPI = async () => {
    try {
      let body = { status: action == "turn on" ? true : false };

      const res = await updateUserStatus(body, updateUserStatusID);

      if (res.status === 200) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        onUpdate();
        setOpen(false);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("UPDATE USER STATUS", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal1">
          <Grid container>
            <Grid item xs={12} className="box-modal1-row1">
              <Typography>
                Are you sure you want to {action} membership?
              </Typography>
            </Grid>
            <Grid item xs={12} className="box-modal1-row2">
              <button
                onClick={() => {
                  updateUserStatusAPI();
                }}
                className="box-modal1-row2-yes"
              >
                yes
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="box-modal1-row2-no"
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ActionMembershipModal;
