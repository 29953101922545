//Free trial card for subscriptimport React, { useState, useEffect } from "react";
import React, { useState, useEffect } from "react";

//Common button component
import TertiaryButton from "components/Common/TertiaryButton";
//Accordion component from MUI
import UserAccordion from "./userAccordion";
import { Typography } from "@mui/material";
import { adminAddUserByEco } from "config/apis/Admin";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import { removeEcosystem } from "config/apis/Ecosystems";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import { TrashIcon } from "@heroicons/react/outline";
import { searchBySubscriptionAPI } from "config/apis/dashboard.js";

/**
 * To display the subscriptions and the users in those subscription
 * @param { Object } subscription - Subscription details and the users in it
 * @returns { Component }
 */

const UserCard = ({
  subscription,
  list,
  onUpdate,
  ecosystems,
  id,
  unverified,
}) => {
  const [csvFile, setCsvFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [filterCompany, setFilterCompany] = useState("");
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [list1, setList1] = useState(list);

  const csvFileInput = React.useRef(null);

  const [timeoutToClear, setTimeoutToClear] = useState();

  useEffect(() => {
    /* if (list1?.length === 0) {

      console.log(" _searchBySubscriptionAPI useEffect listlistlist ", JSON.stringify(list))
      setList1(list)
    } */
  }, [list1]);

  useEffect(() => {
    if (uploading) {
      fn();
    }
  }, [uploading]);

  const fn = async () => {
    const Data = new FormData();
    Data.append("file", csvFile);
    if (csvFile !== null) {
      const res = await adminAddUserByEco(id, Data);
      setCsvFile(null);
      csvFileInput.current = null;
      setTimeout(() => {
        onUpdate();
        setUploading(false);
      }, 2000);
    }
  };

  const AddUserButtonHandler = (e) => {
    csvFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    setCsvFile(fileUploaded);
    setUploading(true);
  };

  const RemoveEcosystemHandler = async (id) => {
    try {
      console.log("remove");
      const res = await removeEcosystem(id);
      if (res?.status === 200) {
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE ECOSYSTEM", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  const _searchBySubscriptionAPI = async (payload) => {
    // console.log("_searchBySubscriptionAPI _searchBySubscriptionAPI1111 ", payload);
    try {
      const res = await searchBySubscriptionAPI(payload);
      if (res?.data?.success) {
        // console.log("res?.data searchBySubscriptionAPI222 ", res?.data?.comapny.length);
        // console.log("res?.data searchBySubscriptionAPI222 ", JSON.stringify(res?.data?.comapny));
        setList1(res?.data?.comapny);
        // handleClose();
        // onRefresh();
      } else {
        console.log("error searchBySubscriptionAPI ");
      }
    } catch (e) {
      logError("searchBySubscriptionAPI ==> ", e);
    }
  };

  const clearSearch = () => {
    setSearch("");
    setList1(list);
  };

  const handleSearch = async (text) => {
    if (text.length === 0) {
      await clearSearch();
    } else if (text.length >= 1) {
      const payload = {
        keyword: text,
        subscription: subscription,
      };
      await _searchBySubscriptionAPI(payload);
    } else {
      setSearch(text);
    }
  };

  const debounce = (callback, alwaysCall, ms) => {
    return (...args) => {
      alwaysCall(...args);
      clearTimeout(timeoutToClear);
      setTimeoutToClear(
        setTimeout(() => {
          callback(...args);
        }, ms)
      );
    };
  };

  const setSearchTextAlways = (text) => {
    setSearch(text);
  };

  const debouncedSearchComplaints = debounce(
    handleSearch,
    setSearchTextAlways,
    500
  );

  // console.log("subscription ==>  ", subscription)
  return (
    <>
      <div className="tab:col-span-1 md:col-span-3 col-span-3 ">
        <div className="shadow-ContentCards bg-white rounded-2xl mx-auto md:w-3/4 w-full tab:w-full p-5">
          <div className="grid-cols-1 grid w-full border-b-2 border-gray-400 p-2">
            <div className="flex-[1_0_0] col-span-2 p-2">
              <h1 className="text-normal capitalize text-xl flex justify-start items-center gap-3">
                {subscription}
                <input
                  value={search}
                  /* onChange={(e) => {
                    setSearch(e.target.value);
                  }} */
                  onChange={(e) => debouncedSearchComplaints(e.target.value)}
                  type="text"
                  className="block w-full px-4 bg-white border rounded-md focus:outline-none"
                  placeholder="Search..."
                />
              </h1>
              <ConfirmationDialog
                open={showModal1}
                setOpen={setShowModal1}
                handleYes={() => {
                  RemoveEcosystemHandler(id);
                }}
                message={`You have to show deleting the ecosystem will delete all the users and company associated. Are you sure?`}
              />
            </div>
            {ecosystems && (
              <div className="flex justify-end md:space-x-4 space-x-3 md:col-span-1 col-span-2 items-center">
                {uploading ? (
                  <div className="flex flex-row items-center justify-center bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-[7.5rem] w-[6rem] md:h-10 h-7 rounded-lg font-normal text-sm">
                    <div className="border-white border border-t-0 bg-transparent w-5 h-5 rounded-full animate-spin" />
                  </div>
                ) : (
                  <div className="flex flex-row gap-2 items-center">
                    <button
                      className=" bg-primary-blue-medium col-end-1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-[7.5rem] w-[6rem] md:h-10 h-7 rounded-lg font-normal text-sm"
                      onClick={AddUserButtonHandler}
                    >
                      + Add
                      <input
                        type="file"
                        ref={csvFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </button>
                    <TrashIcon
                      onClick={() => {
                        setShowModal1(true);
                      }}
                      className=" text-red-500 col-end-1 cursor-pointer h-7 w-7"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {ecosystems && (
            <input
              type="text"
              placeholder="Enter company name."
              className="w-full mt-2 mb-2"
              value={filterCompany}
              onChange={(e) => setFilterCompany(e.target.value)}
            />
          )}
          <div className="h-[25rem] overflow-y-scroll">
            {/* {list?.filter(
              (l) =>
                (!search ||
                  (search !== "" &&
                    l?.Company?.company_name
                      ?.toLowerCase()
                      .includes(search.toLowerCase()))) &&
                (!filterCompany ||
                  (filterCompany !== "" &&
                    l?.Company?.company_name
                      ?.toLowerCase()
                      .includes(filterCompany.toLowerCase())))
            )?.length > 0 ? (
              <UserAccordion
                users={list.filter(
                  (l) =>
                    (!search ||
                      (search !== "" &&
                        l?.Company?.company_name
                          ?.toLowerCase()
                          .includes(search.toLowerCase()))) &&
                    (!filterCompany ||
                      (filterCompany !== "" &&
                        l?.Company?.company_name
                          ?.toLowerCase()
                          .includes(filterCompany.toLowerCase())))
                )}
                onUpdate={onUpdate}
                unverified={unverified}
              />
            ) */}

            {subscription === "Unassigned Users" ? (
              list?.filter(
                (l) =>
                  (!search ||
                    (search !== "" &&
                      l?.Company?.company_name
                        ?.toLowerCase()
                        .includes(search.toLowerCase()))) &&
                  (!filterCompany ||
                    (filterCompany !== "" &&
                      l?.Company?.company_name
                        ?.toLowerCase()
                        .includes(filterCompany.toLowerCase())))
              )?.length > 0 ? (
                <UserAccordion
                  users={list.filter(
                    (l) =>
                      (!search ||
                        (search !== "" &&
                          l?.Company?.company_name
                            ?.toLowerCase()
                            .includes(search.toLowerCase()))) &&
                      (!filterCompany ||
                        (filterCompany !== "" &&
                          l?.Company?.company_name
                            ?.toLowerCase()
                            .includes(filterCompany.toLowerCase())))
                  )}
                  onUpdate={onUpdate}
                  unverified={unverified}
                />
              ) : (
                <div className="flex flex-row items-center justify-center h-full">
                  <Typography className="box-modal-label">
                    No users found!
                  </Typography>{" "}
                </div>
              )
            ) : list1?.length > 0 ? (
              <UserAccordion
                users={list1}
                onUpdate={onUpdate}
                unverified={unverified}
              />
            ) : (
              <div className="flex flex-row items-center justify-center h-full">
                <Typography className="box-modal-label">
                  No users found!
                </Typography>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCard;
