// DONE
// TODO: The save button doesn't work on editing the question
import React from "react";
//React hooks
import { useState } from "react";

//Icons
import { AiFillCheckCircle } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";

//Initial values for add faq form

//External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Api
import { FAQs, UpdateFAQs } from "config/apis/FAQS";

// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";

const EditFAQ = ({
  onUpdate,
  editFaq,
  onDelete,
  setEditFaqID,
  edit,
  setEdit,
}) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [answerChange, setAnswerChange] = useState(false);
  console.log(editFaq.answer);

  let answerArr = Object.entries(editFaq.answer).map((item, index) => {
    console.log(item);

    return item[1];
  });
  let ansString = answerArr.map((item) => {
    return `${item}`;
  });
  let finalAns = ansString.join().replace(/,/g, "\n");

  const formik = useFormik({
    initialValues: {
      tittle: editFaq.question,
      answer: finalAns,
    },
    validationSchema: Yup.object({
      tittle: Yup.string().required("Required"),
      answer: edit ? "" : Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      EditFaqAPI(values);
    },
  });

  const EditFaqAPI = async (values) => {
    try {
      const body = {
        question: values.tittle,
        answer: {},
      };

      values?.answer?.split(/[\r\n\t]+/g).map((items, index) => {
        body.answer[`line${index + 1}`] = items;
      });

      setAnswerChange(false);
      const res = await UpdateFAQs(body, editFaq.id);

      if (res?.status === 200) {
        dispatch(showToast({ message: res?.data?.message }));
        onUpdate();
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        setEditFaqID();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Edit FAQ", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  return (
    <>
      <div className="grid grid-cols-12 p-4 mb-5 shadow-FaqCard rounded-lg bg-white">
        <div className="col-span-10  truncate w-full">
          <input
            type="tittle"
            id="tittle"
            name="tittle"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.tittle}
            className="font-normal w-full bg-white focus:outline-none text-base h-[40px] pl-3"
          />
          {formik.touched.tittle && formik.errors.tittle ? (
            <p className="text-red-600 text-sm  pl-3 ">
              {formik.errors.tittle}
            </p>
          ) : null}
        </div>

        <div className="flex col-span-2 justify-end space-x-5">
          <button
            onClick={() => setOpen(true)}
            type="submit"
            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
          >
            <AiFillCheckCircle className="text-2xl text-[#009B22]  font-medium" />
          </button>
          <button
            onClick={() => {
              onDelete();
            }}
            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
          >
            <HiOutlineTrash className="text-2xl text-alert" />
          </button>
        </div>

        <div className="col-span-12  pb-5">
          <textarea
            type="answer"
            id="answer"
            name="answer"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setAnswerChange(true);
            }}
            value={formik.values.answer}
            className="text-sm text-[#5b5a5a] bg-white font-light w-full border-none pl-3 focus:outline-none h-12"
            placeholder="Add Text Here"
          />
          {formik.touched.answer && formik.errors.answer ? (
            <p className="text-red-600 text-sm  pl-3 ">
              {formik.errors.answer}
            </p>
          ) : null}
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        handleYes={formik.handleSubmit}
        handleNo={() => setEditFaqID()}
        message="Are you sure you want to update this FAQ?"
      />
    </>
  );
};

export default EditFAQ;
