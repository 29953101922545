// DONE

//individual faq div for the faq card on content dashboard

import React from "react";

//icons
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";

const Faq = ({ item, onDelete, onEdit }) => {
  return (
    <div className="grid grid-cols-12 mt-5 md:p-3 p-2 gap-2 rounded-lg bg-neutral-gray-faqBG">
      <div className="md:col-span-9 col-span-10    w-full">
        <p className="md:text-base text-xs   font-semibold">{item?.question}</p>
      </div>
      <div className="flex md:col-span-3   col-span-2 justify-end md:space-x-5 space-x-2">
        <button
          onClick={() => onEdit()}
          className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
        >
          <BiPencil className="md:text-2xl text-lg text-primary-blue-dark font-medium" />
        </button>
        <button
          onClick={() => onDelete()}
          className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
        >
          <HiOutlineTrash className="md:text-2xl text-lg text-primary-blue-dark" />
        </button>
      </div>
    </div>
  );
};

export default Faq;
