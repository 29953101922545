import { TrashIcon } from "@heroicons/react/outline";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import ConfirmRemoveModal from "components/PrivatePages/SubscriptionDashboard/modals/ConfirmRemoveModal";
import ConfirmSendRequest from "components/PrivatePages/SubscriptionDashboard/modals/ConfirmSendRequest";
import React, { useState, useEffect } from "react";
import { HiTrash } from "react-icons/hi";
import { MdOutlineEdit } from "react-icons/md";
import { removeEcosystem } from "config/apis/Ecosystems";
import { showToast } from "redux/toaster";
import { adminAddUserByEco } from "config/apis/Admin";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Row = ({ eco, sendRequestToChange, onUpdate }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [filterCompany, setFilterCompany] = useState("");
  const [updateUserStatusID, setUpdateUserStatusID] = useState("");
  const [sendId, setSendId] = useState("");
  const [remove, setRemove] = useState(false);
  const [remove2, setRemove2] = useState(false);
  console.log(eco, "eco console>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.");
  const csvFileInput = React.useRef(null);
  useEffect(() => {
    if (uploading) {
      fn();
    }
  }, [uploading]);

  const fn = async () => {
    const Data = new FormData();
    Data.append("file", csvFile);
    if (csvFile !== null) {
      const res = await adminAddUserByEco(eco?.id, Data);
      setCsvFile(null);
      csvFileInput.current = null;
      setTimeout(() => {
        onUpdate();
        setUploading(false);
      }, 2000);
    }
  };

  const AddUserButtonHandler = (e) => {
    csvFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    setCsvFile(fileUploaded);
    setUploading(true);
  };

  const RemoveEcosystemHandler = async (id) => {
    try {
      console.log("remove");
      const res = await removeEcosystem(id);
      if (res?.status === 200) {
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("DELETE ECOSYSTEM", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  return (
    <div className="max-h-96 overflow-y-scroll px-2 py-5">
      <ConfirmationDialog
        open={showModal1}
        setOpen={setShowModal1}
        handleYes={() => {
          RemoveEcosystemHandler(eco?.id);
        }}
        message={`Deleting the ecosystem will delete all the users and company associated. Are you sure?`}
      />{" "}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-lg md:text-2xl mb-2 font-poppins font-normal">
          {eco?.name}
        </h1>
        {uploading ? (
          <div className="flex flex-row items-center justify-center bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white md:w-[7.5rem] w-[6rem] md:h-10 h-7 rounded-lg font-normal text-sm">
            <div className="border-white border border-t-0 bg-transparent w-5 h-5 rounded-full animate-spin" />
          </div>
        ) : (
          <div className="flex flex-row gap-2 items-center">
            <button
              className=" bg-primary-blue-medium col-end-1 cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 text-white p-2 px-10 rounded-lg font-normal text-sm"
              onClick={AddUserButtonHandler}
            >
              + Add
              <input
                type="file"
                ref={csvFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </button>
            <TrashIcon
              onClick={() => {
                setShowModal1(true);
              }}
              className=" text-red-500 col-end-1 cursor-pointer h-7 w-7"
            />
          </div>
        )}
      </div>
      <input
        type="text"
        placeholder="Enter company name."
        className="mt-2 mb-2 rounded-md w-full md:w-auto"
        value={filterCompany}
        onChange={(e) => setFilterCompany(e.target.value)}
      />
      <div className="overflow-y-auto">
        <table className=" table-auto w-full text-md text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="break-words ">
              <th className="p-2 text-base">Company Name</th>
              <th className="p-2 text-base">Email</th>
              <th className="p-2 text-base">Phone Number</th>
              <th className="p-2 text-base">Status</th>
              <th className="p-2 text-base">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {eco?.Users?.filter((e) =>
              e?.Company?.company_name
                ?.toLowerCase()
                .includes(filterCompany.toLowerCase())
            ).map((user) => (
              <tr className=" break-words bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-2">{user?.Company?.company_name}</td>
                <td className="p-2">{user?.email}</td>
                <td className="p-2">{user?.mobileNo}</td>
                <td className="p-2">
                  {user?.requestSent ? (
                    <div className="text-blue-400 underline underline-offset-1 cursor-pointer">
                      <h1 className="text-base text-primary-blue-dark">
                        Request Sent
                      </h1>
                    </div>
                  ) : (
                    <div
                      className="text-blue-400 underline underline-offset-1 cursor-pointer"
                      onClick={() => {
                        setRemove2(true);
                        setSendId(user?.id);
                      }}
                    >
                      <h1 className="text-primary-blue-dark text-base">
                        Send Request
                      </h1>
                    </div>
                  )}
                </td>
                <td className="p-2 flex items-center gap-2">
                  <div>
                    <button
                      className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                      onClick={() => {
                        history.push("/company/" + user?.Company?.id);
                      }}
                    >
                      <MdOutlineEdit className="text-2xl text-blue-500" />
                    </button>
                  </div>
                  <div>
                    <button
                      className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
                      onClick={() => {
                        setRemove(true);
                        setUpdateUserStatusID(user?.id);
                      }}
                    >
                      <HiTrash className="text-2xl text-red-500" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmRemoveModal
        open={remove}
        setOpen={setRemove}
        id={updateUserStatusID}
        onUpdate={onUpdate}
      />
      <ConfirmSendRequest
        open={remove2}
        setOpen={setRemove2}
        id={sendId}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default Row;
