//Open and close modal for adding generating new promo codes

import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import { sendRequestToChangeAPI } from "config/apis/UserAPIs";

const ConfirmSendRequest = ({ open, setOpen, id, onUpdate }) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const onDeleteUser = async () => {
    const body = { id: id };
    setDeleting(true);
    try {
      const res = await sendRequestToChangeAPI(body);
      console.log(res);
      dispatch(
        showToast({
          message: res?.data?.message,
          type: "success",
        })
      );
      onUpdate();
      setOpen(false);
      setDeleting(false);
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
      setDeleting(false);
    }
  };

  //Functions to handle opening and closing of modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal1">
          <Grid container>
            <Grid item xs={12} className="box-modal1-row1">
              <Typography>
                Are you sure you want to send a request to the user to change
                their subscription plan?
              </Typography>
            </Grid>
            <Grid item xs={12} className="box-modal1-row2">
              {deleting ? (
                <div className="flex flex-row items-center space-x-3 text-white justify-center bg-primary-blue-medium cursor-pointer transform transition ease-in-out duration-150 px-2 md:h-10 h-7 rounded-lg font-normal text-sm">
                  <div className="font-sm font-medium text-white">Sending</div>{" "}
                  <div className="border-white border border-t-0 bg-transparent w-5 h-5 rounded-full animate-spin" />
                </div>
              ) : (
                <button
                  onClick={() => {
                    onDeleteUser();
                  }}
                  className="box-modal1-row2-yes"
                >
                  Yes
                </button>
              )}
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="box-modal1-row2-no"
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmSendRequest;
