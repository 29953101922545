import React, { useEffect } from "react";

function LinkView({ link, image, icon, title, description, domain }) {
  useEffect(() => {}, [link]);

  return (
    <div className="link-container">
      <a href={link} target="__blank">
        <img src={image || icon} alt="link" className="link-image" />
        <div className="link-content">
          <h1 className="line-clamp-2">
            {title?.substr(0, 50)}
            {title?.length > 50 ? "..." : ""}
          </h1>
          <h2>
            {domain?.substr(0, 50)}
            {domain?.length > 50 ? "..." : ""}
          </h2>
          <p>
            {description?.substr(0, 125)}{" "}
            {description?.length > 125 ? "..." : ""}
          </p>
        </div>
      </a>
    </div>
  );
}

export default LinkView;
