import API_END_POINT from "config/APIEndpoints";

// adminTypes -> string || array
export const getIsAdmin = (adminTypes, type) => {
  let types = type?.includes(",") ? type.split(",") : [type];

  let f = false;

  types?.map((a) => {
    if (adminTypes?.includes(a)) f = true;
  });

  return f;
};

export const convertArrayToMap = (array, key) => {
  return array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
};

export const socketBaseUrl = () => {
  if (API_END_POINT && API_END_POINT.lastIndexOf("/api") !== -1) {
    const lastIndex = API_END_POINT.lastIndexOf("/api");
    const updatedUrl = API_END_POINT.substring(0, lastIndex);

    return updatedUrl;
  } else {
    return "https://dev-api.bizbackbone.com";
  }
};
