import { Link, useHistory, useRouteMatch } from "react-router-dom";

// Utils
import classNames from "helpers/utils/classNames";

// Constants
import { navigation, userNavigation } from "helpers/constants/Navigation";
import { privateRoutes } from "routes/PrivateRoute";

// Assets
import BackboneLogo from "assets/logos/BackboneLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { BsExclamation } from "react-icons/bs";

import { io } from "socket.io-client";
import { socketBaseUrl } from "helpers/utils";
import { showToast } from "redux/toaster";
import { useEffect } from "react";

const socket = io(socketBaseUrl());

/**
 * Displaying Navigation in Desktop
 */
function DesktopNavigation() {
  const history = useHistory();

  const dispatch = useDispatch();

  const selectedRoute = useRouteMatch();

  const user = useSelector((state) => state?.user);

  const signOut = () => {
    localStorage.removeItem("backboneAdmin");
    history.push("/");
  };

  useEffect(() => {
    try {
      const handleNotification = (data) => {
        data?.notifyToAdmin && dispatch(showToast({ message: data?.description }));
      };

      socket.on("notification", handleNotification);
      return () => {
        socket.off("notification", handleNotification);
      };
    } catch (err) {
      console.log("catch block", err);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col flex-grow bg-white overflow-y-auto">
        <div
          onClick={() => history.push("/dashboard")}
          className="cursor-pointer h-28 flex items-center justify-center flex-shrink-0 px-8 lg:px-4 pt-5 pb-4"
        >
          <img className="h-20 w-auto" src={BackboneLogo} alt="Logo" />
        </div>
        <div className="mt-2.5 flex-grow flex flex-col">
          <nav className="flex-1 pb-4 relative">
            <div className="top-nav-menu">
              {navigation.map((item) => {
                return (
                  <div className="py-2.5 px-4" key={item?.name}>
                    <Link
                      to={item.path}
                      className={classNames(
                        privateRoutes.find((e) => e.path == selectedRoute.path)
                          ?.menuTab === item.tab
                          ? "bg-primary-blue-lightest text-primary-blue-dark"
                          : "text-neutral-gray-500",
                        "group flex flex-col justify-center items-center font-poppins text-xs font-normal px-4 py-3 rounded-md transform transition ease-in-out duration-150 hover:scale-105"
                      )}
                    >
                      {item.path === "/servicerequests" ? (
                        /* <img src={exclaim} style={{ width: '40px', height: '40px', resize: 'contain' }} /> */
                        <BsExclamation
                          style={{ transform: "scale(3.8)", marginBottom: 10 }}
                        />
                      ) : privateRoutes.find(
                          (e) => e.path === selectedRoute.path
                        )?.menuTab == item.tab ? (
                        <item.selectedIcon
                          className={classNames(
                            "text-primary-blue-dark",
                            "flex-shrink-0 h-7 w-7 mb-1"
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <item.icon
                          className={classNames(
                            "text-neutral-gray-500 ",
                            "flex-shrink-0 h-7 w-7 mb-1"
                          )}
                          aria-hidden="true"
                        />
                      )}

                      <span className="text-center">{item.name}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="absolute bottom-0 w-full bg-neutral-gray-100 shadow-[0_-2px_10px_0_rgba(0,0,0,0.2)]">
              {userNavigation.map((item) => {
                return (
                  <div className="py-2.5 px-4" key={item?.name}>
                    {item?.path ? (
                      item?.name === "Settings" && user?.isSuperAdmin ? (
                        <Link
                          to={item.path}
                          className={classNames(
                            privateRoutes.find(
                              (e) => e.path == selectedRoute.path
                            )?.menuTab == item.tab
                              ? "bg-primary-blue-lightest text-primary-blue-dark"
                              : "text-neutral-gray-500",
                            "group flex flex-col justify-center items-center font-poppins text-xs font-normal px-4 py-3 rounded-md transform transition ease-in-out duration-150 hover:scale-105"
                          )}
                        >
                          {privateRoutes.find(
                            (e) => e.path == selectedRoute.path
                          )?.menuTab == item.tab ? (
                            <item.selectedIcon
                              className={classNames(
                                "text-primary-blue-dark",
                                "flex-shrink-0 h-7 w-7 mb-1"
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <item.icon
                              className={classNames(
                                "text-neutral-gray-500 ",
                                "flex-shrink-0 h-7 w-7 mb-1"
                              )}
                              aria-hidden="true"
                            />
                          )}

                          {item.name}
                        </Link>
                      ) : null
                    ) : (
                      <div
                        onClick={() => {
                          signOut();
                        }}
                        className={classNames(
                          privateRoutes.find(
                            (e) => e.path == selectedRoute.path
                          )?.menuTab == item.tab
                            ? "bg-primary-blue-lightest text-primary-blue-dark"
                            : "text-neutral-gray-500",
                          "group flex cursor-pointer flex-col justify-center items-center font-poppins text-xs font-normal px-4 py-3 rounded-md transform transition ease-in-out duration-150 hover:scale-105"
                        )}
                      >
                        {privateRoutes.find((e) => e.path == selectedRoute.path)
                          ?.menuTab == item.tab ? (
                          <item.selectedIcon
                            className={classNames(
                              "text-primary-blue-dark",
                              "flex-shrink-0 h-7 w-7 mb-1"
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <item.icon
                            className={classNames(
                              "text-neutral-gray-500 ",
                              "flex-shrink-0 h-7 w-7 mb-1"
                            )}
                            aria-hidden="true"
                          />
                        )}

                        {item.name}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default DesktopNavigation;
