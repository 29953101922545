import moment from "moment";
import React, { useState } from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
//Avatar image
import AvatarIMG from "assets/subscriptionAdmin/avatarCircle.png";

//Icons
import { AiOutlineClose } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";

const ShareEventModal = ({ shareModal, setShareModal, shareEventData }) => {
  const [isCopiedText, setIsCopiedText] = useState(false);

  const copyLink = () => {
    const tLink = `${window.location.href}?id=${shareEventData?.id}`;
    navigator.clipboard.writeText(tLink);
    setIsCopiedText(true);
    setTimeout(() => setIsCopiedText(false), 1000);
  };

  const str = shareEventData?.eventDate;

  const date = new Date(str);
  const date1 = new Date(date.getYear(), date.getMonth(), date.getDate());
  const shortName = date1.toLocaleString("en-US", { month: "short" });

  return (
    <div>
      <Modal id="root" open={shareModal} onClose={() => setShareModal(false)}>
        <Box className="box-modal14">
          <Grid container>
            <Grid item xs={12} className="box-modal14-middleColumn relative">
              <Typography className="w-full flex justify-start text-4xl">
                Share event
              </Typography>

              <button
                onClick={() => {
                  setShareModal(false);
                }}
                className="absolute top-1 right-1"
              >
                <AiOutlineClose />
              </button>

              <div className="w-full my-5">
                <div className="shadow-ContentCards rounded-2xl w-full p-3 grid grid-cols-12 gap-4 bg-white divide-[#9F9F9F] divide-y sm:divide-x sm:divide-y-0">
                  <div className="col-span-12 grid grid-cols-12 pt-3">
                    <div className="col-span-2 pl-2 pr-1">
                      <div className="flex-row justify-center h-fit pt-2 pb-2 w-10/12  rounded-lg bg-primary-blue-lightest items-start   ">
                        <p className="text-neutral-gray-darkgraytext justify-center md:text-4xl text-lg flex  font-semibold">
                          {date.getDate()}
                        </p>
                        <p className="text-neutral-gray-darkgraytext flex justify-center font-semibold md:text-base text-xs">
                          {shortName}
                        </p>
                      </div>
                    </div>
                    <div className="divide-[#9F9F9F] divide-y col-span-10">
                      <div className="flex items-center pb-3 space-x-3">
                        <h1 className="text-xl font-semibold text-[#555555] line-clamp-1">
                          {shareEventData?.eventName}
                        </h1>
                        <p className="text-[#8D8D8D] text-base font-normal line-clamp-1">
                          ({shareEventData?.eventType})
                        </p>
                      </div>
                      <div className="grid grid-cols-12 space-y-3 pt-3">
                        <div className="col-span-12 flex space-x-10">
                          <h3 className="text-base text-[#363636] font-semibold">
                            Time
                          </h3>
                          <p className="text-[#4E4E4E] text-base font-light">
                            {moment(new Date(str)).format("hh:mm a")}
                          </p>
                        </div>
                        <div className="col-span-12">
                          <p className="text-xs font-light text-[#4E4E4E] line-clamp-4">
                            {shareEventData?.eventDescription}
                          </p>
                        </div>
                        <div className="col-span-12 flex space-x-4 items-center">
                          <div>
                            <Avatar src={AvatarIMG} />
                          </div>
                          <div>
                            <p className="text-[#606060] font-medium text-base">
                              Uploaded by “
                              {shareEventData?.Company?.company_name}”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-start items-center w-full text-3xl space-x-4">
                <span className="cursor-pointer" onClick={copyLink}>
                  <BsLink45Deg />
                </span>
                <Typography className="text-3xl">Get Link</Typography>
              </div>
              <div className="flex mt-5">
                <Typography>
                  Use this unique link to have another business sign up to
                  BackBone and earn 1 month free subscription credit, when they
                  do
                </Typography>

                {isCopiedText ? (
                  <button className="poppins font-semibold text-base">
                    Link Copied
                  </button>
                ) : (
                  <button
                    className="poppins font-semibold text-base"
                    style={{
                      color: "#03A9F4",
                    }}
                    onClick={copyLink}
                  >
                    Copy Link
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ShareEventModal;
