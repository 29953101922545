// DONE

//Blog card for content dashboard

import React, { useState } from "react";
//Mapped Blog div for Blog card on content dashboard
import Blog from "./Blog";
//Commonly styled  buttons
import TertiaryButton from "../../../../Common/TertiaryButton";
import Button from "../../../../Common/Button";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import logError from "helpers/utils/logErrors";
import { deleteABlog } from "config/apis/Blogs";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
const BlogCard = ({ blogs, onUpdate }) => {
  const [blogId, setBlogId] = useState(null);
  const [openCnf, setOpenCnf] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const deleteBlog = async (id) => {
    try {
      const res = await deleteABlog(id);
      if (res?.status == 200) {
        onUpdate();
      }
    } catch (e) {
      logError("DELETE Blog", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  return (
    <div className="shadow-ContentCards bg-white rounded-2xl w-full p-5">
      <div className="md:grid-cols-2 grid-cols-3 grid w-full">
        <div className="flex col-span-1">
          <h1 className="text-normal text-2xl flex  justify-start">Blogs</h1>
        </div>
        <div className="flex justify-end md:space-x-4 space-x-3 md:col-span-1 col-span-2 items-center">
          <TertiaryButton path="/blogs" />
          <Button
            onClick={() => {
              history.push({
                pathname: "/blog/create/0",
              });
            }}
            text="New Blog"
          />
        </div>
      </div>
      <div className="pt-5 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-5">
        {blogs?.length > 0 &&
          blogs?.map((item, index) => {
            return (
              <div key={index}>
                <Blog
                  item={item}
                  onDelete={() => {
                    setBlogId(item?.id);
                    setOpenCnf(true);
                  }}
                  onEdit={() => {
                    history.push({
                      pathname: `/blog/edit/${item?.id}`,
                    });
                  }}
                />
              </div>
            );
          })}
      </div>
      <ConfirmationDialog
        open={openCnf}
        setOpen={setOpenCnf}
        handleYes={() => deleteBlog(blogId)}
        message={`Are you sure you want to delete this Blog?`}
      />
    </div>
  );
};

export default BlogCard;
