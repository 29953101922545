//Modal to show ownership is transferred to new admin.
import React from "react";
//MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

//Icons
import { AiFillCheckCircle } from "react-icons/ai";
const OwnershipTransferred = ({
  success,
  setSuccess,
  setOldAdmin,
  oldAdmin,
}) => {
  const handleSuccess = () => setSuccess(true);
  const handleClose = () => {
    setOldAdmin(true);
    setSuccess(false);
  };

  return (
    <div>
      <Modal open={success} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box className="box-modal7">
          <Grid container>
            <Grid item xs={12} className="box-modal7-middleColumn">
              <div className="box-modal7-middleColumn-icon">
                <AiFillCheckCircle />
              </div>
              <Typography className="box-modal7-middleColumn-mainText">
                Ownership transferred successfully
              </Typography>
              <Typography className="box-modal7-middleColumn-subtext">
                You no longer have access to the super admin dashboard
              </Typography>
              <button
                onClick={() => {
                  setOldAdmin(true);
                  setSuccess(false);
                }}
                className="box-modal7-middleColumn-okayButton"
              >
                okay
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default OwnershipTransferred;
