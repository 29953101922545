import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { pieData1 } from "helpers/defaultValues/dashboard";
import DropDownMenu from "./DropDownMenu";
const PieChartCard = ({ cardData, cardType }) => {
  const [pieData, setPieData] = useState(pieData1);

  let data = cardData;
  useEffect(() => {
    const tempPieData = [];
    for (let i = 0; i < data?.length; i++) {
      const item = data[i];
      const obj = {
        id: item.type,
        label: item.type,
        value: item.typeCount,
      };
      tempPieData.push(obj);
    }
    setPieData(tempPieData);
  }, [data]);

  return (
    <div className="shadow-ContentCards bg-white h-[21rem] rounded-2xl w-full  p-5  ">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p className="text-xl">Most used features</p>
        </div>
        {/* <div>
          <DropDownMenu setFilter={setFilter} cardType={cardType} />
        </div> */}
      </div>
      <div style={{ height: "270px" }}>
        <ResponsivePie
          data={pieData}
          margin={{ top: 40, right: 80, bottom: 80, left: -50 }}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 30,
              translateY: 5,
              itemsSpacing: 20,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "square",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PieChartCard;
