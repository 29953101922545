// DONE

import React from "react";
//React hooks
import { useState } from "react";

//Icons
import { AiFillCheckCircle } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { AiFillCaretDown } from "react-icons/ai";
//Initial values for add faq form
import { InitialValues } from "helpers/defaultValues/addFaq/InitialValues";
//External library for form handling and validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Api
import { FAQs, AddNewFAQs } from "config/apis/FAQS";
// Redux
import { useDispatch } from "react-redux";

import { showToast } from "redux/toaster";

// Utils
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";

const AddFaq = ({ setAddFaq, onUpdate, addFaq }) => {
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      tittle: InitialValues.tittle,
      answer: InitialValues.answer,
    },
    validationSchema: Yup.object({
      tittle: Yup.string().required("Required"),
      answer: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values.answer.split(/[\r\n\t]+/g));
      onSubmit(values);
    },
  });

  const onSubmit = async (values) => {
    setFetching(true);
    try {
      const body = {
        question: values.tittle,
        answer: {},
      };
      values.answer.split(/[\r\n\t]+/g).map((items, index) => {
        body.answer[`line${index + 1}`] = items;
      });

      const res = await AddNewFAQs(body);

      if (res.status === 201) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );

        onUpdate();
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("ADD FAQ", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }

    setFetching(false);
  };
  return (
    <>
      <div className="grid grid-cols-12 p-4  shadow-FaqCard rounded-lg bg-[#F0F2F4]">
        <div className="col-span-10  truncate w-full">
          <input
            type="tittle"
            id="tittle"
            name="tittle"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.tittle}
            className="font-normal w-full bg-[#F0F2F4] focus:outline-none text-base h-[40px] pl-3"
            placeholder="Add FAQ Title Here"
          />
          {formik.touched.tittle && formik.errors.tittle ? (
            <p className="text-red-600 text-sm  pl-3 ">
              {formik.errors.tittle}
            </p>
          ) : null}
        </div>
        <div className="flex col-span-2 justify-end space-x-5">
          <button
            onClick={() => setOpen(true)}
            type="submit"
            className={
              "cursor-pointer transform transition ease-in-out duration-150 hover:scale-105 " +
              (!(formik.isValid && formik.dirty) &&
                "pointer-events-none opacity-[0.6]")
            }
          >
            <AiFillCheckCircle className="text-2xl text-[#009B22]  font-medium" />
          </button>
          <button
            onClick={() => {
              setAddFaq(false);
            }}
            className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105"
          >
            <HiOutlineTrash className="text-2xl text-alert" />
          </button>
        </div>

        <div className="col-span-12  pb-3 mt-2">
          <textarea
            type="answer"
            id="answer"
            name="answer"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.answer}
            className="text-sm text-[#5b5a5a] bg-[#F0F2F4] font-light w-full border-none pl-3 focus:outline-none h-16 rounded-md"
            placeholder="Add Text Here"
          />
          {formik.touched.answer && formik.errors.answer ? (
            <p className="text-red-600 text-sm  pl-3 ">
              {formik.errors.answer}
            </p>
          ) : null}
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        handleYes={formik.handleSubmit}
        message="Are you sure you want to save this FAQ?"
      />
    </>
  );
};

export default AddFaq;
