import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiFillFilter } from "react-icons/ai";
import "assets/styles/scss/_styles/_MUIDropDown.scss";
import { useState } from "react";
const DropDownMenu = ({ setFilter, setYear }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState("Annual");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemSelect = (e) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const value = e.target.innerText;
    console.log(value);
    setValue(value);
    setYear(currentYear);
    setFilter(value.toLowerCase());
    handleClose();
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="flex items-center space-x-2 "
      >
        <AiFillFilter />
        <p>{value}</p>
      </button>
      <Menu
        className="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem className="menu-item" onClick={handleMenuItemSelect}>
          All
        </MenuItem> */}

        <MenuItem className="menu-item" onClick={handleMenuItemSelect}>
          Monthly
        </MenuItem>

        <MenuItem className="menu-item" onClick={handleMenuItemSelect}>
          Annual
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DropDownMenu;
