import React, { useState, useEffect, useCallback } from "react";
import Pagination from "components/Common/Pagination";
import EventCard from "components/PrivatePages/Events/EventCard";
import AddEventModal from "components/PrivatePages/Events/AddEventModal";
import OfflineEventModal from "components/PrivatePages/Events/OfflineEventModal";
import ShareEventModal from "components/PrivatePages/Events/ShareEventModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  GetAllEvents,
  getSearchEventsAPI,
  GetUpcomingEvents,
} from "config/apis/Events";
import { useLocation } from "react-router-dom";
import { BiFilter, BiSearch } from "react-icons/bi";
import Loader from "components/Common/Loader";

const AllEvents = () => {
  const location = useLocation();
  const [shareModal, setShareModal] = useState(false);
  const [shareEventData, setShareEventData] = useState("");
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [eventPageNo, setEventPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offlineModal, setOfflineModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventID, setEventID] = useState("");
  const [payload, setPayload] = useState({});
  const [edit, setEdit] = useState(false);
  const [editEventData, setEditEventData] = useState({});
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false);

  const fetchEvents = useCallback(async () => {
    setFetching(true);
    try {
      const res = isUpcomingEvent
        ? await GetUpcomingEvents(eventPageNo)
        : await GetAllEvents(eventPageNo);

      if (res?.status === 200) {
        const data = res?.data;
        setTotalPages(Math.ceil(data?.totalEvents / 6));
        setEvents(data?.events || []);
      } else {
        console.error("Error fetching events");
      }
    } catch (e) {
      console.error("Error fetching events", e);
    } finally {
      setFetching(false);
    }
  }, [isUpcomingEvent, eventPageNo]);

  const fetchSearchedEvents = useCallback(
    async (searchText) => {
      setFetching(true);
      try {
        const res = await getSearchEventsAPI(searchText, eventPageNo);

        if (res?.status === 200) {
          const data = res?.data;
          setTotalPages(Math.ceil(data?.eventslength / 6));
          setEvents(data?.events || []);
        } else {
          console.error("Error fetching searched events");
        }
      } catch (e) {
        console.error("Error fetching searched events", e);
      } finally {
        setFetching(false);
      }
    },
    [eventPageNo]
  );

  useEffect(() => {
    if (location?.state?.id) {
      setEdit(true);
      setEditEventData(location?.state?.event);
      setOpen(true);
    } else if (location?.state?.add) {
      setOpen(true);
    }
  }, [location?.state]);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      if (values.searchText === "") {
        fetchEvents();
      } else {
        fetchSearchedEvents(values.searchText);
      }
    },
  });

  useEffect(() => {
    if (formik.values.searchText === "") {
      fetchEvents();
    } else {
      fetchSearchedEvents(formik.values.searchText);
    }
  }, [
    eventPageNo,
    isUpcomingEvent,
    formik.values.searchText,
    fetchEvents,
    fetchSearchedEvents,
  ]);

  return (
    <div>
      {fetching && <Loader />}
      <div className="tab:flex tab:items-center tab:justify-between grid grid-cols-2 mb-10 mt-3.5 p-6">
        <div className="md:flex md:justify-between tab:w-5/12 w-full col-span-2">
          <h1 className="font-medium text-2xl">All events</h1>
          <div className="flex md:justify-end justify-start items-center mt-5 md:mt-0 tab:mr-5 w-[18.5rem] relative">
            <input
              type="text"
              id="searchText"
              name="searchText"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.searchText}
              className="w-full pl-5 h-10 rounded-md pr-12"
              placeholder="Search by keyword"
            />
            <button
              className="absolute right-0"
              onClick={formik.handleSubmit}
              type="submit"
            >
              <BiSearch className="text-primary-blue-medium text-3xl mr-2" />
            </button>
          </div>
        </div>
        <div className="flex md:justify-end justify-start mt-5 tab:mt-0 col-span-2">
          <button
            onClick={() => setOpen(true)}
            className="bg-primary-blue-medium text-white w-[17rem] h-10 rounded-lg font-bold text-xl"
          >
            Add new event
          </button>
        </div>
      </div>
      <div
        className={`flex my-3 p-2 ml-6 rounded-lg w-60 cursor-pointer ${
          isUpcomingEvent
            ? "bg-primary-blue-medium text-white hover:text-white"
            : "bg-gray-200"
        }`}
        onClick={() => setIsUpcomingEvent(!isUpcomingEvent)}
      >
        <div className="flex justify-center gap-2">
          <BiFilter className="font-bold text-3xl" />
          <button className="font-medium">Upcoming Events</button>
        </div>
      </div>
      <div className="grid tab:grid-cols-2 grid-cols-2 gap-10 p-6">
        {events.length > 0 ? (
          events.map((item, index) => (
            <div className="col-span-2 lg:col-span-1" key={index}>
              <EventCard
                shareEventData={shareEventData}
                setShareEventData={setShareEventData}
                shareModal={shareModal}
                setShareModal={setShareModal}
                setEditEventData={setEditEventData}
                setEdit={setEdit}
                setOpen={setOpen}
                eventData={item}
                onUpdate={fetchEvents}
              />
            </div>
          ))
        ) : (
          <div className="text-center text-gray-400 mt-2">No Events</div>
        )}
      </div>
      {totalPages > 0 && (
        <Pagination
          pageNo={eventPageNo}
          setPageNo={setEventPageNo}
          totalPages={totalPages}
        />
      )}
      <AddEventModal
        editEventData={editEventData}
        setEditEventData={setEditEventData}
        edit={edit}
        setEdit={setEdit}
        open={open}
        setOpen={setOpen}
        offlineModal={offlineModal}
        setOfflineModal={setOfflineModal}
        payload={payload}
        setPayload={setPayload}
        onUpdate={fetchEvents}
      />
      <OfflineEventModal
        editEventData={editEventData}
        setEditEventData={setEditEventData}
        edit={edit}
        setEdit={setEdit}
        open={open}
        setOpen={setOpen}
        offlineModal={offlineModal}
        setOfflineModal={setOfflineModal}
        payload={payload}
        setPayload={setPayload}
        onUpdate={fetchEvents}
      />
      <ShareEventModal
        shareEventData={shareEventData}
        setShareEventData={setShareEventData}
        shareModal={shareModal}
        setShareModal={setShareModal}
      />
    </div>
  );
};

export default AllEvents;
