import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Utils
import { getIsAdmin } from "helpers/utils";

import PortalLayout from "layout/PortalLayout";

// Wrapper for Private Routes
const AuthenticatedRedirects = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("backboneAdmin")) {
          return (
            <Redirect
              to={{
                pathname: "/auth/signIn",
              }}
            />
          );
        }

        let isSuperAdmin = JSON.parse(
          localStorage.getItem("backboneAdminData")
        )?.isSuperAdmin;

        if (isSuperAdmin) {
          return (
            <PortalLayout>
              <Component {...props} />
            </PortalLayout>
          );
        }

        let token = localStorage.getItem("backboneAdmin");

        if (token) {
          var d = jwt_decode(token);
          console.log(d);
          if (getIsAdmin(rest?.route?.admins, d?.type)) {
            return (
              <PortalLayout>
                <Component {...props} />
              </PortalLayout>
            );
          }
        }
      }}
    />
  );
};

export default withRouter(AuthenticatedRedirects);
