// DONE

//individual event to be mapped on the event card for content dashboard
import React from "react";
//icons and image import
import EventIMG from "assets/ContentDashboradIcons/EventIMG.png";
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";

const Event = ({ item, onEdit, onDelete }) => {
  const str = item.eventDate;
  const date = new Date(str);
  const date1 = new Date(date.getYear(), date.getMonth(), date.getDate());
  const shortName = date1.toLocaleString("en-US", { month: "short" });

  return (
    <div className="grid md:grid-cols-8 mt-5   rounded-lg bg-neutral-gray-faqBG ">
      <div className="md:col-span-1 flex items-center  bg-primary-blue-lightest rounded-lg">
        <div className="flex justify-center items-center w-full ">
          <div className="flex-row justify-center items-center">
            <p className="text-neutral-gray-darkgraytext text-3xl flex  font-medium">
              {date.getDate()}
            </p>
            <p className="text-neutral-gray-darkgraytext flex font-medium text-sm">
              {shortName}
            </p>
          </div>
        </div>
      </div>
      <div className="md:col-span-2">
        <img src={EventIMG} className="w-full h-full" />
      </div>
      <div className="md:col-span-4 p-4">
        <p className="text-xs font-medium line-clamp-2">{item?.eventName}</p>
        <p className="text-neutral-gray-lightgraytext break-all text-xs font-normal line-clamp-4">
          {item?.eventDescription}
        </p>
      </div>
      <div className="md:col-span-1 flex pb-3 md:pb-0 items-center justify-center mr-4 space-x-5 ">
        <button className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105">
          <BiPencil
            onClick={() => onEdit()}
            className="text-2xl text-primary-blue-dark font-medium"
          />
        </button>
        <button className="cursor-pointer transform transition ease-in-out duration-150 hover:scale-105">
          <HiOutlineTrash
            onClick={() => onDelete()}
            className="text-2xl text-primary-blue-dark"
          />
        </button>
      </div>
    </div>
  );
};

export default Event;
