import React from "react";
import "./index.css";

const ConfirmModal = ({ request, closeModal, updateStatus }) => {
  return (
    <div className="modal">
      <div className="modal-content bg-[#fff] p-5 rounded-lg max-w-3xl">
        <div className="confirmation-title">
          Are you sure you want to confirm?
        </div>
        <p>Upon confirmation, a message will be sent to them saying:</p>
        <p>
          "Good news! We've confirmed your Podcast. A BackBone Team member will
          be in touch shortly with details."
        </p>
        <button
          className="request-button waitlist text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          className="request-button bg-primary-blue-medium text-white w-half h-12 rounded-lg font-bold text-xl"
          onClick={() => {
            // console.log("request ==> ", request)
            closeModal();
            updateStatus("approved", request?.request_id);
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
