import React from "react";

/**
 * Spin Loader to improve UX on actions
 * @param { String } borderColor - Css for the loader color
 * @returns { Component }
 */
function SpinLoader({ borderColor = "border-white" }) {
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <div
        className={`h-5 w-5 rounded-full bg-transparent animate-spin border border-t-0 ${borderColor}`}
      />
    </div>
  );
}

export default SpinLoader;
