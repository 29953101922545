//Open and close modal for adding generating new promo codes

import React, { useState, useEffect } from "react";
//MUI components
import Box from "@mui/material/Box";

// API
import { adminByType } from "config/apis/Admin/index.js";

//Redux
import { useDispatch } from "react-redux";
import logError from "helpers/utils/logErrors";
import { showToast } from "redux/toaster";
import Toggle from "components/Common/Toggle";
import {
  ANALYTICS,
  CONTENT,
  SUBSCRIPTION,
  SYSTEM,
} from "helpers/constants/Common/Admin";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import "assets/styles/scss/_styles/_UpdateAccessModal.scss";
import UpdateWarning from "./UpdateWarning";
import { UpdateAdmin } from "config/apis/AdminAccess";
const UpdateAccessModal = ({
  updateModal,
  setUpdateModal,
  admin,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [warning, setWarning] = useState(false);
  const [types, setTypes] = useState(admin?.type?.split(",") || []);
  const [data, setData] = useState([]);
  const [systemAdmin, setSystemAdmin] = useState(0);
  const [subscriptionAdmin, setSubscriptionAdmin] = useState(0);
  const [analyticsAdmin, setAnalyticsAdmin] = useState(0);
  const [contentAdmin, setContentAdmin] = useState(0);

  useEffect(() => {
    if (updateModal) {
      getAdminByType();
    }
  }, [updateModal]);

  const getAdminByType = async () => {
    try {
      const res = await adminByType();
      let data = res?.data?.admins;
      var systemAdminCount = 0;
      var subscriptionAdminCount = 0;
      var analyticalAdminCount = 0;
      var contentAdminCount = 0;
      for (var i = 0; i < res.data.admins.length; i++) {
        if (data[i]?.isSuperAdmin == true) {
          continue;
        } else {
          if (data[i]?.type.includes("System")) {
            systemAdminCount = systemAdminCount + 1;
          }
          if (data[i]?.type.includes("Subscription")) {
            subscriptionAdminCount = subscriptionAdminCount + 1;
          }
          if (data[i]?.type.includes("Content")) {
            contentAdminCount = contentAdminCount + 1;
          }
          if (data[i]?.type.includes("Analytical")) {
            analyticalAdminCount = analyticalAdminCount + 1;
          }
        }
      }
      setData(data);
      setSystemAdmin(systemAdminCount);
      setSubscriptionAdmin(subscriptionAdminCount);
      setAnalyticsAdmin(analyticalAdminCount);
      setContentAdmin(contentAdminCount);
    } catch (e) {
      logError("adminByType", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };
  //Functions to handle opening and closing of modal
  const handleOpen = () => setUpdateModal(true);
  const handleClose = () => {
    setUpdateModal(false);
  };

  const onSubmit = async () => {
    try {
      let body = { type: types.join(",") };

      const res = await UpdateAdmin(body, admin?.id);

      if (res.status === 200) {
        dispatch(
          showToast({
            message: res?.data?.message,
            type: "success",
          })
        );
        onUpdate();
        setUpdateModal(false);
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("UPDATE ADMIN ACCESS", e);
      dispatch(
        showToast({
          message: e?.response?.data?.error,
          type: "error",
        })
      );
    }
  };

  const addType = (adminType) => {
    let t = [...types];

    if (t?.includes(adminType)) {
      console.log(t?.filter((i) => i !== adminType));
      t = t?.filter((i) => i !== adminType);
    } else {
      t = t?.concat([adminType]);
    }

    setTypes(t);
  };

  const isType = (adminType) => {
    const check = types?.includes(adminType);

    return check;
  };

  const isToggleDisable = (adminTypeCount, adminType) => {
    return (adminTypeCount >= 3 && !admin?.type?.includes(adminType)) ||
      (types.length == 1 && isType(adminType))
      ? true
      : false;
  };

  return (
    <div>
      <Modal
        open={updateModal}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        id="root"
      >
        <Box className="box-modal11">
          <Grid container>
            <Grid item xs={12} className="box-modal11-row1">
              <Typography className="font-medium text-4xl">
                Update access
              </Typography>
            </Grid>
            <Grid item xs={12} className="box-modal11-row1">
              <Typography className="font-medium text-xl text-[#929292]">
                {admin?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} className="grid grid-cols-12 pb-4 items-center">
              <div className="col-span-10">
                {systemAdmin >= 3 ? (
                  <Typography className="text-[#929292]">
                    System admin
                  </Typography>
                ) : (
                  <Typography>System admin</Typography>
                )}
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <Toggle
                  disabled={isToggleDisable(systemAdmin, SYSTEM)}
                  active={isType(SYSTEM)}
                  setIsActive={() => addType(SYSTEM)}
                />
              </div>
            </Grid>

            <Grid item xs={12} className="grid grid-cols-12 pb-4 items-center">
              <div className="col-span-10">
                {subscriptionAdmin >= 3 ? (
                  <Typography className="text-[#929292]">
                    Subscription admin
                  </Typography>
                ) : (
                  <Typography>Subscription admin</Typography>
                )}
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <Toggle
                  disabled={isToggleDisable(subscriptionAdmin, SUBSCRIPTION)}
                  active={isType(SUBSCRIPTION)}
                  setIsActive={() => addType(SUBSCRIPTION)}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="grid grid-cols-12 pb-4 items-center">
              <div className="col-span-10">
                {analyticsAdmin >= 3 ? (
                  <Typography className="text-[#929292]">
                    Analytics admin
                  </Typography>
                ) : (
                  <Typography>Analytics admin</Typography>
                )}
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <Toggle
                  disabled={isToggleDisable(analyticsAdmin, ANALYTICS)}
                  active={isType(ANALYTICS)}
                  setIsActive={() => addType(ANALYTICS)}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="grid grid-cols-12 pb-4 items-center">
              <div className="col-span-10">
                {contentAdmin >= 3 ? (
                  <Typography className="text-[#929292]">
                    Content admin
                  </Typography>
                ) : (
                  <Typography>Content admin</Typography>
                )}
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <Toggle
                  disabled={isToggleDisable(contentAdmin, CONTENT)}
                  active={isType(CONTENT)}
                  setIsActive={() => addType(CONTENT)}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className="flex flex-row items-center justify-between"
            >
              <button
                onClick={() => {
                  setUpdateModal(false);
                }}
                className="box-modal11-row2-no"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onSubmit();
                }}
                className="box-modal11-row2-yes"
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <UpdateWarning warning={warning} setWarning={setWarning} />
    </div>
  );
};

export default UpdateAccessModal;
