// DONE
// TODO: Copy the error handling format from EditFAQ for all API calls

//Event card for event list page

import React, { useEffect, useState } from "react";
//MUI components
import Avatar from "@mui/material/Avatar";
//Icons
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";
//Avatar image
import AvatarIMG from "assets/subscriptionAdmin/avatarCircle.png";
//Blue chips for event card on the event page
import moment from "moment";
import { DelEvent } from "config/apis/Events";
import { RiShareForwardLine } from "react-icons/ri";
import { AiOutlineInfo } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import logError from "helpers/utils/logErrors";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import { FaInfoCircle } from "react-icons/fa";
import { BsInfo } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import "./EventCard.css";
// TODO: Make it dynamic accept props for each values being displayed and show that
const EventCard = ({
  eventData,
  setOpen,
  setEdit,
  setEditEventData,
  onUpdate,
  shareEventData,
  setShareEventData,
  shareModal,
  setShareModal,
}) => {
  const [openCnf, setOpenCnf] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteEvent = async (eventID) => {
    try {
      const res = await DelEvent(eventID);
      if (res?.status === 200) {
        onUpdate();
        dispatch(showToast({ message: res?.data?.message, type: "success" }));
      } else {
        dispatch(showToast({ message: res?.data?.error, type: "error" }));
      }
    } catch (e) {
      logError("Delete Event", e);
      dispatch(showToast({ message: e?.response?.data?.error, type: "error" }));
    }
  };

  const str = eventData.eventDate;

  const date = new Date(str);
  const date1 = new Date(date.getYear(), date.getMonth(), date.getDate());
  const shortName = date1.toLocaleString("en-US", { month: "short" });

  return (
    <>
      <div className="shadow-ContentCards rounded-2xl w-full p-3 grid grid-cols-12 gap-4 bg-white divide-[#9F9F9F] divide-y sm:divide-x sm:divide-y-0">
        <div className="sm:col-span-10 col-span-12 grid grid-cols-12 pt-3">
          <div className="col-span-2 pl-2 pr-1">
            <div className="flex-row justify-center h-fit pt-2 pb-2 w-10/12  rounded-lg bg-primary-blue-lightest items-start   ">
              <p className="text-neutral-gray-darkgraytext justify-center md:text-4xl text-lg flex  font-semibold">
                {date.getDate()}
              </p>
              <p className="text-neutral-gray-darkgraytext flex justify-center font-semibold md:text-base text-xs">
                {shortName}
              </p>
            </div>
          </div>
          <div className="divide-[#9F9F9F] divide-y col-span-10">
            <div className="flex items-center pb-3 space-x-3">
              <h1 className="text-xl font-semibold text-[#555555] line-clamp-1">
                {eventData?.eventName}
              </h1>
              <p className="text-[#8D8D8D] text-base font-normal line-clamp-1">
                ({eventData?.eventType})
              </p>
            </div>
            <div className="grid grid-cols-12 space-y-3 pt-3">
              <div className="col-span-12 flex space-x-10">
                <h3 className="text-base text-[#363636] font-semibold">Time</h3>
                <p className="text-[#4E4E4E] text-base font-light">
                  {moment(new Date(str)).format("hh:mm a")}
                </p>
              </div>
              <div className="col-span-12">
                <p className="text-xs font-light text-[#4E4E4E] line-clamp-4">
                  {eventData?.eventDescription}
                </p>
              </div>
              {eventData?.Company?.id && (
                <div className="col-span-12 flex space-x-4 items-center">
                  <div>
                    <Avatar
                      src={
                        eventData?.Company?.company_logo != null
                          ? eventData?.Company?.company_logo
                          : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                      }
                    />
                  </div>
                  <div>
                    <Link to={`company/${eventData?.Company?.id}`}>
                      <p className="text-[#606060] font-medium text-base">
                        Uploaded by “{eventData?.Company?.company_name}”
                      </p>
                    </Link>
                  </div>
                </div>
              )}
              <div className="col-span-12">
                <button className=" text-white font-medium text-base bg-[#039BE5] w-[11.25rem] rounded-lg h-[2.25rem]">
                  <a
                    href={eventData?.registrationLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Register here
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:col-span-2 col-span-12 flex justify-center items-center">
          <div className="sm:flex-col flex justify-center sm:space-y-5 sm:space-x-0 space-x-5 pt-3 sm:pt-0 items-center">
            {!eventData?.Company?.id && (
              <div className="flex justify-center items-center">
                <button
                  className="cursor-pointer p-1.5  rounded-md bg-primary-blue-lightest  transform transition ease-in-out duration-150 hover:scale-105"
                  onClick={() => {
                    history.push("/power-hour", {
                      eventName: eventData?.eventName,
                      id: eventData?.id,
                    });
                  }}
                >
                  <BsInfo className="md:text-2xl text-lg text-[#007893] font-medium" />
                </button>
              </div>
            )}
            <div className="flex justify-center items-center">
              <button
                onClick={() => {
                  setShareEventData(eventData);
                  setShareModal(true);
                }}
                className="cursor-pointer p-1.5  rounded-md bg-primary-blue-lightest  transform transition ease-in-out duration-150 hover:scale-105"
              >
                <RiShareForwardLine className="md:text-2xl text-lg text-[#007893] font-medium" />
              </button>
            </div>
            <div className="flex justify-center  items-center">
              <button
                onClick={() => {
                  setEdit(true);
                  setEditEventData(eventData);
                  setOpen(true);
                }}
                className="cursor-pointer p-1.5  rounded-md bg-primary-blue-lightest  transform transition ease-in-out duration-150 hover:scale-105"
              >
                <BiPencil className="md:text-2xl text-lg text-[#007893] font-medium" />
              </button>
            </div>
            <div className="flex justify-center   items-center">
              <button
                onClick={() => setOpenCnf(true)}
                className="cursor-pointer bg-[#FAECEC] p-1.5 rounded-md transform transition ease-in-out duration-150 hover:scale-105"
              >
                <HiOutlineTrash className="md:text-2xl text-lg text-[#C11717] " />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={openCnf}
        setOpen={setOpenCnf}
        handleYes={() => deleteEvent(eventData?.id)}
        message="Are you sure you want to delete this Event?"
      />
    </>
  );
};

export default EventCard;
