import React from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

//Icons
import { AiOutlineClose } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";
const AdminPasswordModal = ({
  openPasswordModal,
  setOpenPasswordModal,
  password,
  admin,
}) => {
  const handleClose = () => {
    setOpenPasswordModal(false);
  };
  return (
    <div>
      <Modal
        open={openPasswordModal}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box className="box-modal3">
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8} className="box-modal3-middleColumn">
              <div className="box-modal3-middleColumn-icon">
                <AiFillCheckCircle />
              </div>
              <Typography className="box-modal3-middleColumn-text">
                The password for the {admin?.name} is
              </Typography>
              <div className="box-modal3-middleColumn-codeDiv">
                <Typography className="box-modal3-middleColumn-codeDiv-code">
                  {password}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} className="box-modal3-lastColumn">
              <button
                onClick={() => {
                  setOpenPasswordModal(false);
                }}
              >
                <AiOutlineClose />
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminPasswordModal;
