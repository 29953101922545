import React, { useState, useEffect } from "react";
//MUI components
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

//Icons
import { AiOutlineClose } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";

const PasswordGeneratedModal = ({ open, setOpen, newPassword }) => {
  const [text, setText] = useState({
    message: "New password has been generated!",
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflow: "scroll" }}
      >
        <Box className="box-modal3">
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8} className="box-modal3-middleColumn">
              <div className="box-modal3-middleColumn-icon">
                <AiFillCheckCircle />
              </div>
              <Typography className="box-modal3-middleColumn-text">
                {text.message}
              </Typography>
              <div className="box-modal3-middleColumn-codeDiv">
                <Typography className="box-modal3-middleColumn-codeDiv-code">
                  {newPassword}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} className="box-modal3-lastColumn">
              <button onClick={() => setOpen(false)}>
                <AiOutlineClose />
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default PasswordGeneratedModal;
